@import 'styles/variables';

.variablesPage {
  width: 100%;
  height: 100%;
}

.variablesHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;

  &__title {
    display: flex;
    align-items: flex-end;

    & h2 {
      color: $headerTextColor;
      font-size: $h2FontSize;
      line-height: $h2LineHeight;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-family: $fontFamilyMonsterrat;
    }
  }
}

.variablesContent {
  padding: 24px 0 0 0;
  &__table {
    display: flex;
    flex-direction: column;
  }
  &__tableHead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $borderAndSeparatorsColor;
    padding: 0 32px 0 10px;
    & div {
      text-transform: uppercase;
      font-weight: bold;
      color: $subtextColor;
      font-size: $tableHeadFontSize;
      line-height: $tableHeadLineHeight;
      letter-spacing: $defaultLetterSpacing;
      padding: 12px 0;
    }
  }
  &__tableName {
    width: 19%;
  }
  &__tableSystemName {
    width: 18%;
  }
  &__tableVariableType {
    width: 12%;
  }
  &__tableDataType {
    width: 11%;
  }
  &__tableUpdated {
    width: 20%;
  }
  &__tableDescription {
    width: 20%;
  }
}

.variablesSearch {
  width: 420px;
  margin-right: 24px;
}

.userFilter {
  margin-right: auto;
}

.addVariableButton {
  width: 180px;
}

.filtersContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.noResultsFoundContainer {
  margin-top: 80px;
}

.filterButton {
  margin-left: 36px;
  width: 44px;
}
