@import 'styles/variables';

.popup {
  margin-top: 10px;
  width: 240px;
  padding: 0;

  &__user {
    padding: 8px 24px;
    display: flex;
    align-items: center;

    &__image {
      margin-right: 8px;
      background-color: $white100Base;
      padding: 2px;
    }
  }
}

:export {
  contextualPopUpMaxHeight: 576px;
}
