@import 'styles/variables';

.description {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $primary80;
  margin-top: 12px;
}

.goBackButton {
  min-width: 160px;
}

.importTemplatesButton {
  margin-top: 24px;
}

.templatesToImportContainer {
  margin-top: 24px;
}

.noItemsTemplatesToImportContainer {
  margin-top: 36px;
}

.templateToImport {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  & > p {
    color: $headerTextColor;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    margin: 0 6px 0 12px;
  }
}

.skeletonItem {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.popupContent {
  margin-top: 12px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
}

.shareButton {
  min-width: 187px;
  margin-right: 12px;
}
