@import 'styles/mixins';

.container {
  @include titlePadding;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .headerTitle {
    @include headerTitle;
  }

  .createBorrowerButton {
    width: 145px;
    height: 44px;
    margin-left: auto;
  }
}

.subTitle {
  display: flex;
  align-items: center;

  .searchInput {
    width: 420px;
    margin-right: 24px;
  }
}

.filterButtonContainer {
  margin-left: auto;
}
