@import 'styles/colorPalette';
@import 'components/NoItems/CommonVariables.module';

.header {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $primary100Base;
  margin-bottom: 24px;
}

.noData {
  padding-top: $noDataTopOffset;
}

.noDataTitle {
  margin-top: 24px;
}
