@import 'styles/variables';

$arrow: url('./utils/images/arrows.svg') no-repeat center;

.decisionProcess {
  background-color: $surfacesLight;
  background-image: url('../../../static/images/dotted-background.png');
  background-repeat: repeat;
  border-radius: 6px;
  padding: 36px 55px 60px 55px;
  position: relative;
  min-height: 69.4vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.startProcess {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $surfacesPrimary;
  width: 168px;
  height: 44px;
  background-color: $primary80;
  border-radius: 26px;

  & > svg {
    margin-right: 4px;
  }
}

.startProcessContainer {
  width: 168px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.endProcess {
  @extend .startProcess;
  background-color: $green100Base;

  & > svg {
    margin-right: 4px;

    & > path {
      stroke: $surfacesPrimary;
    }
  }

  &:after {
    display: none;
  }
}

.moduleListContainer {
  opacity: 0.4;
}
