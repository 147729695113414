@import 'styles/variables';

.applicationForm {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 24px;

  textarea {
    font-family: $fontFamilyMonsterrat;
    color: $primary100Base;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    padding: 16px 20px;
    border: 1px solid $borderAndSeparatorsColor;
    resize: none;
    margin: 8px 0 36px;
    height: 172px;
    max-width: 100%;
    min-width: 100%;
    outline: none;
  }

  p {
    color: $headerTextColor;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
  }
}

.submitButton {
  min-width: 180px;
}
