@import 'styles/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $surfacesLight;
}

.rowsContainer {
  display: flex;
  flex-direction: column;
}

.addVariableButton {
  width: 140px;
}
