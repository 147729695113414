@import 'styles/mixins';

.container {
  background-color: $surfacesPrimary;
  padding: 36px 55px;
}

.title {
  display: flex;
  justify-content: space-between;

  .headerTitle {
    @include headerTitle;
  }

  .downloadApiTemplateButton {
    width: 294px;
    height: 52px;
    margin-left: auto;
  }
}

.headerTitleWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questionIcon {
  margin-left: 12px;
}
