.row {
  position: relative;
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.cardPreview {
  width: 100%;

  &:not(:last-child) {
    margin-right: 30px;
  }
}
