@import 'styles/variables';

.row {
  position: relative;
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 36px;

    &.editableRow {
      margin-bottom: 60px;
    }
  }
}
