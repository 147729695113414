@import 'styles/variables';
@import 'components/Auth/Variables.module.scss';

.errorNotification {
  color: $defaultErrorColor;
  margin-top: 4px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.mobileSelect {
  outline: none;
  border-radius: 4px;

  margin-top: 8px;
  height: 52px;
  border: 1px solid #e7e9ec;
  border-radius: 4px;
  padding: 16px 20px;
  cursor: pointer;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  appearance: none;

  &.hasError {
    border: 1px solid $defaultErrorColor;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.mobileSelectContainer {
  padding-top: 24px;
}

@media (max-width: $mobileMaxWidth) {
  .desktopSelect {
    display: none;
  }
}

@media (min-width: $desktopMinWidth) {
  .mobileSelectContainer {
    display: none;
  }
}
