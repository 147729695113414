@import 'styles/variables';

.toggleableContainer {
  transition: opacity 0.1s ease;
  opacity: 1;

  &.hidden {
    opacity: 0;
    display: none;
  }
}

.switchButtonLabel {
  color: $headerTextColor;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  margin-right: 7px;

  &.disabled {
    color: $subtextColor;
  }
}

.switchButtonContainer {
  display: flex;
  flex-direction: row;
  margin: 0 30px;
  align-items: center;
}

.downloadCSVButton {
  margin-left: auto;
}

.switchButton {
  padding: 0;
}

.tip {
  margin: 0 16px 0 5px;
}
