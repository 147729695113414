@import 'styles/variables';

.notificationContainer {
  margin: 24px 0 36px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
}

.buttonsContainer {
  display: flex;
}

.cancelButtonContainer {
  margin-left: 10px;
}

.spinnerContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
