@import 'styles/variables';

.textContainer {
  width: 100%;
  display: flex;

  &Expanded {
    flex-direction: column;
    align-items: flex-start;
  }
}

.textCollapsed {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.textExpanded {
  max-width: 100%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.controlButton {
  background-color: transparent;
  border: 0;
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
  color: $secondary100Base;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-family: $fontFamilyMonsterrat;
  outline: 0;
}
