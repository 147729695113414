@import 'styles/variables';

.skeletonCredentialLine {
  display: flex;
  flex-direction: row;
  min-height: 44px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
  padding: 0 10px;

  &:first-child {
    border-top: 1px solid $borderAndSeparatorsColor;
  }

  & > div:first-child {
    margin-right: 40px;
  }

  & > div:last-child {
    width: 100%;
    padding: 8px 0;
  }
}
