@import 'styles/variables';

.popUpBody {
  padding-top: 24px;
  &__description {
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $defaultTextColor;
  }

  &__input {
    padding: 12px 0 0 0;

    & label {
      font-style: normal;
      font-weight: bold;
      font-size: $smallFontSize;
      line-height: $smallLineHeight;
    }
  }

  &__warning {
    background-color: rgba(247, 101, 101, 0.2);
    margin-bottom: 20px;
    padding: 16px 24px;

    & > p {
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-size: $pFontSize;
      line-height: $pLineHeight;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: $red100Base;
    }
  }

  &__button {
    padding-top: 36px;
    & button {
      width: 180px;
      margin-right: 12px;
    }
  }
}
