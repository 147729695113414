@import 'styles/variables';

.applyFiltersButton {
  width: 100%;
  margin-top: 36px;
}

.statusMultiselectContainer {
  padding-top: 0;
}

:export {
  errorColor: $red100Base;
  successColor: $green100Base;
}
