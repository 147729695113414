@import 'styles/variables';

.pageContent {
  padding: 36px 55px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.headerTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-right: 12px;
}

.tabContent {
  margin-top: 36px;
}
