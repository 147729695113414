@import 'styles/variables';

.applyFilter {
  width: 100%;
  margin-top: 36px;
  height: 52px;
}

.filterLayoutTitle {
  margin-bottom: 16px;
}

.statusDropdownContainer {
  margin: 0;
}
