@import 'styles/variables';
@import 'styles/mixins';

.container {
  width: 980px;
  height: 100%;
}

.content {
  padding: 36px 36px 0 55px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  h2 {
    @include headerTitle;
  }
}

.closeButton {
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}
