.message {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}

.contactUs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareIcon {
  margin-right: 6px;
}
