@import 'styles/variables';

.asideLink {
  display: flex;
  align-items: center;

  &:hover .linkTitle {
    color: $defaultLinkHoverColor;
  }

  &:hover .linkImage {
    & > path {
      stroke: $defaultLinkHoverColor;
    }
  }
}

.linkTitle {
  color: $defaultLinkColor;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  padding-right: 2px;
}
