@import 'styles/variables';

.strategyUpdatesContent {
  width: 100%;
  padding: 36px 55px 0 55px;
  margin-bottom: 50px;

  &__title {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }

  &__table {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
  }
}

.updatesNoData {
  margin-top: 60px;
}

.updatesNoDataTitle {
  margin-top: 36px;
}
