@import 'styles/variables';

.container {
  position: relative;
  width: 710px;
  height: 100%;
  padding: 36px 55px 60px 55px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.editProductButton {
  margin: 36px 0 60px 0;
}

.closeImage {
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}
