@import 'styles/variables';

.sectionHeader {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.products {
  margin-top: 12px;
}

.product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 36px;
  border-top: 1px solid $primary20;
  border-left: 1px solid $primary20;
  border-right: 1px solid $primary20;
  box-sizing: border-box;
  height: 84px;
  cursor: pointer;

  .productContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .productType {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $tableHeadFontSize;
    line-height: $tableHeadLineHeight;
    letter-spacing: $defaultLetterSpacing;
    text-transform: uppercase;
    color: $primary40;
  }

  .productName {
    margin-top: 2px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-size: $h5FontSize;
    font-weight: $boldFontWeight;
    line-height: $h5LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }

  &:first-child {
    border-radius: 6px 6px 0 0;

    &:last-child {
      border-radius: 6px;
    }
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
    border-bottom: 1px solid $primary20;
  }

  .productTypeImage {
    fill: $primary80;
  }

  &:not(.selectedProduct):hover {
    background-color: $surfacesLight;
  }
}

.selectedProduct {
  background-color: $secondary100Base;
  border: 1px solid $secondary100Base;

  .productType {
    color: $secondary40;
  }

  .productName {
    color: $white100Base;
  }

  .productTypeImage {
    fill: $white100Base;
  }
}

.continueButton {
  margin-top: 36px;
  min-width: 180px;
}

.additionalSettingsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.checkboxContainer {
  margin-top: 12px;
}

.checkbox {
  padding-right: 12px !important;
}
