@import 'styles/variables';

.container {
  margin-top: 24px;
}

.colorsLine {
  display: flex;
  margin-top: 8px;
}

.basicColorsTitle {
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.colorBlock {
  width: 52px;
  height: 52px;
  border-radius: $borderRadiusDefault;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  & label {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    cursor: pointer;

    & svg path {
      stroke: $contrastSurfacesIconColor;
    }
  }

  & > input[type='radio'] {
    display: none;

    &:checked {
      + label {
        opacity: 1;
      }
    }
  }
}

.randomColorButton {
  @extend .colorBlock;
  margin-right: 0;
  padding: 0;
  border: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: 0;

  & > svg {
    position: absolute;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &:hover {
    & svg {
      opacity: 1;
    }
  }
}
