@import 'styles/colorPalette';

.container {
  background: $orange10;
  border: 1px solid $orange100Base;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 22px 30px;
}

.info {
  width: 66%;
}

.infoTitle {
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
  color: $orange100Base;
  margin-bottom: 4px;
}

.infoContent {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.date {
  font-weight: bold;
  color: $primary100Base;
  display: inline;
}
