@import 'styles/variables';

.popup {
  & > section {
    width: 100%;
  }
}

.statusRules {
  margin-bottom: 36px;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  margin-bottom: 36px;
}

.header {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 16px;
}

.closeButton {
  width: 160px;
}

.bold {
  font-weight: $boldFontWeight;
}
