@import 'styles/variables';

.infoPanelContentInner {
  display: flex;
  overflow-y: auto;
}

.borrowerIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -28px;
}

.avatarInUserList {
  background-color: $surfacesLight;
}

.teamMembersContent {
  align-items: center;
}

.teamMembersSection {
  position: absolute;
  top: -2px;
}

.duplicateIcon {
  stroke: $primary100Base;
}

.borrowerFullNameWithIcon {
  position: relative;
}

.blankBorrowerFullNameWithIcon {
  color: $primary40;
}
