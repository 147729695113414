@import 'styles/variables';

.cardPreview {
  padding: 24px 12px 24px 36px;
  background-color: $surfacesPrimary;
}

.variablesContainer {
  display: grid;
  gap: 6px;
}

.cardTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 12px;
}

.layoutItem {
  padding: 12px 24px 12px 0;
}
