@import 'styles/variables';

.editableInput {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::placeholder {
    color: $captionColor;
  }
}

.textLabel {
  @extend .editableInput;
  position: absolute;
  visibility: hidden;
  padding: 0 2px 0;
  white-space: pre;
  max-width: 580px;
}
