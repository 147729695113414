@import 'styles/mixins';

.description {
  @include descriptionText;
}

.inputContainer {
  padding-top: 0;
  margin: 36px 0;
}
