@import 'styles/variables';

.emptyVersion {
  font-style: normal;
  font-weight: bold;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
  padding: 16px 12px;
}

.versionContainer {
  width: 100%;
}

.version {
  width: 100%;
}
