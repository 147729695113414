@import 'styles/variables';

.statusDropdown {
  svg {
    path {
      stroke: $white100Base;
    }
  }
}

.statusDropdownItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    path {
      stroke: $secondary100Base;
    }
  }
}

.statusDropdownItemSelected {
  color: $secondary100Base;
}

.statusDropdownItemContainer {
  width: 100%;
}
