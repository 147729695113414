@import 'styles/variables.scss';

$borderRaduis: 2px;
$spacing: 8px;

.container {
  width: 260px;
  padding: $spacing;
  & > :not(:last-child) {
    margin-bottom: $spacing;
  }
}

@mixin controlContainer {
  border-radius: $borderRaduis;
  position: relative;
  overflow: hidden;
}

.saturation {
  @include controlContainer;
  height: 160px;
}

.saturationPointer {
  width: 9px;
  height: 9px;
  transform: translate(-50%, -50%);
  border: 1px solid $surfacesPrimary;
  border-radius: 50%;
  box-sizing: border-box;
  filter: drop-shadow(0px 1px 3px rgba(15, 35, 66, 0.07));
}

.hue {
  @include controlContainer;
  height: 36px;
}

.huePointer {
  width: 8px;
  height: 32px;
  transform: translate(-50%, 2px);
  background: $surfacesPrimary;
  border: 1px solid $primary6;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(15, 35, 66, 0.07);
  border-radius: $borderRaduis;
}
