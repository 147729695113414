@import 'styles/variables';

.container {
  min-height: 90px;
  width: 100%;
  padding: 10px 24px 10px 18px;
  display: flex;
  align-items: center;
  border: 1px solid $primary10;
  border-top: none;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;

  &.disabled {
    cursor: default;
    background-color: $surfacesLight;
  }

  &:not(.disabled):hover {
    background-color: $surfacesLight;
  }

  &:first-child {
    border-top: 1px solid $primary10;
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.activeContainer {
  @extend .container;
  background-color: $secondary100Base;

  &.disabled {
    background-color: $secondary40;
  }

  &:not(.disabled):hover {
    background-color: $secondary100Base;
  }
}

.cardInfoContainer {
  width: 100%;
  padding: 8px 0 0 12px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: bold;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  transition: color 0.25s ease-in-out;
}

.activeTitle {
  @extend .title;
  color: $white100Base;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
  transition: color 0.25s ease-in-out;
  width: 486px;
}

.activeDescription {
  @extend .description;
  color: $secondary40;
}

.image {
  fill: $primary80;
  transition: fill 0.25s ease-in-out;
}

.imageContainer {
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: 12px;
}

.activeImage {
  @extend .image;
  fill: $white100Base;
}

.infoContainer {
  width: 100%;
}
