@import 'styles/variables';

.container {
  position: relative;
  width: 100%;
}

.dataTypeContainer {
  position: absolute;
  z-index: 1;
  top: 48px;
  height: 52px;
  width: 52px;
  border: 1px solid $borderAndSeparatorsColor;
  border-radius: 4px 0 0 4px;
  background-color: $surfacesLight;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorDataTypeContainer {
  border-top: 1px solid $defaultErrorColor;
  border-bottom: 1px solid $defaultErrorColor;
  border-left: 1px solid $defaultErrorColor;
}

.symbol {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}
