@import 'styles/variables';

.applicationFormConfigurationContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.configurationContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 36px 55px;
  background-color: $surfacesLight;
}
