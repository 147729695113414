@import 'styles/variables';

.unlockCustomerButton {
  margin-right: 12px;
}

.contentMessage {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #3f4f68;
  margin-bottom: 36px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
}
