@import 'styles/variables';

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.addEndpointButton {
  position: absolute;
  width: fit-content;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.headerTitleWithTooltip {
  display: flex;
  align-items: center;
  min-height: 28px;
}

.headerTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-right: 8px;
}

.noItemsAddedSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noItemsAddedSectionTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
}

.noItemsAddedSectionDescription {
  @extend .description;

  margin: 8px 0 48px;
  text-align: center;
}

.needHelpSection {
  margin-top: 36px;
}
