@import 'styles/variables';

.draggableUploadFileStub {
  position: relative;
  width: 100%;
  padding: 12px 24px;

  &:hover {
    background-color: #F2F9FF;

    .dragImage {
      display: block;
    }
  }
}

.inDragProcessUploadFileStub {
  background-color: $white100Base;

  .dragImage {
    display: block;
  }
}

.dragImage {
  display: none;
  position: absolute;
  bottom: 46px;
  left: 0;
}

.rowActions {
  position: absolute;
  right: -10px;
  bottom: 46px;
}

.actionPopup {
  width: 270px;
}

.conditionalDisplayRuleIcon {
  position: absolute;
  top: 16px;
  right: 24px;
}
