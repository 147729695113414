@import 'styles/variables';

.tableHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(15, 35, 66, 0.1);
  background-color: $white100Base;
}

.columns {
  text-transform: uppercase;
  font-weight: bold;
  color: $subtextColor;
  font-size: $tableHeadFontSize;
  line-height: $tableHeadLineHeight;
  letter-spacing: $defaultLetterSpacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: $tableHeadStickyZIndex;
  transition: left 0.5s ease-in-out;
}

.stickyWithMargin {
  border-bottom: 0;
  box-shadow: 0 8px 9px rgba(15, 35, 66, 0.1);
}
