@import 'styles/variables';

.loadingPopUpContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: $tableHeadStickyZIndex + 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingPopUp {
  background-color: $surfacesLight;
  opacity: 0.6;
  height: 100%;
  width: 100%;
}

.transparentLoadingPopUp {
  background-color: transparent;
}

.loaderContainer {
  width: 36px;
  height: 36px;
}
