@import 'styles/variables';

$rightSidePopupWidth: 390px;

.rightSidePopup {
  position: fixed;
  top: 0;
  right: -$rightSidePopupWidth;
  width: $rightSidePopupWidth;
  height: 100vh;
  background-color: $white100Base;
  box-shadow: 0px 0px 60px rgba(15, 35, 66, 0.2);
  padding: 36px;
  z-index: 100;
  overflow-x: hidden;
  transition: transform 0.3s ease-in-out;
}

.rightSidePopupOpenWithTranslate {
  transform: translateX(-$rightSidePopupWidth);
}

.rightSidePopupOpen {
  right: 0;
}
