@import 'styles/variables';

.displayName {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.strategyName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 8px;
}

.versionsCount {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
}

.versionsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:hover {
    & button {
      opacity: 1;
      background-color: $secondary6;
    }
  }
}

.strategyUpdateInfo {
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
  }
}

.tableRow {
  position: relative;

  .versionCell {
    > svg {
      margin-left: 4px;
    }
  }

  &:hover button {
    background-color: transparent;
    opacity: 1;
  }
}
