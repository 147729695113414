@import 'styles/variables';

.pageUpdateInfo {
  display: flex;
  align-items: center;
}

.pageUpdateInfoDate {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.pageUpdateInfoUser {
  margin-right: 8px;
}
