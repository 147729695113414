@import 'styles/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $surfacesLight;
}

.rowsContainer {
  display: flex;
  flex-direction: column;
}

.addCardButton {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin-top: 8px;

  & path {
    stroke: $primary40;
  }
}

.addVariableButtonWrapper {
  position: absolute;
  top: -60px;
  right: 0;

  & > button {
    pointer-events: none;
  }
}
