@import 'styles/variables';

.skeletonCard {
  width: 100%;
  padding: 12px 16px;
  background-color: $white100Base;
  border-radius: $borderRadiusDefault;
}

.skeletonCardLabelsInner {
  display: flex;
  margin-bottom: 6px;
}

.skeletonCardTag:not(:last-child) {
  margin-right: 4px;
}

.skeletonCardLineTop {
  margin-bottom: 5px;
}

.skeletonCardLineBottom {
  margin-bottom: 15px;
}

.skeletonCardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
