@import 'styles/variables';

.row {
  margin-top: 20px;
  border-top: 1px solid $borderAndSeparatorsColor;
}

.nameColumn {
  min-width: 200px;
  margin-right: 10px;
}

.nameColumnText {
  color: $subtextColor;
}
