@import 'styles/variables';

.container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.innerContainer {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-flow: column;
  padding-top: 36px;
}

.productConfigurationStep {
  @media (max-width: 1550px) {
    text-align: left;

    & > p {
      width: min-content;
    }
  }
}
