@import 'styles/variables';

.inputsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.privateKeyInputContainer {
  & textarea {
    height: 232px;
  }
}

.inputsRow {
  display: flex;

  &:first-child > div {
    padding-top: 0;
  }

  & > div:not(:last-child) {
    margin-right: 30px;
  }
}

.autoCompletionInput {
  width: 100%;
}

.accountIdInput {
  width: calc(50% - 15px);
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 36px;

  & > h4 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }
}

.credentialsRow {
  display: flex;
  padding: 14px 10px;
  min-height: 44px;
  border-bottom: 1px solid $borderAndSeparatorsColor;

  &:first-child {
    border-top: 1px solid $borderAndSeparatorsColor;
  }

  & > p:first-child {
    min-width: 200px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $placeholderTextColor;
    margin-right: 10px;
  }

  & > p:last-child {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }
}

.buttonsContainer {
  display: flex;
  margin-top: 36px;
  width: 100%;
}

.editButton {
  margin-left: 12px;
  border-radius: 6px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: $defaultLinkColor;
    background-color: $secondary10;
  }
}

.credentialsTable {
  margin-top: 24px;
}

.rightButtonActions {
  position: absolute;
  top: -25%;
  right: 0;
}

.saveChangesButton {
  margin-left: 12px;
}

.closeEditorButton {
  min-width: 140px;
  padding: 12px 25.5px;
}
