@import 'styles/variables';

.closeBtn {
  border: none;
  background-color: transparent;
  height: 36px;
  width: 36px;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  & svg {
    width: 100%;
    height: auto;

    & path {
      stroke: $defaultTextColor;
    }
  }

  &__disabled {
    cursor: default;

    svg path {
      stroke: $primary20;
    }
  }
}
