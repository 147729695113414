@import 'styles/variables';

.buttonRound {
  border-radius: 50%;
  border: 1px solid $borderAndSeparatorsColor;
  background-color: transparent;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s background-color ease-in-out, 0.25s border ease-in-out;

  & > svg {
    transition: 0.25s stroke ease-in-out, 0.25s fill ease-in-out;
    stroke: $primary100Base;
  }

  & path {
    transition: 0.25s stroke ease-in-out, 0.25s fill ease-in-out;
  }

  &:hover {
    background-color: $secondary10;
    border: $secondary10;

    & > svg {
      stroke: $secondary100Base;
    }
  }

  &:disabled {
    background-color: $primary6;
    border: $primary6;
    pointer-events: none;

    & > svg {
      stroke: $primary40;
    }
  }
}
