@import 'styles/variables';

.popupContent {
  margin-top: 12px;
}

.deleteTemplateQuestion {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
  margin-bottom: 36px;
}

.goBackButton {
  min-width: 180px;
}

.deleteTemplateButton {
  margin-right: 12px;
  min-width: 202px;
}
