@import 'styles/variables';

.withHoverableTitle {
  &:hover {
    & .title {
      color: $secondary100Base;
    }
  }
}

.step {
  display: flex;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
  cursor: default;

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid $primary10;
    border-radius: 24px;
    width: 36px;
    height: 36px;

    > svg {
      > path {
        stroke: $white100Base;
      }
    }
  }

  .title {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary40;
    margin-left: 8px;
  }

  &.active {
    @extend .withHoverableTitle;

    color: $primary40;
    cursor: pointer;

    .number {
      border: 1.5px solid $primary10;
    }

    &:not(:hover) .title {
      color: $primary100Base;
    }
  }

  &.passed {
    @extend .withHoverableTitle;

    cursor: pointer;

    .number {
      svg > path {
        stroke: $secondary100Base;
      }

      border: 1.5px solid $secondary100Base;
    }

    & .title {
      color: $primary80;
    }
  }

  &.current {
    @extend .withHoverableTitle;

    color: $secondary100Base;

    .number {
      background-color: $secondary100Base;
      color: $white100Base;
      border: 1.5px solid $secondary100Base;
    }

    & .title {
      color: $primary100Base;
    }
  }
}
