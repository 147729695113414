@import 'styles/variables';

.container {
  flex-grow: 1;
  padding: 36px 55px 50px 55px;
}

.titleContainer {
  padding-right: 24px;
}

.actionsContainer {
  position: absolute;
  top: -10px;
  right: 0;
  flex-shrink: 0;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.editableInput {
  input,
  p {
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    padding-left: 0;
  }
}
