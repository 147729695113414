@import 'components/NoItems/CommonVariables.module';

.noData {
  margin-top: $noDataTopOffset + 40px; // invalid email dashboard title layout (no margins/padding)
}

.noDataTitle {
  margin: 36px 0 12px 0;
}

.noItemsContainer {
  margin-top: 56px;
}
