@import 'styles/variables';

.skeletonContainer {
  display: flex;
  flex-direction: column;
}

.skeletonHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 48px;
  margin-bottom: 15px;
}

.skeletonStatusNumber {
  position: absolute;
  left: 0;
  top: 0;
}

.skeletonBody {
  display: flex;
  flex-direction: column;
}

.skeletonValidationResult {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $borderAndSeparatorsColor;
  padding: 17px 0 17px 48px;

  &:last-child {
    border-bottom: 1px solid $borderAndSeparatorsColor;
  }
}

.skeletonResultIcon {
  position: absolute;
  left: 0;
  top: 17px;
}

.validationResultContent {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
