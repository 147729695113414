@import 'styles/variables';

.header {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 36px;
  margin-bottom: 24px;
}

.headerTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.editButton {
  margin-left: 12px;
  border-radius: 6px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: $defaultLinkColor;
    background-color: $secondary10;
  }
}

.closeEditorButton {
  position: absolute;
  min-width: 140px;
  top: -25%;
  right: 0;
  padding: 12px 25.5px;
}


.table {
  border-top: 1px solid $borderAndSeparatorsColor;
}

.tableRow {
  display: flex;
  padding: 14px 10px;
  min-height: 44px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.tableRowLabel {
  min-width: 200px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $placeholderTextColor;
  margin-right: 10px;
}

.tableRowValue {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
}