@import 'styles/variables';
@import 'styles/colorPalette';

$inputMarginTop: 8px;

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  width: 100%;
}

.labelWithLink {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navLink {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $defaultLinkColor;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}

@mixin inputBox {
  border: 1px solid $primary10;
  border-radius: $borderRadiusDefault;
  padding: 16px 20px;
  height: 52px;
}

@mixin inputStyles {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  outline: none;

  &::placeholder {
    color: $subtextColor;
    opacity: 1;
  }

  &:disabled {
    border: 1px solid $borderAndSeparatorsColor;
    cursor: default;
    background-color: $surfacesLight;
    color: $primary80;
  }
}

.input {
  @include inputBox;
  @include inputStyles;
  background: $surfacesPrimary;
  width: 100%;
  margin-top: $inputMarginTop;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  appearance: none;

  &:hover {
    border: 1px solid $primary20;
  }

  &:focus {
    border: 1px solid $secondary40;
  }

  &:disabled {
    border: 1px solid $borderAndSeparatorsColor;
    cursor: default;
    background-color: $surfacesLight;
    color: $primary80;
  }

  &.inputError {
    border: 1px solid $red100Base;
  }
}

.inputWithRightNeighbour {
  @extend .input;
  border-radius: 4px 0 0 4px;
}

.inputWithLeftPadding {
  @extend .input;
  padding-left: 72px;
}

.inputWithRightPadding {
  @extend .input;
  padding-right: 40px;
}

.textArea {
  @extend .input;
  height: 104px;
}

.errorNotification {
  color: $defaultErrorColor;
  margin-top: 4px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.fieldContainer {
  width: 100%;
  position: relative;
}

.loader {
  position: absolute;
  top: calc(50% + 4px);
  transform: translateY(-50%);
  right: 20px;
}

.inputIconContainer {
  top: 50%;
  transform: translateY(calc(-50% + 4px));
  display: flex;
  position: absolute;
  right: 16px;
}
