@import 'styles/variables';

.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.footer {
  width: 100%;
}

.contentContainer {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  padding: 35px 55px 60px;
  width: 100%;
}

.infoContainer {
  width: 30%;

  > button {
    width: 160px;
  }
}

.image {
  position: absolute;
  height: auto;
  right: 0;
  width: 75%;
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
  margin-bottom: 24px;
}

.info {
  font-size: $pFontSize;
  line-height: $pLineHeight;
  margin-bottom: 36px;
}
