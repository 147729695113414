@import 'styles/variables';

.pageContainer {
  min-height: 100%;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.tabSwitch {
  margin: 24px 0 36px 0;
}
