.listContainer {
  display: flex;
  position: relative;
  min-height: 0.1px; // empty containers with zero height refuse to work as drop targets
  flex-flow: row;
  align-items: center;

  > div {
    display: flex;
    outline: none;
  }

  &.vertical {
    flex-flow: column;
  }

  &.horizontal {
    flex-flow: row;
  }
}

.draggableItem {
  width: 100%;

  &__dragging {
    box-shadow: 0 8px 24px rgba(15, 35, 66, 0.2);
  }
}
