@import 'styles/variables';

.viewContainer {
  width: 890px;
  padding: 36px 25px 60px;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 30px 8px 30px;
}

.titleActionsContainer {
  display: flex;
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
}

.notFoundContainer {
  @extend .viewContainer;
  padding-left: 0;
}

.notFoundHeader {
  @extend .header;
  justify-content: flex-end;
  margin-bottom: 0;
}

.closeButton {
  cursor: pointer;
  position: relative;
  left: 20px;
  stroke: $primary80;
}

.decisionDescription {
  margin-left: 30px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.userAvatar {
  margin-right: 8px;
}

.headerLink {
  color: $defaultLinkColor;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}

.decisionFilter {
  margin: 36px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterInput {
  margin-top: 0;
  width: 330px;
}

.filterInputSelectControl {
  padding: 9px 20px;
  height: auto;
  margin-top: 0;
}

.viewBody {
  margin: 0 30px;
}

.noItems {
  margin: 93px 230px 0;
  text-align: center;
}
