@import 'styles/variables';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 149, 162, 0.4);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
