@import 'styles/variables';

.subtitleInner {
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.subtitleLabels {
  margin-right: 24px;
}

.subtitleTagItem:not(:last-child) {
  margin-right: 8px;
}

.editLabels {
  margin-left: 24px;
}
