@import 'styles/variables';

.row {
  color: $defaultTextColor;
  font-size: $tableHeadLineHeight;
  line-height: $smallLineHeight;
}

.authorName {
  margin-left: 10px;
}
