@import 'styles/variables';

.cardsFormHeader {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 44px;
  margin-bottom: 20px;

  & > h4 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }
}

.editModeActions {
  display: flex;
  position: absolute;
  right: 0;
}

.saveChangesButton {
  width: fit-content;
  margin-left: 12px;
}

.closeEditorButton {
  width: 140px;
  padding: 12px 20px;
  min-width: 129px;
}

.editDataButton {
  margin-left: 12px;
  height: 36px;

  &:not(:disabled):hover {
    background-color: $secondary10;
    border-radius: 6px;
  }
}

.skeletonCard {
  padding: 0;
}
