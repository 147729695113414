@import 'styles/variables';

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    color: $headerTextColor;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-style: normal;
    font-feature-settings: 'tnum' on, 'lnum' on;
    font-weight: $boldFontWeight;
    font-family: $fontFamilyMonsterrat;
  }
}

.filters {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.searchInput {
  margin-right: auto;
  width: 420px;
}

.filterButton {
  margin-left: 24px;
}
