@import 'styles/variables';

.closeImage {
  position: absolute;
  right: -20px;
  display: none;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  cursor: pointer;

  & > svg {
    stroke: $primary80;
  }
}

.dragImage {
  position: absolute;
  left: -21.5px;
  display: none;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(calc(-50% - 4px));
}

.tabItemContent {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 9px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $placeholderTextColor;
}

.tab {
  padding-right: 20px;
  cursor: pointer;

  &.selected {
    .tabItemContent {
      color: $headerTextColor;
      border-bottom: 2px solid $contrastSurfacesActiveIconColor;
    }
  }

  &.dragging {
    background-color: $surfacesPrimary;
    padding: 6px 28px 6px 24px;

    .tabItemContent {
      padding: 0;
      border: 0;
    }

    & .dragImage {
      display: block;
      transform: translateY(-50%);
    }
  }

  &:not(.selected):hover {
    & .closeImage {
      display: flex;
    }
  }

  &:hover {
    & .dragImage {
      display: block;
    }

    & .tabItemContent {
      color: $headerTextColor;
    }
  }
}
