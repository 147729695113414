@import 'styles/variables';

.avatar {
  border-radius: 50%;
}

.avatarImage {
  height: 100%;
  width: auto;
}

.avatarText {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  text-align: center;
  letter-spacing: 0.03em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $white100Base;
  font-size: inherit;
  line-height: 12px;
  text-transform: uppercase;
}

.active {
  box-shadow: 0 0 0 2px $secondary100Base;
  background-color: $white100Base;
  padding: 2px;
}
