@import 'styles/colorPalette';
@import 'styles/variables';
@import '../common.module.scss';

.skeletonColumnHeader {
  width: $columnWidth;
  background-color: $surfacesLight;
  padding: $paddingSize $paddingSize 16px $paddingSize;
  border-top-left-radius: $borderRadiusDefault;
  border-top-right-radius: $borderRadiusDefault;
}

.skeletonColumnHeaderTopInner {
  display: flex;
}

.skeletonColumnHeaderTitle {
  margin-right: 9px;
  margin-bottom: 4px;
}
