@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  & > h6 {
    color: $primary100Base;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
}

.questionIcon {
  margin-left: 8px;
}

.expandSectionContent {
  padding-top: 24px;
}

.chevronIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: calc(100% - 20px);
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);

  & > path {
    stroke: $secondary100Base;
  }
}

.expandedChevronIcon {
  transform: rotate(0);
}
