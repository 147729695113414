@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;

  & > div {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 36px;
  }
}

.card {
  width: 100%;
  height: 100%;
  padding: 24px 36px 36px;
  background-color: $white100Base;
  border-radius: 6px;
}
