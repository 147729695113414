@import 'styles/variables';

.tableRow {
  border: 1px solid transparent;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.nameCell {
  padding: 13px 0;
}

.avatar {
  margin-right: 12px;
}

.extensionIcon {
  margin-right: 12px;
}

.actionsPopup {
  width: 250px;
}
