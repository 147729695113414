@import 'styles/variables';

$animationDuration: 2s;

$colors: (
  'primary6': $primary6,
  'primary10': $primary10,
  'primary20': $primary20,
);

@mixin skeletonAnimationPrimary6 {
  animation: primary6 $animationDuration ease-in-out infinite;
}

@mixin skeletonAnimationPrimary10 {
  animation: primary10 $animationDuration ease-in-out infinite;
}

@mixin skeletonAnimationPrimary20 {
  animation: primary20 $animationDuration ease-in-out infinite;
}

@each $name, $color in $colors {
  @keyframes #{$name} {
    0% {
      background-color: $color;
    }
    70% {
      background-color: fade-out($color, 0.6);
    }
    100% {
      background-color: $color;
    }
  }
}
