@import 'styles/variables';

$height: 44px;
$datePickerWidth: 159px;

.container {
  display: flex;
  justify-content: space-between;
}

.selectDateOptionContainer {
  width: 200px;
  margin-top: 0;

  label {
    margin-top: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: $primary6;
  }
}

.selectDateOptionInput label {
  height: $height;
}

.datePickerInput.disabled {
  color: $primary40;
}

.selectDateOptionContainer.disabled {
  input {
    color: $primary40;
  }
}

.filterButton.disabled svg {
  stroke: $primary40;
}

.datePickerFrom {
  width: $datePickerWidth;

  .datePickerInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: $primary6;
    border-left: 0;

    &:hover {
      border-color: $primary6;
      border-left: 0;
      border-right: 0;
    }
  }
}

.datePickerTo {
  .datePickerInput {
    border-color: $primary6;
    width: $datePickerWidth;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover {
      border-color: $primary6;
    }
  }
}

.buttonGroup {
  margin-left: 12px;
  margin-right: auto;
}

.datePickerInput {
  height: $height;
}
