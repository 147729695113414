@import 'styles/variables';

$footerPadding: 22px 55px;

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: $footerPadding;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $subtextColor;
}

.separator {
  border-top: 1px solid $borderAndSeparatorsColor;
}
