@import 'styles/variables';

$applicationLinkWidth: 32%;
$applicationLinkMargin: 0 0 0 30px;
$applicationLinkTitlePadding: 0 4px 0 0;
$linksListMargin: 12px 0 0 0;
$linkPadding: 0 0 10px 0;

.applicationLink {
  width: $applicationLinkWidth;

  & h5 {
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    color: $headerTextColor;
    padding: $applicationLinkTitlePadding;
  }
}

.linksList {
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  margin: $linksListMargin;
}

.link {
  padding: $linkPadding;

  & a {
    color: $defaultTextColor;

    &:hover {
      color: $defaultLinkHoverColor;
    }
  }
}

.linksTitle {
  & a {
    display: flex;
    align-items: center;

    &:hover {
      & h5 {
        color: $defaultLinkHoverColor;
      }
      & svg {
        & path {
          stroke: $defaultLinkHoverColor;
        }
      }
    }
  }
}
