@import 'styles/variables';

.lockedCustomerMessage {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: 22px;
  line-height: 28px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
  mix-blend-mode: normal;
  margin-bottom: 24px;
}
