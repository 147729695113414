@import 'styles/variables';

.typeImage {
  fill: none;
  stroke: $primary80;
  transition: stroke 0.25s ease-in-out;
}

.typeImageActive {
  @extend .typeImage;
  stroke: $white100Base;
}

.continueButton {
  margin-top: 36px;
}

.selectDecisionRunContainer {
  margin-top: 24px;
}

.buttonsContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 36px;
}

.dropzoneLabel {
  margin-top: 24px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.labelContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.downloadTemplateButton {
  display: flex;
  align-items: center;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  padding: 0;

  > svg {
    margin-right: 0;
    height: 20px;
    width: 20px;

    path {
      stroke: $secondary100Base;
      transition: 0.25s stroke ease-in-out;
    }
  }

  &:disabled {
    path {
      stroke: $subtextColor;
    }
  }
}

.link {
  color: $secondary100Base;
  cursor: pointer;
  margin: 0 3px;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}

.inputsContainer {
  padding-top: 36px;
}

.centeredTooltip {
  text-align: center;
}
