@import 'styles/variables';
@import 'styles/mixins';

.header {
  display: flex;
  justify-content: space-between;
  vertical-align: center;
  align-items: center;

  .title {
    margin-right: 24px;
    min-width: 0;
    @include headerTitle;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
