@import 'styles/mixins';
@import 'styles/variables';

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 30px;
}

.tabMenu {
  margin-bottom: 30px;
}

.noItems {
  margin: 60px;
}

.noItemsTitle {
  margin-top: 35px;
}

.noItemsSubtitle {
  margin-top: 8px;
  max-width: 460px;
  text-align: center;
}

.noItemsButton {
  display: flex;
  align-items: center;
  font-weight: $boldFontWeight;
  padding: 9px 18px 9px 16px;
  border: 1px solid $secondary100Base;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 36px;
}

.buttonImage {
  margin-right: 6px;
}
