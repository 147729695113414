@import 'styles/colorPalette';
@import 'styles/variables';

.statusHeader {
  &__line {
    display: flex;
    font-size: $h5FontSize;
    color: $headerTextColor;
    font-weight: $boldFontWeight;
    line-height: $h5LineHeight;

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 235px;
    }

    &__count {
      color: $subtextColor;
      font-weight: $normalFontWeight;
      margin-left: 9px;
    }
  }

  &__subTitle {
    color: $subtextColor;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-weight: $normalFontWeight;
  }
}
