@import 'styles/variables';
@import 'styles/colorPalette';

.inner {
  font-family: $fontFamilyMonsterrat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white100Base;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  input {
    margin: 0;
  }
}

.inputContainer {
  padding: 0;
}

.inputLoader {
  top: calc(50%);
}
