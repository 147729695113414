@import 'styles/variables';

.editTemplate {
  width: 980px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;

  &Header {
    padding: 36px 36px 8px 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > button {
      border: none;
      background-color: transparent;
      height: 36px;
      width: 36px;
      padding: 0;
      cursor: pointer;
      outline: 0;

      & svg {
        width: 100%;
        height: auto;

        & path {
          stroke: $primary80;
        }
      }
    }
  }

  &Content {
    padding: 0 55px;
  }
}

.editTemplateDescription {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin-top: 12px;
}

.editVariableMappingButton {
  min-width: 203px;
  margin-top: 24px;
}

.switchContainer {
  display: flex;
  align-items: center;
  min-height: 44px;
  min-width: 172px;
  margin: 0 12px;
  padding-left: 4px;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $subtextColor;

  & > p {
    margin-left: 4px;
  }

  .activeSwitchText {
    color: $headerTextColor;
  }
}

.headerInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > h2 {
    color: $headerTextColor;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-family: $fontFamilyMonsterrat;
  }
}

.helpMessage {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $red100Base;
  margin-top: 4px;
}

.variablesMappingSection {
  margin-top: 24px;
}
