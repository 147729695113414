@import 'styles/variables';

.container {
  padding: 24px 55px 46px 55px;
  width: 100%;
}

.buttonContainer {
  margin-top: 36px;
}
