@import 'styles/variables';

.list {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  & > label {
    margin-left: 0;

    & > span {
      padding: 0 12px 0 0;
    }
  }

  & > label:not(:last-child) {
    padding-bottom: 12px;
  }
}
