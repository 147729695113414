@import 'styles/variables';

.tableHeadCell {
  display: flex;
  align-items: center;
  padding: 12px 10px;
}

.sortable {
  cursor: pointer;
}

.sorted {
  font-weight: bold;
  color: $primary100Base;
}

.rotated {
  transform: rotate(180deg);
}

.arrow {
  margin-left: 4px;
}

.lastColumnCell {
  justify-content: flex-end;
}
