@import 'styles/variables';

.datePickerContainer {
  width: 100%;
}

.noLabel {
  > div {
    > div {
      padding-top: 0;

      input {
        margin-top: 0;
      }
    }
  }
}

:export {
  palettePrimaryMain: $secondary100Base;
  hoverColor: $secondary10;
  captionColor: $primary40;
  toolbarTextColor: $secondary40;
  dayOfWeekColor: $primary40;
  dayNumberColor: $primary80;
  fontFamily: $fontFamilyMonsterrat;
  currentDay: $white100Base;
}
