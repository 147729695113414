@import 'styles/variables';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 36px;
  border: 1px solid $surfacesLight;
  box-sizing: border-box;
  border-radius: 6px;

  .header {
    display: flex;
    flex-direction: row;
  }

  .footer {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  .avatar {
    margin-right: 8px;
  }
}
