@import 'styles/mixins';
@import 'styles/variables';

.title {
  @include titlePadding;

  .titleInner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .titleName {
    @include headerTitle;
  }
}

.titleSmall {
  padding-bottom: 24px;

  .titleInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titleName {
    color: $headerTextColor;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-family: $fontFamilyMonsterrat;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
  }
}

.buttonsInner {
  display: flex;
}

.createTaskButton {
  margin-left: 12px;
  height: 44px;
  width: 140px;
}

.searchInner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
}

.filtersContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.search {
  margin-right: 24px;
}
