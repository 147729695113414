.fromContainer {
  justify-content: space-between;

  .formContainerBody {
    flex: auto;
    padding-top: 48px;
  }

  .smallTopPaddingContainer {
    padding-top: 24px;
  }
}
