@import 'styles/variables';
@import 'styles/mixins';

.strategyHeader {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &__title {
    display: flex;
    align-items: flex-end;
    max-width: 70%;

    input,
    p {
      @include headerTitle;
      padding-left: 0;
      line-height: 46px;
      max-width: unset;
    }
  }

  &__actionsButton {
    border: 1px solid $borderAndSeparatorsColor;
    height: 44px;
    width: 44px;
  }

  &__button {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
  }
}

.strategyInfo {
  margin: 16px 0;
}

.strategyData {
  display: flex;
  align-items: center;
}

.statusLabel {
  height: 24px;
  padding: 0 10px;
}

.strategyVersion {
  @include labelContainer;
  height: 24px;
  padding: 0 10px;
  margin-left: 8px;
}

.strategyUpdateInfo {
  display: flex;
  align-items: center;
  margin-left: 24px;

  & > p {
    margin-left: 8px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }
}

.strategyLinkBack {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultLinkColor;
  display: flex;
  align-items: center;
  margin-left: -23px;
  width: 170px;

  &:hover {
    color: $defaultLinkHoverColor;

    & > svg {
      stroke: $defaultLinkHoverColor;
    }
  }

  & > svg {
    stroke: $defaultLinkColor;
  }
}
