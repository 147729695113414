@import 'styles/variables';

.strategyDescription {
  margin: 16px 0 24px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  & > textarea {
    width: 733px;
    min-height: $pLineHeight;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
    border: 0;
    outline: none;
    resize: none;
    padding: 0;
    opacity: 0;
    transition: 0.4s height ease;
    overflow-y: hidden;

    &.focusTextarea {
      opacity: 1;
    }

    &::placeholder {
      color: $primary40;
    }
  }

  & > p {
    top: 0;
    left: 0;
    position: absolute;
    width: 733px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
    padding: 0;
    pointer-events: none;
    overflow-wrap: anywhere;

    &.focusParagraph {
      display: none;
    }

    &.emptyParagraph {
      color: $primary40;
    }

    & > span {
      margin-left: 5px;
      color: $secondary100Base;
      position: relative;
      cursor: pointer;
      pointer-events: auto;
      min-width: 100%;

      &:hover {
        color: $defaultLinkHoverColor;
      }

      &::before {
        content: '';
        width: 40px;
        height: 20px;
        background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        left: -40px;
        pointer-events: none;
      }
    }
  }

  .showLessButton {
    color: $secondary100Base;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    cursor: pointer;
    width: 80px;

    &:hover {
      color: $defaultLinkHoverColor;
    }
  }
}
