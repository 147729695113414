.container {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;

  &.tableView {
    overflow-y: auto;
    flex-flow: column;

    > div:not(:first-child) {
      margin-top: 46px;
    }
  }
}

@mixin columnsContainer {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 55px 0 55px;

  > div {
    flex-shrink: 0;
  }

  > div:not(:first-child) {
    margin-left: 12px;
  }
}

.columnHeaders {
  @include columnsContainer;

  flex: 0 0 auto;
  padding-top: 36px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.columnBodiesWrapper {
  flex: 1 1;
  flex-basis: 0;
  overflow-x: auto;
  overflow-y: scroll;
}

.columnBodies {
  @include columnsContainer;
}
