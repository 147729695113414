.popupContent {
  padding-bottom: 76px;
}

.nameInputContainer {
  padding-top: 16px;
  margin-bottom: 36px;
}

.cancelButton {
  margin-left: 8px;
}

.buttonContainer {
  display: flex;
}
