@import 'styles/variables';

.chartsContainer {
  margin-top: 36px;
}

.chart {
  width: 50%;

  &:last-child {
    border-left: none;
  }
}

.groupTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $primary100Base;
}

.chartsRow {
  display: flex;
  margin-top: 24px;
}
