@import 'styles/variables';

.documentContainer {
  display: flex;
  align-items: center;
}

.documentName {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $defaultTextColor;
  margin-left: 8px;
}

.loader {
  margin-left: auto;
}
