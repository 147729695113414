@import 'styles/variables';

.ruleRow {
  width: 870px;
  height: 36px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $borderAndSeparatorsColor;
  margin: 24px 30px 0 30px;
}

.tableCeil {
  text-transform: uppercase;
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: $defaultLetterSpacing;
  color: $subtextColor;
  text-justify: center;
}

.dataItem {
  @extend .tableCeil;
  width: 356px;
  padding-left: 10px;
}

.variable {
  @extend .tableCeil;
  width: 356px;
  padding-left: 10px;
}

.container {
  margin-top: 48px;
}
