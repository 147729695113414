@import 'styles/variables';
@import 'styles/mixins';

.docuSignButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addDocumentButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  .dropdownImage {
    & path {
      stroke: $white100Base;
    }
  }
}

.tabHeader {
  margin-bottom: 8px;

  .tabHeaderBreadcrumbs:last-child {
    @include headerTitle;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
  }
}

.downloadAttachments {
  margin-top: -2px;
  justify-content: flex-start;
}

.actionsPopup {
  width: 250px;
}
