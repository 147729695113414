@import 'styles/variables';

.tagLine {
  width: 20px;
  height: 4px;
  border-radius: 2px;
}

.minimized {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
