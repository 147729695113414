.container {
  display: flex;
  flex-direction: row;
  padding: 25px 25px 12px;

  & > div {
    &:not(:last-child) {
      margin-right: 36px;
    }
  }
}
