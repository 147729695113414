.applicationSelectInner {
  position: relative;
}

.goToApplicationButton {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  line-height: 16px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
}

.createButton {
  margin: 36px 0 60px 0;
}

.taskFormContainer {
  margin-right: 19px;
}
