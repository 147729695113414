@import 'styles/variables';

$dashboardUserNamePadding: 24px 0;
$helpfulResourcesTitleMargin: 60px 0 0 0;
$dashboardLinksPadding: 36px 0 72px 0;

.dashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  width: 100%;

  & h1 {
    font-weight: $boldFontWeight;
    font-size: $h1FontSize;
    line-height: $h1LineHeight;
    color: $headerTextColor;
  }

  & h3 {
    font-weight: $normalFontWeight;
    font-size: $h3FontSize;
    line-height: $h3LineHeight;
    color: $defaultTextColor;
    padding: $dashboardUserNamePadding;

    & span {
      font-weight: $boldFontWeight;
      color: $headerTextColor;
    }
  }

  &__cardContainer {
    display: flex;
    justify-content: space-between;
  }

  & h2 {
    font-weight: $boldFontWeight;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    margin: $helpfulResourcesTitleMargin;
    color: $headerTextColor;
  }

  &Links {
    display: flex;
    justify-content: space-between;
    padding: 36px 0 72px 0;
  }
}

.card {
  width: 32%;
  height: 220px;
}
