@import 'styles/variables';

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  color: $headerTextColor;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}

.filtersContainer {
  width: 100%;
  display: flex;
}

.userFilter {
  margin-left: 24px;
}

.noFiltersContainer {
  margin-top: 66px;
}

.noFiltersSubtitle {
  margin: 8px 0 24px 0;
}

.filterButtonContainer {
  margin-left: auto;
}
