@import 'styles/variables';

.popupCore {
  section {
    width: 100%;
  }
}

.downloadButton {
  margin-top: 36px;
}
