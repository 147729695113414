@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.addRuleButtonContainer {
  display: flex;

  & > span {
    width: 100%;
    margin: 0 20px;
  }
}

.addStatusRuleButton {
  width: 100%;
  padding-top: 11px;
  justify-content: normal;

  &:disabled {
    pointer-events: none;
  }
}

.withBorderAddStatusRuleButton {
  border-top: 1px solid $borderAndSeparatorsColor;
}
