@import 'styles/variables';

.pageContent {
  padding: 0;
  background-color: $surfacesLight;
}

.footer {
  background-color: $surfacesLight;
}
