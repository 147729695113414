@import 'styles/variables';

$defaultLeftPadding: 24px;

.leftSidePane {
  width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 28px 0 24px 0;
  box-shadow: inset -1px 0px 0px $borderAndSeparatorsColor;
}

.paneHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: $defaultLeftPadding;
  margin-bottom: 48px;
}

.stepsTitle {
  padding: 14px 0 14px $defaultLeftPadding;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
  color: $headerTextColor;
}

.step {
  position: relative;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $placeholderTextColor;
  padding: 12px $defaultLeftPadding 12px;
  cursor: pointer;

  &:hover {
    color: $headerTextColor;
  }

  &.selectedStep {
    color: $headerTextColor;
    background-color: #FAFBFB;
  }
}

.selectedStepRightBorder {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: $orange100Base;
}

.bottomSection {
  display: flex;
  flex-direction: column;
  padding: 0 $defaultLeftPadding;
  margin-top: auto;
}

.productName {
  max-width: 200px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  color: $headerTextColor;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.defaultUpdaterInformationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.updaterInformationContainer {
  @extend .defaultUpdaterInformationContainer;

  & > p {
    margin-left: 8px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary80;
  }
}

.skeletonUpdaterInformationContainer {
  @extend .defaultUpdaterInformationContainer;
}

.skeletonTitleWithActionsContainer {
  display: flex;
  flex-direction: row;
}

.productTypeImage {
  & > svg {
    fill: $defaultTextColor;
  }
}

.tag {
  height: 44px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 12px 20px;
  text-transform: capitalize;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0;
  margin-bottom: 12px;
}

.activeTag {
  @extend .tag;

  &.selectedTag, &:hover {
    border: 1px solid $green50;
  }
}

.draftTag {
  @extend .tag;

  &.selectedTag, &:hover  {
    border: 1px solid $secondary40;
  }
}

.archivedTag {
  @extend .tag;

  &.selectedTag, &:hover {
    border: 1px solid $primary20;
  }
}

.moreButton {
  position: absolute;
  top: 0;
  right: 12px;
  border-radius: 4px;
  width: 44px;
  height: 44px;

  > button {
    width: 44px;
    height: 44px;
    opacity: 1;

    & circle {
      fill: $primary100Base;
    }
  }
}

.checkedIcon {
  margin-right: 6px;

  & > path {
    stroke: $green110;
  }
}

.actionsPopup {
  width: 232px;
}

.actionPopupItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding-right: 9.67px;
}