@import 'styles/variables';

.container {
  position: relative;
  padding: 24px 30px 22px 30px;
  background-color: $surfacesLight;
  border-radius: 6px;
  margin: 0 -30px;
}

.headerContainer {
  width: fit-content;
  position: relative;
  display: flex;
  margin-bottom: 20px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.titleIcons {
  margin-left: 8px;
}

.row {
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.cardTableViewField {
  &:last-child {
    border-bottom: 0;
  }

  & > div {
    padding-left: 0;
  }
}

.cardTableFieldValue {
  font-size: $pFontSize;
  line-height: $pLineHeight;
  padding: 12px 10px;
}

.cardTableView {
  & > div {
    margin-top: 14px;
  }
}

.cardName {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.editButton {
  position: absolute;
  top: 50%;
  left: calc(100% + 16px);
  width: max-content;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: $normalFontWeight;
  line-height: 20px;
  color: $secondary100Base;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: $white100Base;

    & > svg {
      stroke: $secondary100Base;
    }
  }
}

.closeImage {
  position: absolute;
  right: 30px;
  cursor: pointer;

  & > path {
    stroke: $primary80;
  }
}
