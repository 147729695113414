@import 'styles/mixins';
@import 'styles/colorPalette';

.description {
  @include descriptionText;
}

.inputContainer {
  display: flex;
  align-items: flex-end;
  margin: 36px 0;

  .codeInputContainer {
    padding-top: 0;
  }

  .countryCodeSelectorContainer {
    width: 32%;
  }

  .phoneNumberInputContainer {
    width: 68%;
  }

  .countryCodeSelectorContainer,
  .phoneNumberInputContainer {
    margin-top: 0;
    position: relative;

    > p {
      position: absolute;
      bottom: -25px;
    }
  }
}
