@import 'styles/variables';

.userGuideLink {
  display: flex;
  align-items: center;
  margin-left: 4px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultLinkColor;

  & > svg {
    width: 20px;
    height: 20px;
    stroke: $defaultLinkColor;
  }
}

.helpSection {
  display: flex;
  flex-direction: row;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
}
