@import 'styles/variables';

$transitionDuration: 0.25s;

.card {
  height: 220px;
  background-color: $white100Base;
  border-radius: 8px;
  border: 1px solid $primary20;
  transition: all $transitionDuration ease-in-out;

  &:hover {
    background-color: $secondary100Base;

    & a h4,
    p {
      color: $white100Base;
    }

    & a svg {
      fill: $white100Base;
    }
  }

  & a {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    padding: 20px 24px 24px 24px;

    & h4 {
      font-size: $h4FontSize;
      line-height: $h4LineHeight;
      font-weight: $boldFontWeight;
      margin: 8px 0;
      color: $headerTextColor;
      transition: all $transitionDuration ease-in-out;
    }

    & p {
      font-size: $pFontSize;
      line-height: $pLineHeight;
      font-weight: $normalFontWeight;
      color: $defaultTextColor;
      transition: all $transitionDuration ease-in-out;
    }

    & svg {
      width: 48px;
      height: 48px;
      fill: $secondary100Base;
      transition: all $transitionDuration ease-in-out;
    }
  }
}

.disabledCard {
  background-color: $surfacesLight;
  cursor: pointer;

  & > a {
    & h4,
    p {
      color: $subtextColor;
    }
    & svg {
      fill: $subtextColor;
    }
  }

  &:hover {
    background-color: $surfacesLight;

    & > a {
      cursor: default;
      & h4,
      p {
        color: $subtextColor;
      }
      & svg {
        fill: $subtextColor;
      }
    }
  }
}
