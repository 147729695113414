@import 'styles/variables';
@import 'styles/mixins';

.viewContainer {
  width: 980px;
  padding: 36px 25px 60px;
  overflow-x: hidden;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  position: relative;
  padding: 0 30px;

  h2 {
    @include headerTitle;
  }

  .actionsBtn {
    display: flex;
  }

  .closeButton {
    position: absolute;
    right: 10px;
    cursor: pointer;
    stroke: $primary80;
  }
}
