@import 'styles/variables';

.profileHeader {
  width: 100%;
}

.titleSection {
  display: flex;
  align-items: center;
}

.subtitleSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 24px 0;

  & > p {
    margin-left: 8px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary80;
  }
}

.emailButton {
  margin-right: 4px;
  & path {
    fill: $primary100Base;
    stroke: none;
  }

  &:hover {
    & path {
      fill: $secondary100Base;
      stroke: none;
    }
  }

  &:disabled {
    & path {
      fill: $primary40;
      stroke: none;
    }

    &:hover {
      & path {
        fill: $primary40;
        stroke: none;
      }
    }
  }
}

.actionsButton {
  border-radius: 4px;
  margin: 0 12px 0 4px;
  width: 44px;
  height: 44px;

  > button {
    width: 44px;
    height: 44px;
    opacity: 1;

    & circle {
      fill: $primary100Base;
    }
  }
}

.skeletonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.skeletonSubtitleSection {
  @extend .skeletonSection;

  margin: 8px 0 24px 0;
}

.subtitleSectionIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;

  & > svg:not(:last-child) {
    margin-right: 6px;
  }
}
