@import 'styles/variables';

.changeStatusQuestion {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.container {
  margin-bottom: 36px;
}
