@import 'styles/variables';

$lineColor: $primary10;
$lineOpacity: 0.5;

$textColor: $primary40;
$textSize: 12px;
$textWeight: 500;

$chartLineColor: $secondary100Base;
$chartLineWidth: 2px;
$chartPointColor: $white100Base;

$gradientFrom: $secondary10;
$gradientTo: rgba($secondary10, 0.1);

.axisXLabel {
  font-family: $fontFamilyMonsterrat;
  font-weight: $textWeight;
  font-size: $textSize;
  color: $primary40;
}

:export {
  gridLineColor: $lineColor;
  gridLineOpacity: $lineOpacity;

  textColor: $textColor;
  fontFamily: $fontFamilyMonsterrat;
  textSize: $textSize;
  textWeight: $textWeight;

  lineColor: $chartLineColor;
  lineWidth: $chartLineWidth;
  pointColor: $chartPointColor;

  gradientFrom: $gradientFrom;
  gradientTo: $gradientTo;
}
