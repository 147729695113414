@import 'styles/variables';

.list {
  margin-top: 20px;
}

.listItem {
  padding-bottom: 0;

  & > p {
    font-size: $pFontSize;
    line-height: $pLineHeight;

    &:before {
      margin-left: 6px;
      color: #3f4f68;
      font-weight: 500;
    }
  }
}

.permissions {
  margin-top: 24px;

  &SliderWrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  & &Slider {
    margin: 0 12px 0 0;

    &Label {
      display: inline-block;
    }
  }

  &Select {
    flex: 0 0 calc(50% - 15px);
  }
}
