@import 'styles/variables';
@import 'components/RuleRow/RuleRow.module.scss';

.tableHeaderRow {
  padding-right: 24px;
  height: 36px;
  display: flex;
  align-items: center;
  margin: 24px 30px 0;
  padding-left: 10px;
}

.tableHeaderCell {
  color: $subtextColor;
  font-weight: $boldFontWeight;
  text-transform: uppercase;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: $defaultLetterSpacing;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  margin: 8px 30px 0;
}

.variable {
  @extend .tableCell;
  max-width: 230px;
  width: 100%;
}

.comparison {
  @extend .tableCell;
  max-width: 100px;
  padding-left: 24px;
  width: 100%;
}

.value {
  @extend .tableCell;
  max-width: 460px;
  padding-left: 24px;
  width: 100%;
}

.variableHeaderCell {
  @extend .variable;
  @extend .tableHeaderCell;
}

.valueHeaderCell {
  @extend .tableHeaderCell;
  @extend .value;
}

.comparisonHeaderCell {
  @extend .tableHeaderCell;
  @extend .comparison;
}

.skeletonTable {
  margin: 0 30px;
}

.skeletonRow {
  justify-content: flex-start;
}

.tableHeaderUnderline {
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.conditionsTable {
  position: relative;
}

.tooltip {
  text-align: center;
}
