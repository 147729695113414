@import 'styles/variables';

.emailViewContainer {
  width: 890px;
  padding: 36px 25px 60px 25px;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 30px 24px 30px;
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
}

.notFoundContainer {
  @extend .emailViewContainer;
  padding-left: 0;
}

.notFoundHeader {
  @extend .header;
  justify-content: flex-end;
  margin-bottom: 0;
}

.closeButton {
  cursor: pointer;
  position: relative;
  left: 20px;
  stroke: $primary80;
}

.emailDescription {
  display: flex;
  justify-content: space-between;
}

.emailActionsButton {
  padding: 0;
  margin: 0;
  border: 1px solid $surfacesLight;
  border-radius: $borderRadiusDefault;
  background-color: transparent;
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  transition: border 0.25s ease-in-out, background-color 0.25s ease-in-out;

  &:hover {
    border: 1px solid $secondary100Base;
    background-color: $secondary10;
  }

  & svg {
    fill: $primary80;
  }

  & > button {
    width: 100%;
    height: 100%;
  }
}

.senderInfoContainer {
  display: flex;
  align-items: center;
}

.senderInfo {
  margin-left: 16px;
}

.senderEmail {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
}

.mailCreateDate {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $subtextColor;
}

.mailContent {
  font-family: $fontFamilyMonsterrat;
  padding: 36px 30px;
  color: $defaultTextColor;
  display: block;
  height: auto;
  width: 100%;
}

.attachmentsTitle {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  margin-bottom: 16px;
}

.downloadAttachments {
  margin-top: 10px;
}

.attachmentsContainer {
  margin: 0 30px;
}

.buttonsContainer {
  margin: 36px 30px 0 30px;
  display: flex;

  & > button {
    display: flex;
    align-items: center;
    margin-right: 12px;

    & > * {
      margin-right: 6px;
    }
  }
}

.replyButton {
  padding-left: 30px;
}

:export {
  mailTextColor: $defaultTextColor;
  mailTextFontSize: $pFontSize;
  mailTextLineHeight: $pLineHeight;
  mailTextFontWeight: $normalFontWeight;
}
