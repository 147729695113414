@import 'styles/variables';

.description {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
}

.emailAddress {
  @extend .description;
  margin: 20px 0 36px 0;
}
