@import 'styles/variables';

.tabsContainer {
  padding: 25px;
  flex: 1 0 0;
  display: flex;
  flex-flow: column nowrap;

  .tabContainer {
    margin-top: 28px;
    flex: 1 0;
    display: flex;
    flex-flow: column nowrap;
  }
}

.skeletonTabsContainer {
  margin: 0 25px;
  padding: 36px 0;
  border-top: solid 1px $borderAndSeparatorsColor;
}
