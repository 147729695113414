@import 'styles/variables';

.container {
  display: flex;
  min-height: 100%;
  width: 100%;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding-left: 55px;
  padding-right: 30px;
  margin: auto;
}

.image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
    max-width: 800px;
  }
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
}

.infoContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-width: 330px;
  width: 330px;
}

.info {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  text-align: left;
  margin: 24px 0 36px;
}
