@import 'styles/variables';

.container {
  width: 710px;
  padding: 36px 55px 60px 55px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
}

.branchName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h3FontSize;
  line-height: $h3LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.updaterInfoContainer {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.closeButton {
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  left: 20px;
  stroke: $primary80;
}

.updateDateContainer {
  margin-left: 8px;
  font-size: 14px;
  font-weight: $normalFontWeight;
  color: $defaultTextColor;
}

.buttonContainer {
  margin-top: 36px;
}

.variableTypeTag {
  margin-right: 12px;
}

.notFoundContainer {
  @extend .container;
  padding-left: 0;
}

.notFoundHeader {
  @extend .header;
  justify-content: flex-end;
  margin-bottom: 0;
}
