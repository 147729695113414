@import 'styles/variables';
@import 'styles/mixins';

.decisionsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-feature-settings: 'tnum' on, 'lnum' on;
  padding: 36px 55px 0;
  margin-bottom: 16px;

  &__embedded {
    padding: 0;
  }

  .headerTitle {
    @include headerTitle;
  }
}

.runDecisionButton {
  width: 140px;
}

.decisionsSwitch {
  padding-bottom: 10px;
  border-bottom: 1px solid $borderAndSeparatorsColor;

  & > label {
    font-style: normal;
    font-weight: $semiboldFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $subtextColor;
    cursor: pointer;
    padding-bottom: 10px;
    margin-right: 24px;
  }

  & > input[type='radio'] {
    display: none;

    &:checked {
      + label {
        color: $headerTextColor;
        border-bottom: 2px solid $orange100Base;
      }
    }
  }
}

.input {
  flex: 1 0 calc(50% - 30px);

  &:nth-child(odd) {
    margin-right: 30px;
    max-width: calc(50% - 30px);
  }
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.noData {
  margin-top: 136px;

  &__embedded {
    margin-top: 58px;
  }
}

.notFound {
  padding: 0 55px;
  margin-top: 24px;
}

.noDataTitle {
  margin: 36px 0 12px 0;
}

.filterContainer {
  display: flex;
  margin-bottom: 24px;
  padding: 0 55px;

  &__embedded {
    padding: 0;
  }
}

.filterButton {
  margin-left: auto;
}

.userFilter {
  display: flex;
  margin-right: auto;
  margin-left: 24px;
}
