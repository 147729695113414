@import 'components/NoItems/CommonVariables.module';

$noDataAdditionalOffset: 24px;

.noApplicationsAddedContainer {
  margin-top: $noDataTopOffset + $noDataAdditionalOffset;
}

.noApplicationsAddedButton {
  margin-top: 12px;
}

.noApplicationsAddedTitle {
  margin-top: 36px;
}

.noApplicationsFoundContainer {
  margin-top: $noDataTopOffset + $noDataAdditionalOffset;
}

.noApplicationsFoundTitle {
  margin-top: 12px;
}

.noApplicationsFoundSubtitle {
  margin: 8px 0 0 0;
}

.noApplicationsFoundButton {
  margin-top: 24px;
}

.searchNotFound {
  margin-top: 48px;
  padding: 0 55px;
}
