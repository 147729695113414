@import 'styles/variables';

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.titleWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > h4 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }
}

.questionIcon {
  margin-left: 12px;
}

.questionIcon:after {
  background-color: $white100Base;
  border: 1px solid $borderAndSeparatorsColor;

  &:hover {
    border: 1px solid $primary40;
  }
}

.cardBody {
  background-color: $white100Base;
  box-sizing: border-box;
}

.actions {
  position: absolute;
  right: 0;
  top: -25%;
}
