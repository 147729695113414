@import 'styles/variables';

.decisionOverviewContainer {
  background-color: $surfacesLight;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
}

.decisionTitle {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: 18px;
  line-height: 24px;
  color: $headerTextColor;
  margin-bottom: 7px;
}

.decisionInfo {
  display: flex;
  gap: 3%;
}

.decisionInfoCell {
  min-width: 32%;

  & p {
    font-size: 14px;
    line-height: 20px;
    color: $defaultTextColor;
  }

  .propertyName {
    font-size: 12px;
    line-height: 16px;
    color: $subtextColor;
    white-space: break-spaces;
    margin-bottom: 2px;
  }

  .propertyValue {
    display: flex;
    flex-flow: row;
    align-items: baseline;
  }
}

.decisionInfoRow {
  padding-left: 0;
}

.columnViewItem {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.noPadding {
  padding: 0;
}

.lastRow {
  border-bottom: 0;
}

.noLeftPadding {
  padding-left: 0;
}

.link {
  font-size: 14px;
  line-height: 20px;
  color: $primary80;

  &:hover {
    color: $secondary100Base;
  }
}

.tooltip {
  background-color: $surfacesPrimary;
  margin-left: 4px;
  border-radius: 50%;
}
