@import 'styles/variables';

.subheader {
  padding: 0 55px;
  min-height: 36px;
}

.tabsSwitch {
  margin-top: 24px;
  padding: 0 55px;
}

.tab {
  & label {
    margin-right: 36px;
  }
}

.applicationAutomationContainer {
  display: flex;
  flex: 1;
  padding: 34px 55px;
  background-color: $white100Base;
}
