@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-shrink: 0;
  padding: 0 36px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 24px;
  color: $headerTextColor;
}

.closeButton {
  border: none;
  background-color: transparent;
  height: 36px;
  width: 36px;
  padding: 0;
  cursor: pointer;
  outline: none;

  & svg {
    width: 100%;
    height: auto;
    margin: -7px;

    & path {
      stroke: $defaultTextColor;
    }
  }
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 36px;

  .searchInput {
    width: 100%;
  }
}

.actionsContainer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 36px;
  padding: 0 36px;
}
