@import 'styles/variables';

.downloadApiRequestTemplateButton {
  display: flex;
  align-items: center;
  min-width: 243px;
  padding: 10px 20px 10px 16px;

  & svg {
    margin-right: 6px;

    path:first-child {
      fill: $secondary100Base;
    }

    path:last-child {
      stroke: $secondary100Base;
    }
  }
}
