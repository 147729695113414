@import 'styles/variables';

.changeStatusButton {
  margin-right: 12px;
}

.goBackButton {
  min-width: 180px;
}

.changeStatusRejectTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: $primary100Base;
  margin-bottom: 8px;
}
