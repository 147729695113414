@import 'styles/variables';

.sourceVariableName {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 10px;
}

.validationResult {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px 48px;
  border-bottom: 1px solid $borderAndSeparatorsColor;

  &:first-child {
    border-top: 1px solid $borderAndSeparatorsColor;
  }

  &.failed {
    .sourceVariableName {
      color: $defaultErrorColor;
    }
  }
}

.resultIcon {
  position: absolute;
  top: 10px;
  left: 0;
}

.line {
  display: flex;
  flex-direction: row;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  & > p:first-child {
    width: 74px;
    margin-right: 58px;
    color: $placeholderTextColor;
  }

  & > p:last-child {
    color: $defaultTextColor;
  }
}

.bold {
  font-weight: $boldFontWeight;
}
