@import 'styles/variables';

.smallTitle {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
}

.subtitleContainer {
  max-width: 690px;

  h4 {
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    color: $headerTextColor;
    margin-bottom: 20px;
  }

  p {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $primary80;
  }
}

.subtitle {
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
}

.connectEmailsButton {
  width: 160px;
}

.sendEmailButton {
  margin-left: 12px;
  width: 140px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  margin-right: 93px;
}

.smallDescription {
  @extend .description;
  margin-top: 16px;
}
