@import "styles/variables";

.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 0;
}

.icon {
  margin-bottom: 36px;
}

@mixin text {
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.message {
  @include text;
  color: $headerTextColor;
  font-weight: $boldFontWeight;
}

.details {
  @include text;
  color: $defaultTextColor;
  text-align: center;
  font-weight: $normalFontWeight;
}
