@import 'styles/variables';
@import 'styles/mixins';

.strategyStatus {
  @include labelContainer;
  height: 16px;
  padding: 0 6px;
}

.statusActive {
  background-color: $green10;
  color: $green100Base;
}

.statusInactive {
  background-color: $surfacesLight;
}

.statusArchive {
  color: $primary40;
}

.statusTesting {
  background-color: $secondary10;
  color: $secondary100Base;
}
