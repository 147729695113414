@import 'styles/variables';

.applyFilter {
  width: 100%;
  margin-top: 36px;
}

.filterLayoutTitle {
  margin-bottom: 0;
}

.statusDropdownContainer {
  margin: 0;
}

.typeTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  padding-bottom: 12px;
  color: $primary100Base;
}

.variableTypesContainer {
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: space-between;
}

.dataTypeInput {
  margin-top: 0;
}

:export {
  customVariableType: $primary80;
  standardVariableType: $secondary100Base;
  passedLabelColor: $green100Base;
  failedLabelColor: $red100Base;
  errorLabelColor: $orange100Base;
  strategyStatusTesting: $secondary100Base;
  strategyStatusInactive: $primary80;
}
