@import 'components/NoItems/CommonVariables.module';

.contentContainer {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-flow: column;
}

.tasksNoData {
  margin-top: $noDataTopOffset;
}

.tasksNoDataSmall {
  margin-top: 24px;
}

.tasksNoDataTitle {
  margin: 36px 0 12px 0;
}

.noItemsSubtitle {
  margin: 8px 0 24px 0;
}

.tasksNoFilterResults {
  margin-top: 66px;
}

.tasksNoFilterResultsSmall {
  margin-top: 70px;
}

.notFoundContainer {
  margin-top: 26px;

  &.normal {
    padding: 0 55px;
  }
}
