@import 'styles/variables';

.fieldRow {
  display: flex;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.fieldContainer{
  width: 100%;
  padding-top: 0;
  margin-top: 0;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.buttonsContainer {
  display: flex;
  margin-top: 36px;
  width: 100%;
}

.closeEditorButton {
  margin-left: 12px;
}
