@import 'styles/variables';

.popup {
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 36px 36px 0 55px;
  }

  &Title {
    display: flex;
    flex-direction: row;
    align-items: center;

    & h3 {
      font-style: normal;
      font-weight: $boldFontWeight;
      font-size: $h3FontSize;
      line-height: $h3LineHeight;
      color: $headerTextColor;
    }
  }
}

.questionIcon {
  margin-left: 12px;
}

.titleIconsContainer {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
