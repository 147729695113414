@import 'styles/mixins';
@import 'styles/variables';

.headerPreview {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 30px;
}

h2 {
  margin-right: 12px;
  @include headerTitle;
}

.titleName {
  margin-right: 12px;
}

.titleContent {
  display: flex;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h3FontSize;
  line-height: $h2LineHeight;
  color: $orange100Base;
}

.questionTooltip {
  margin-left: 8px;
}

.infoPanel {
  margin-top: 20px;
}
