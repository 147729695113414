@import 'styles/variables';

.tableCell {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.draggableRuleRow {
  &:before {
    top: -1px;
    right: 32px;
    left: 30px;
  }
}

.ruleRow {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding: 0 0 0 10px;
  width: 100%;

  &:before {
    border-top: 1px solid $borderAndSeparatorsColor;
    content: '';
    position: absolute;
    top: -1px;
    left: 40px;
    right: 32px;
  }

  .actionsButton {
    display: flex;
    opacity: 0;
    transition: 0.25s opacity ease-in-out;
  }

  &:hover {
    padding: 0 0 0 10px;
    background-color: $secondary6;

    .dragImage,
    .actionsButton {
      opacity: 1;
    }

    .actionsButton button {
      opacity: 1;
    }
  }
}

.dragging {
  background-color: white;
  padding: 0;

  .dragImage,
  .actionsButton {
    display: flex;
  }
}

.dragImage {
  opacity: 0;
  display: flex;
  align-items: center;
  transition: 0.25s opacity ease-in-out;
  min-width: 30px;
}

.variable {
  @extend .tableCell;
  font-weight: $boldFontWeight;
  color: $primary100Base;
}

.value {
  @extend .tableCell;
}

.output {
  @extend .tableCell;
  font-size: $smallFontSize;
}

.comparison {
  @extend .tableCell;
  min-width: 100px;
}

.ruleContainer {
  display: flex;
  position: relative;
  justify-content: flex-start;
  width: 100%;
}

.conditionContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding: 16px 0;
}

.separator {
  font-weight: $boldFontWeight;
  font-size: $tableHeadFontSize;
  line-height: $tableHeadLineHeight;
  letter-spacing: $defaultLetterSpacing;
  color: $orange100Base;
  text-transform: uppercase;
  z-index: 10;
}

.rowsSeparator {
  @extend .separator;
  position: absolute;
  bottom: -6px;
  background-color: $white100Base;
  margin-left: 26px;
  padding: 0 4px;
}

.rulesSeparator {
  @extend .separator;
  color: $primary100Base;
  background-color: $surfacesLight;
  display: inline-block;
  margin: 3px 0 5px -4px;
  padding: 0 4px;
}

.outputsContainer {
  padding: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skeletonContainer {
  margin: 0 0 -1px 30px;
}
