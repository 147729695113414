@import 'styles/variables';

@mixin headerTitle {
  color: $headerTextColor;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-family: $fontFamilyMonsterrat;
}

@mixin headerGuideText {
  p {
    color: $defaultLinkColor;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    padding-right: 2px;
  }
}

@mixin buttonReset {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  text-align: start;
  cursor: pointer;
  outline: 0;
}

@mixin buttonOptions {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    padding: 0;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out;
    border-radius: 4px;

    &:hover {
      background-color: $secondary10;
    }

    & > svg {
      & > circle {
        fill: $primary80;
      }
    }
  }
}

@mixin notFoundContainer {
  margin-left: 55px;
  margin-top: 26px;
}

@mixin labelContainer {
  display: flex;
  align-items: center;
  background-color: $surfacesLight;
  border-radius: 2px;
  color: $defaultTextColor;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
}

@mixin overflowOverlayY {
  overflow-y: #{'overlay'};
}

@mixin titlePadding {
  padding: 36px 55px 24px 55px;
}

@mixin descriptionText {
  color: $defaultTextColor;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}
