@import 'styles/variables';

.draggableInputStubWrapper {
  width: 100%;
}

.draggableInputStub {
  width: 100%;
  position: relative;
  cursor: grab;

  &.dragging {
    padding: 12px 36px;
    background-color: $surfacesPrimary;

    & .deleteAction {
      right: 8px;
    }

    & .dragImage {
      display: block;
    }
  }

  .innerContainer {
    width: 100%;
    position: relative;
  }

  &:hover {
    & .dragImage {
      display: block;
    }
  }

  .rowActions {
    position: absolute;
    right: -32px;
    bottom: 12px;
  }

  .dragImage {
    display: none;
    position: absolute;
    bottom: 12px;
    left: -24px;
  }
}

.rowActionsPopup {
  width: 280px;
}

.disabledInputStub {
  cursor: default;
}