@import 'styles/variables';

.skeletonRecord {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 82px;
  padding: 18px 0 18px 60px;

  &:not(:first-child) {
    border-top: 1px solid $borderAndSeparatorsColor;
  }
}

.icon {
  position: absolute;
  left: 0;
}

.skeletonRecordInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
