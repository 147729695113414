@import 'styles/variables';

.cardContainer {
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  &__selected {
    border: 1px solid $secondary100Base;
    background-color: $secondary10;
  }

  &__inactive {
    background-color: $primary2;
    color: $primary40;
  }
}

.strategyName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  white-space: nowrap;
  max-width: 430px;
  overflow: hidden;
  text-overflow: ellipsis;

  &__inactive {
    color: $primary40;
  }
}

.versionInfoRow {
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 0;
  cursor: pointer;
  transition: 0.25s background-color ease-in-out;
  &:hover {
    background-color: $secondary6;

    & button {
      opacity: 1;
      background-color: $secondary6;
    }
  }

  p {
    font-size: $pFontSize;
    line-height: $pLineHeight;
  }
}

.actionsContainer {
  right: -8px;
  position: absolute;
  top: 10px;
  right: 0;
  justify-content: unset;
  align-content: flex-end;
}

.divider {
  border-top: 1px solid $primary6;
  width: 100%;
}

.lastDivider {
  @extend .divider;
  margin-bottom: -1px;
}

.footer {
  border-top: 1px solid $primary20;
  border-radius: 0 0 5px 6px;
  padding: 0 24px;
  margin-top: auto;
  height: 44px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__hoverable:hover {
    background-color: $secondary6;
  }
}

.footerText {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
}

.footerLink {
  @extend .footerText;
  color: $defaultLinkColor;
  cursor: pointer;
}

.versionsInfoWithAction {
  display: flex;
  justify-content: space-between;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 24px;

  &:hover {
    & button {
      opacity: 1;
      background-color: $white100Base;
    }
  }
}
