@import 'styles/variables';

.foldersTableRow {
  border: 1px solid transparent;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.foldersTableBodyCell {
  padding: 13px 10px;
}

.foldersTableBodyCellName {
  margin-left: 10px;
}
