@import 'styles/variables';

.textElementContainer {
  height: 52px;
  width: 120px;
}

.symbolElementContainer {
  height: 52px;
  width: 52px;
}

.elementContainer {
  border: 1px solid $borderAndSeparatorsColor;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $headerTextColor;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
}

.disabledElementContainer {
  @extend .elementContainer;
  background: $primary6;
}

.activeElementContainer {
  @extend .elementContainer;
  background: $secondary10;
  border: 1px solid $secondary100Base;
  color: $secondary100Base;
}

.displayNameContainer {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
