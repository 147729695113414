@import 'styles/variables';
@import 'styles/mixins';

.container {
  position: relative;
  width: 980px;
  padding: 36px 55px 60px 55px;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

.content {
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
}

.tabsContainer {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
}

.tabContainer {
  padding-top: 36px;
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
}

.title {
  display: flex;
  justify-content: space-between;

  h2 {
    @include headerTitle;
  }
}

.closeButton {
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}

.editBorrowerForm {
  margin-top: 32px;
}
