@import 'styles/colorPalette';
@import 'components/BillingDashboard/Overview/OverviewVariables';

.label {
  padding-bottom: 24px;
  border-bottom: $separator;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: $primary100Base;
}

.product {
  padding: 24px 0;
  border-bottom: $separator;
  display: flex;
}

.productName {
  margin-right: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: $primary100Base;
}
