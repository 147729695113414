@import 'styles/variables';

.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  position: relative;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.actions {
  display: flex;
  width: max-content;
  position: absolute;
  top: 50%;
  left: calc(100% + 24px);
  transform: translateY(-50%);
}

.titleWithActions {
  position: relative;
}
