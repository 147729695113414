@import 'components/NoItems/CommonVariables.module';

.labelsNoData {
  margin-top: $noDataTopOffset;
}

.contentContainer {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-flow: column;
}

.tasksNoDataTitle {
  margin: 36px 0 12px 0;
}
