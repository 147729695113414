@import 'styles/variables';

.collapseContainer {
  width: 100%;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.card {
  width: 100%;

  .cardName {
    min-height: 24px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    margin-bottom: 24px;
  }
}

.configurableForm {
  margin-top: -24px;
}
