@import 'styles/variables';

.statusRulesValidationResult {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  &.collapsed {
    border-bottom: 1px solid $borderAndSeparatorsColor;
  }
}

.statusRulesValidationResultHeader {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 48px;
  margin-bottom: 12px;

  .statusName {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    margin-right: 12px;
  }

  .rulesPassedInfo {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $placeholderTextColor;
    margin-top: 4px;
  }

  .subHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tag {
    width: 112px;
    height: 16px;
  }
}

.chevronIcon {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;

  &:not(.chevronIconDisabled) {
    cursor: pointer;
  }

  &.chevronIconDisabled {
    & path {
      stroke: $subtextColor;
    }
  }

  &.chevronIconActive {
    transform: rotate(0);
  }
}

.statusIndex {
  position: absolute;
  top: 0;
  left: 0;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}
