@import 'components/NoItems/CommonVariables.module';
@import 'styles/mixins';

.intermediariesNoData {
  margin-top: $noDataTopOffset;
}
.contentContainer {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-flow: column;
}

.intermediariesNoDataTitle {
  margin: 36px 0 12px 0;
}

.notFoundContainer {
  @include notFoundContainer;
}

.noResultsFoundContainer {
  margin-top: 60px;
}
