@import 'styles/colorPalette';
@import 'components/BillingDashboard/Overview/OverviewVariables';

.label {
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: $primary100Base;
}

.columns {
  display: grid;
  grid-row-gap: 12px;
  grid-auto-flow: column;

  @media (max-width: 1800px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(9, auto);
  }

  @media (min-width: 1801px) {
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: repeat(6, auto);
  }
}

.feature {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $primary80;

  > svg {
    width: 20px;
    height: 20px;
  }

  .featureIcon {
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
}

.professionalServicesRateIcon {
  margin-left: 4px;
}
