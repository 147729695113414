@import 'styles/colorPalette';
@import 'styles/variables';

$separator: 1px solid $primary10;

.container {
  background-color: $primary2;
  border-radius: 4px;
}

.primaryInfo {
  padding: 24px;
  border-bottom: $separator;

  .title {
    margin-bottom: 6px;
  }

  .value {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: $green100Base;
  }

  .subTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primary80;
  }
}

.secondaryInfo {
  $padding: 24px;

  padding-top: $padding;
  padding-bottom: $padding;

  .title {
    margin-bottom: 12px;
    padding: 0 $padding;
  }

  .secondaryItem {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .secondaryItemContainer {
    padding: 0 ($padding);
    max-height: 140px;
    overflow-x: hidden;
  }

  .secondaryItemTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primary80;
  }

  .secondaryItemValue {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $primary100Base;
  }

  .total {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: $separator;
    margin: 0 $padding;
  }

  .totalTitle {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }

  .totalValue {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary100Base;
  }
}

.title {
  margin-right: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: $primary100Base;
}

.downloadButton,
.downloadButton.disabled {
  background-color: $secondary100Base;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 10px 18px;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  > svg {
    path {
      stroke: $surfacesPrimary;
    }
  }

  &:hover {
    border: none;
    background-color: $secondary120;
  }

  &:focus {
    border: none;
    background-color: $secondary120;
  }

  .downloadButtonText {
    font-size: 14px;
    line-height: 20px;
    color: $surfacesPrimary;
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-left: 18px;
  }

  .downloadButtonTitle {
    font-weight: bold;
  }

  .downloadButtonSubTitle {
    font-weight: 500;
    opacity: 0.5;
  }
}

.container {
  .downloadButton:focus,
  .downloadButton.disabled:focus,
  .downloadButton.disabled:hover,
  .downloadButton:focus-visible,
  .downloadButton.disabled:focus-visible {
    border: none;
    background-color: $secondary100Base;
  }
}
