@import 'styles/variables';

.saveButton {
  width: 180px;
  margin-top: 36px;
}

.colorsContainer {
  display: flex;
}
