@import 'styles/variables';

.contextualViewContentSlideContainer {
  transition: 0.5s;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.contextualViewSlide {
  min-width: 100%;
}
