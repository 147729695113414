.transitionExpand {
  overflow: hidden;

  &__initial {
    transition: none;
  }

  &__animated {
    transition: width 300ms ease-in-out, height 300ms ease-in-out, transform 300ms ease-in-out;
  }
}
