@import 'styles/variables';

.infoPanelColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 240px;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.infoPanelColumnHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $primary100Base;
}

.collapsedHeader {
  flex-direction: column;
}

.infoPanelColumnItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;

  & > div {
    width: 100%;
    flex: 1 1 100%;
  }

  max-height: 67px;
}

.openedInfoPanelColumnsItem {
  max-height: 500px;
}

.twoColumnsInfoPanelColumnItems {
  & > div {
    width: 50%;
    flex: 1 1 50%;
  }

  & > div:nth-child(2) {
    border-top: 1px solid $borderAndSeparatorsColor;
  }
}

.infoPanelColumnItem {
  min-height: 67px;
  padding: 14px 8px 14px 0;

  box-sizing: border-box;

  border-top: 1px solid $borderAndSeparatorsColor;
}

.infoPanelColumnItemLabel {
  color: $primary40;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.infoPanelColumnItemContent {
  position: relative;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;

  & a {
    color: $defaultTextColor;
    transition: 0.25s color ease-in-out;

    &:hover {
      color: $defaultLinkColor;
    }
  }
}

.title {
  margin-right: 8px;
}

.titleContent {
  display: flex;
}
