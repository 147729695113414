@import 'styles/variables';

.checkBox {
  padding: 0 16px 0 0;
}

.doneTask {
  text-decoration-line: line-through;
  color: $primary40;
}

.applicationId {
  &:hover {
    color: $secondary100Base;
  }
}

.teamMembersCell {
  text-decoration-line: none;
}

.formatIcon {
  margin-right: 12px;
}

.attachmentType {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
