@import 'styles/variables';

.ace_gutter {
  min-width: 48px;
  background: $primary2 !important;
  color: $primary40 !important;

  & > div:first-child {
    min-width: 48px;
  }
}

.ace_placeholder {
  padding-left: 10px !important;
  padding-top: 16px !important;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
}
