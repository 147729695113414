@import 'styles/variables';

.contextualViewWrapperContainerHidden {
  flex: 0 0 0;
  height: 100vh;
  width: 0;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.contextualViewWrapperContainerShow {
  @extend .contextualViewWrapperContainerHidden;
  transform: translateX(0);
}
