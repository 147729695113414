.mainLayoutNotifications {
  transition: left 0.5s ease-in-out;
}

.leftNavigationExpanded {
  left: 280px;
}

.leftNavigationCollapsed {
  left: 72px;
}

.empty {
  pointer-events: none;
}
