@import 'styles/variables';

.skeletonCirclesList {
  display: flex;
}

.skeletonCirclesListItem {
  border: 2px solid $white100Base;
}

.skeletonCirclesListItem:not(:first-child) {
  margin-left: -10px;
}
