@import 'styles/variables';

.deleteRuleButton {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #f76565;
  cursor: pointer;
}

.dividerContainer {
  padding-top: 36px;
}
