@import 'styles/variables';

.header {
  padding: 0 55px;
}

.subheader {
  margin-bottom: 24px;
}

.tabsContainer {
  position: relative;
}

.addPageButton {
  position: absolute;
}

.actionsContainer {
  background-color: $surfacesPrimary;
  box-shadow: 0 8px 24px rgba(26, 35, 56, 0.2);
  border-radius: $borderRadiusDefault;
  width: 240px;
  outline: 0;
  height: auto;
  cursor: default;
}

.formSettingsButton {
  min-width: 145px;
  margin-left: 12px;
  padding: 6px 8px;
  border-radius: 6px;

  &:not(:disabled):hover {
    background-color: $secondary10;
    color: $secondary100Base;
  }
}

.disabledAddPageButtonContextualButtonContainer {
  &:hover {
    & > button {
      background-color: $primary2;
    }
  }
}

.addPageButtonContextualButton {
  &:disabled {
    background-color: $white100Base;
    pointer-events: none;
  }
}

.disabledAddPageButton {
  pointer-events: none;
}

.tabSwitchSkeleton {
  margin-bottom: 12px;
}

.tab {
  & label {
    margin-right: 36px;
  }
}

.tabSwitchBorderContainer {
  & > div {
    height: 1px;
    top: calc(100% - 1px);
  }
}

.removeTabImage {
  right: 15px;
}

.questionTooltip {
  margin-left: 8px;
}
