@import 'styles/variables';
@import 'components/RuleRow/RuleRow.module.scss';

.tableHeaderRow {
  height: 36px;
  display: flex;
  align-items: center;
  margin: 24px 30px 0;
  padding-left: 10px;
}

.tableHeaderCell {
  font-weight: $boldFontWeight;
  text-transform: uppercase;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  color: $subtextColor;
  letter-spacing: $defaultLetterSpacing;
}

.tableHeaderUnderline {
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.variable {
  @extend .tableCell;
  font-weight: $boldFontWeight;
  width: 230px;
  max-width: 230px;
}

.comparison {
  @extend .tableCell;
  width: 100px;
  padding-left: 24px;
}

.value {
  @extend .tableCell;
  width: 210px;
  padding-left: 24px;
}

.output {
  @extend .output;
  width: 314px;
  padding-left: 24px;
}

.skeletonOutput {
  @extend .output;
  width: 250px;
}

.skeletonOutputWide {
  width: 230px;
}

.skeletonValueWide {
  width: 457px;
  padding-left: 24px;
}

.variableHeaderCell {
  @extend .variable;
  @extend .tableHeaderCell;
}

.comparisonHeaderCell {
  @extend .comparison;
  @extend .tableHeaderCell;
}

.valueHeaderCell {
  @extend .value;
  @extend .tableHeaderCell;
}

.outputHeaderCell {
  @extend .output;
  @extend .tableHeaderCell;
}

.variableWide {
  @extend .variable;
  width: 230px;
}

.valueWide {
  @extend .tableCell;
  width: 457px;
  padding-left: 24px;
  word-break: break-word;
}

.variableHeaderCellWide {
  @extend .variableWide;
  @extend .tableHeaderCell;
}

.valueHeaderCellWide {
  @extend .valueWide;
  @extend .tableHeaderCell;
}

.skeletonTable {
  margin: 0 30px;
}

.skeletonRow {
  justify-content: flex-start;
}
