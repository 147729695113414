@import 'styles/variables';

.content {
  padding: 0 55px;
  margin: 18px 0 48px;
  width: 100%;
  height: auto;
}

.closeWindowButton {
  margin-top: 36px;
}

.validationConditionResult {
  display: flex;
  align-items: center;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;

  margin-top: 12px;

  & > p {
    margin-left: 12px;
  }
}

.invalidValidationConditionResult {
  color: $red100Base;
}
