@import 'styles/mixins';
@import 'styles/variables';

.title {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.titleInner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.titleName {
  @include headerTitle;
}

.addLabelButton {
  width: 160px;
}

.searchInput {
  margin-right: 24px;
}
