@import 'styles/variables';

.container {
  margin-top: 24px;
}

.colorInputContainer {
  height: 52px;
  width: 188px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: relative;

  & > p {
    position: absolute;
    left: 84px;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $headerTextColor;
  }
}

.inputTitle {
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.colorBlock {
  min-width: 52px;
  height: 100%;
  border-bottom-left-radius: $borderRadiusDefault;
  border-top-left-radius: $borderRadiusDefault;
  cursor: pointer;
}

.whiteColorBlock {
  border: 1px solid $borderAndSeparatorsColor;
}

.colorInput {
  height: 100%;
  width: 136px;
  border: 1px solid $borderAndSeparatorsColor;
  padding: 16px 32px 16px 42px;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  border-bottom-right-radius: $borderRadiusDefault;
  border-top-right-radius: $borderRadiusDefault;
  border-left: 0;
  outline: 0;
}
