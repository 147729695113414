@import 'styles/variables';

.strategyData {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.strategyDataTable {
  max-height: 16px;
}

.strategyVersion {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-right: 8px;
}

.inactiveItem {
  .strategyVersion,
  .dateContainer {
    color: $subtextColor;
  }
}

.cardInfoContainer {
  position: relative;
  padding: 12px 0 12px 24px;
  width: 100%;
}

.tableInfoContainer {
  display: block;
  position: relative;
  padding: 12px 20px 12px 12px;
  width: 33%;

  &:hover {
    background-color: $white100Base;
    box-shadow: 0 1px 3px rgba(15, 35, 66, 0.07);
    border-radius: 4px;

    .actionsBtnClassName {
      opacity: 1;
    }
  }

  &.disabled {
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.strategyVersionTable {
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
}

.strategyUpdateInfo {
  display: flex;
  align-items: center;
}

.dateContainer {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  margin-left: 8px;
}

.dateContainerTable {
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
}

.avatar {
  .avatarText {
    letter-spacing: $defaultLetterSpacing;
    font-size: $tagLineHeight;
    line-height: $tableHeadLineHeight;
  }
}
