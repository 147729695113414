@import 'styles/variables';

.foldersTable {
  margin-top: 24px;
}

.foldersTableRow {
  &Dragging {
    background-color: $surfacesPrimary;
    border: none;
    box-shadow: 0px 8px 24px rgba(26, 35, 56, 0.2);
  }

  &CombineTarget {
    border: 1px solid $defaultLinkColor;
  }

  &Grayed {
    opacity: 0.5;
    background-color: $primary2;
  }
}

.foldersTableHeadCell {
  color: $primary100Base;
}
