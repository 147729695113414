@import 'styles/variables';

.closeEditorButton {
  position: absolute;
  min-width: 140px;
  top: -25%;
  right: 0;
  padding: 12px 25.5px;
}

.table {
  border-top: 1px solid $borderAndSeparatorsColor;
}

.tableRow {
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.tableRowLabel {
  max-width: 330px;
  width: 100%;
  padding: 0 10px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $placeholderTextColor;
}

.tableRowValue {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
}

.userAvatar{
  margin-right: 8px;
}