.row {
  position: relative;
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;

    .addCardInNewRowButton {
      display: none;
    }
  }

  .addCardInSameRowButton {
    position: absolute;
    right: -32px;
  }

  .addCardInNewRowButton {
    position: absolute;
    bottom: -40px;
  }
}

.variablesConfigurationCard {
  width: 100%;

  &:not(:last-child) {
    margin-right: 30px;
  }
}
