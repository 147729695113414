.inputsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  width: 100%;
}

.firstInputContainer {
  width: 48%;
}

.secondInputContainer {
  width: 48%;
}

.ruleTable {
  position: relative;
}
