@import 'styles/variables';

.container {
  width: 100%;
  padding: 36px 55px 60px;

  & > h4 {
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    color: $headerTextColor;
  }
}
