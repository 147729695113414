@import 'styles/variables';

.pageContainer {
  min-height: 100%;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.branchName {
  display: flex;
}

.title {
  display: flex;
}

.titleText {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0f2342;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.generateSecretButton {
  margin-left: 12px;
  width: 210px;
}

.content {
  margin-top: 36px;
}

.tableTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $headerTextColor;
  margin-bottom: 24px;
}

.credentialsTable {
  border-top: 1px solid $borderAndSeparatorsColor;
}

.credentialsName {
  color: $subtextColor;
}
