@import 'styles/variables';

.integrationInput {
  width: 100%;
  padding-bottom: 22px;
}

.integrationOptions {
  max-height: 260px;
}

.contactUs {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  padding: 15px 24px 17px 24px;
  background-color: $surfacesPrimary;
  border-radius: $borderRadiusDefault;
  margin: 25px 0 55px 0;
}
