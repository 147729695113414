@import 'styles/variables';
@import 'styles/mixins';

.strategyPage {
  padding-top: 36px;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
}

.strategySwitch {
  padding-bottom: 10px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
  position: relative;

  & > label {
    font-style: normal;
    font-weight: $semiboldFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $subtextColor;
    cursor: pointer;
    padding-bottom: 10px;
    margin-right: 24px;
  }

  & > input[type='radio'] {
    display: none;

    &:checked {
      + label {
        color: $headerTextColor;
        border-bottom: 2px solid $orange100Base;
      }
    }
  }
}

.strategyContent {
  min-height: 100%;
}

.headerLine {
  display: flex;
  justify-content: space-between;
}

.collapsedButton {
  position: absolute;
  right: 0;
  bottom: 12px;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultLinkColor;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: color 0.25s ease-in-out;

  & > svg {
    stroke: $defaultLinkColor;
    transition: stroke 0.25s ease-in-out;
  }

  &:hover {
    color: $defaultLinkHoverColor;

    & > svg {
      stroke: $defaultLinkHoverColor;
    }
  }

  &:disabled {
    color: $primary20;

    & > svg {
      stroke: $primary20;
    }
  }
}

.strategyGeneralInfo {
  padding: 0 55px;
}
