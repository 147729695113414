@import 'styles/variables';
@import 'styles/mixins';

.container {
  padding: 36px 36px $bottomPaddingDefault 55px;
  width: 890px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  @include headerTitle;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 760px;
  overflow: hidden;
}

.closeImage {
  cursor: pointer;
  stroke: $primary80;
}

.tableContainer {
  padding-right: 19px;
}
