@import 'styles/variables';

.pageContent {
  padding: 22px 25px 50px 25px;
  display: flex;
  flex-flow: column nowrap;
}

.tabsContainer {
  margin: 0 25px;
  padding: 36px 0;
  border-top: solid 1px $borderAndSeparatorsColor;
}
