.skeletonHeader {
  display: flex;
  flex-direction: column;
}

.skeletonTitle {
  display: flex;
  flex-direction: column;
  padding: 14px 30px 0;

  & > div {
    &:first-child {
      display: flex;
      justify-content: space-between;
    }
    & > div {
      display: flex;
      gap: 12px;
    }
  }

}
