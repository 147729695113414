@import 'styles/mixins';
@import 'styles/variables';

.title {
  display: flex;
  flex-direction: column;
  @include titlePadding;
}

.titleInner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.titleName {
  @include headerTitle;
}

.searchInput {
  width: 420px;
  margin-right: 24px;
}

.filtersContainer {
  display: flex;
}

.filterButton {
  margin-left: auto;
}
