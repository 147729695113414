@import 'styles/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  flex-grow: 1;
  padding-top: 36px;
  background-color: $surfacesLight;
}

.statusesHeader {
  margin-bottom: 24px;
  padding: 0 55px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.defaultStatuses {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 55px 24px;
}

.defaultStatusCard {
  padding: 24px 20px 10px 20px;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.editDeclineReasonsButton {
  position: absolute;
  right: 0;
  min-width: 194px;
  padding: 6px 8px;
  border-radius: 6px;
  margin-top: -8px;

  &:not(:disabled):hover {
    background-color: $secondary10;
    color: $secondary100Base;
  }
}
