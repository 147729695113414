@import 'styles/variables';

.editGroups {
  position: relative;
  width: 710px;
  padding: 36px 31px 60px 31px;

  &__header > h2 {
    padding: 0 24px 24px;
    color: $headerTextColor;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-family: $fontFamilyMonsterrat;
  }

  &__groupWrapper {
    position: relative;
    padding: 12px 24px;
    width: 100%;
    border-radius: 4px;
    background-color: $white100Base;

    &.dragging .editGroups__dragImage {
      display: block;
    }

    &:not(.disabled):hover {
      background-color: $secondary3;
      cursor: grab;

      .editGroups__dragImage {
        display: block;
      }
    }
  }

  &__groupInputWrapper {
    padding-top: 0;
  }

  &__dragImage {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
  }

  &__removeButtonWrapper {
    display: flex;
    width: fit-content;
    cursor: default;
  }

  &__removeButton {
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    color: $red100Base;

    &:hover {
      color: $secondary120;
    }

    &:disabled {
      color: $primary40;
      pointer-events: none;
    }
  }

  &__saveButton {
    margin-top: 36px;
    margin-left: 24px;
  }

  &__addNewButtonWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 24px 24px 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      z-index: -1;
      border: 1px dashed $primary10;
    }
  }

  &__addNewButton {
    display: flex;
    align-items: center;
    padding: 0 6px 0 2px;
    background-color: $white100Base;

    svg > path {
      stroke: $secondary100Base;
    }
  }

  &__closeImage {
    width: 36px;
    height: 36px;
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 36px;
    stroke: $primary80;
  }
}
