@import 'styles/variables';
@import 'styles/mixins';

.branch {
  display: flex;
  align-items: center;
  margin: 0;
  transition: border 0.25s ease-in-out;
  padding-right: 8px;
  width: 100%;

  & > svg {
    min-width: 24px;
    height: 24px;
  }

  &:hover .dragImage {
    opacity: 1;
  }

  &:hover .editButton {
    opacity: 1;
  }

  &:hover .branchNameInput {

    & > input,
    p {
      max-width: 376px;
    }
  }
}

.branchBody {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0 13px 0;
  border: 1px solid transparent;
  transition: border 0.25s ease-in-out;

  & > svg {
    margin: 0 18px 0 12px;

    & > path {
      stroke: $primary80;
    }
  }

  &--active {
    border: 1px solid $secondary100Base;
    background: $secondary10;
  }
}

.branchInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > p {
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $subtextColor;
  }
}

.dragImage {
  opacity: 0;
  margin-right: 36px;
  transition: 0.25s all ease-in-out;
}

.branchName {
  display: flex;
  align-items: center;
  height: 24px;
}

.branchNameInput {

  & > input,
  p {
    font-weight: $normalFontWeight;
    line-height: $pLineHeight;
    max-width: 508px;
  }
}

.editButton {
  border: 0;
  background-color: transparent;
  display: flex;
  opacity: 0;
  align-items: center;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $secondary100Base;
  padding: 2px 12px 2px 4px;
  margin-left: 6px;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  &:hover {
    background-color: $secondary10;
  }
}

.moreButton {
  &:hover {
    & > svg {
      & > circle {
        fill: $secondary100Base;
      }
    }
  }
}
