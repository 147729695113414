@import 'styles/variables';

.applyFilter {
  width: 100%;
  margin-top: 36px;
}

.filterLayoutTitle {
  margin-bottom: 16px;
}

.statusDropdownContainer {
  margin: 0;
}

.permissionGroupSelect {
  margin-top: 0;
}
