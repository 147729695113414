@import 'styles/variables';

.detailsForm {
  position: relative;
}

.detailsFormContent {
  display: grid;
  grid-column-gap: 30px;
}

.detailsFormHeader {
  position: relative;
  width: fit-content;
  margin-bottom: 24px;
}

.headerTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.field {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 44px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.withTopBorderField {
  border-top: 1px solid $borderAndSeparatorsColor;
}

.fieldLabel {
  padding: 0 10px;
  color: $primary40;
}

.fieldValue {
  padding: 0 10px;
  color: $primary80;
}

.editInformationButton {
  position: absolute;
  top: 50%;
  left: calc(100% + 12px);
  width: max-content;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: $normalFontWeight;
  line-height: 20px;
  padding: 8px;
  border-radius: 4px;

  &:not(:disabled) {
    color: $secondary100Base;

    &:hover {
      background-color: $secondary10;

      & > svg {
        stroke: $secondary100Base;
      }
    }
  }
}

.closeEditorButton {
  width: 140px;
  margin-right: 12px;
}

.saveChangesButton {
  width: fit-content;
}

.editFormActions {
  display: flex;
  position: absolute;
  top: -8px;
  right: 0;
}
