@import 'styles/variables';

.confirmationText {
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
}

.saveButton {
  width: 200px;
}

.downloadButton {
  width: 220px;
}
