@import 'styles/colorPalette';
@import 'styles/variables';
@import '../common.module.scss';

.content {
  margin-bottom: 0;
  overflow: hidden;
  width: $columnWidth;
  background-color: $surfacesLight;
  padding: 0 $paddingSize 0 $paddingSize;
  border-bottom-left-radius: $borderRadiusDefault;
  border-bottom-right-radius: $borderRadiusDefault;

  > div:not(:first-child) {
    margin-left: 12px;
  }

  .card {
    margin-bottom: 10px;
  }
}

.content:not(:first-child) {
  margin-left: 12px;
}

.draggingCard {
  box-shadow: 0px 8px 24px rgba(15, 35, 66, 0.2);
  cursor: grabbing;
}
