$primary120: #060d19;
$primary100Base: #0f2342;
$primary80: #3f4f68;
$primary60: #6f7b8e;
$primary40: #9fa7b3;
$primary20: #cfd3d9;
$primary10: #e7e9ec;
$primary6: #f1f2f4;
$primary2: #fafbfb;

$secondary120: #077ddf;
$secondary100Base: #1e95f8;
$secondary40: #a5d5fc;
$secondary10: #e8f4fe;
$secondary6: #f2f9ff;
$secondary3: #f8fcff;

$red100Base: #f76565;
$red50: #fbb2b2;
$red10: #fef0f0;

$orange120: #ff4909;
$orange100Base: #ff6b37;
$orange50: #ffb59b;
$orange10: #fff0eb;

$green110: #00c4ac;
$green100Base: #00c3ac;
$green50: #80e1d6;
$green10: #e5f9f7;

$lightOrange100Base: #ff8a00;
$lightOrange50: #ffc480;
$lightOrange10: #fff3e5;

$darkBlue120: #042d57;
$darkBlue110: #053361;
$darkBlue100Base: #053668;
$darkBlue90: #1e4a77;
$darkBlue20: #cdd7e1;

$white100Base: #ffffff;

$grey: #777c91;

$surfacesPrimary: $white100Base;
$surfacesSidebar: $darkBlue100Base;
$surfacesOverlaySolid: #9295a2;
$surfacesOverlay: rgba($surfacesOverlaySolid, 0.4);
$surfacesLight: #f1f2f4;
