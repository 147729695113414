.submitButton {
  margin-top: 36px;
}

.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
