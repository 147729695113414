@import 'styles/variables';

.configurationCard {
  padding: 24px 12px 24px 36px;
  background-color: $surfacesPrimary;
}

.variablesContainer {
  display: grid;
  gap: 6px;
}

.configurationCardHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.configurationCardTitle {
  input,
  p {
    max-width: 100%;
    padding-left: 0;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
  }

  margin-bottom: 12px;

  .noItemsTitle {
    margin-bottom: 24px;
  }
}

.layoutItem {
  padding: 12px 24px 12px 0;

  &:not(.disabledLayoutItem):hover {
    background-color: $secondary3;
    border-radius: 4px;
    padding-right: 24px;
    width: calc(100% + 24px);
    margin-left: -24px;
    padding-left: 24px;
  }
}

.rowActions {
  position: absolute;
  right: 0;
  min-width: auto;
  margin: 0;
}

.requiredVariablesList {
  margin-bottom: 24px;
}
