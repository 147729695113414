@import 'styles/variables';

.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.headerContainer {
  margin: 36px 55px 0;
}

.tableContainer {
  padding: 0 55px 60px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.createStrategyButton {
  min-width: 150px;
}
