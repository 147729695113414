@import 'styles/variables';

html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: $fontFamilyMonsterrat, sans-serif;
  height: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
}
