@import 'styles/variables';

.navLink {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $defaultLinkColor;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}
