@import 'styles/colorPalette';
@import 'components/BillingDashboard/Overview/OverviewVariables';

$columnSpaceSize: 30px;

.columns {
  display: flex;
}

.leftSideContainer {
  width: 75%;
  margin-right: $columnSpaceSize;
}

.rightSideContainer {
  width: calc(25% - #{$columnSpaceSize});
}

.licenseTierContainer {
  padding-bottom: 24px;
  border-bottom: $separator;
  color: $primary100Base;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

.monthlyPriceContainer {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: $separator;
  margin-bottom: 36px;
}

.featureContainer {
  margin-bottom: 48px;
}
