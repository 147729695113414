@import 'styles/variables';

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 36px 22px 16px;
  height: 64px;
  border-bottom: 1px solid $primary10;
}

.fileName {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.body {
  flex-grow: 1;
  min-height: 0;
  background-color: $secondary3;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.fileContainer {
  min-height: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p {
    line-height: $pLineHeight;
    font-size: $pFontSize;
    font-weight: $boldFontWeight;
    color: $primary100Base;
    margin-top: 24px;
  }
}

.progressBar {
  width: 100%;
  background-color: $secondary3;
  border-radius: 2px;

  & div {
    background-color: $secondary100Base;
  }
}

.downloadLinkBtn{
  border: 1px solid $secondary100Base;
  border-radius: 4px;
  margin-top: 24px;
  line-height: $pLineHeight;
  font-size: $pFontSize;
  color: $secondary100Base;
  font-weight: $boldFontWeight;
  cursor: pointer;
  padding: 9px 19px;

  > svg {
    margin-right: 6px;
  }

  &:hover {
    background-color: $secondary10;
    border-color: $secondary120;
  }
}

.downloadButton {
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  color: $secondary100Base;
}

.downloadImage {
  height: 24px;
  width: 24px;
  stroke: $secondary100Base;
  margin-right: 6px;
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.filePreview {
  display: block !important;
  background-color: $secondary3;
  border: none;
  height: 100%;
  width: 100%;
}
