@import 'styles/variables';

.deleteAction {
  position: absolute;
  right: 0;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $defaultErrorColor;
  font-feature-settings: 'tnum' on, 'lnum' on;
  cursor: pointer;
}

.addOptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > button {
    flex-shrink: 0;
  }
}

.dashes {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed $borderAndSeparatorsColor;
}

.option {
  position: relative;
  margin-bottom: 24px;
}
