@import 'styles/variables';

.container {
  position: relative;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 55px;

  & > div,
  & > button {
    flex: 0 0 auto;
  }
}

.tabsList {
  flex-shrink: 0;
}

.actionsContainer {
  background-color: $surfacesPrimary;
  box-shadow: 0 8px 24px rgba(26, 35, 56, 0.2);
  border-radius: $borderRadiusDefault;
  width: 240px;
  outline: 0;
  height: auto;
  cursor: default;
}

.addTabButton {
  height: 24px;
  border-radius: 4px;

  &:hover {
    background-color: $secondary10;
  }
}

.tabListItem {
  width: auto;
  margin-right: 16px;
}

.disabledAddTabButton {
  pointer-events: none;
}

.border {
  position: absolute;
  top: calc(100% - 1px);
  width: calc(100% - 110px);
  height: 1px;
  background-color: $borderAndSeparatorsColor;
  z-index: -1;
}
