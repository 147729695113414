@import 'styles/variables';

.applyFilter {
  width: 100%;
  margin-top: 36px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $pLineHeight;
  padding-bottom: 12px;
  color: $primary100Base;
}

.labelListOptionPrefix {
  align-self: center;
  margin-right: 7px;
}

.labelSelectContainer {
  padding-top: 0;
}
