@import 'styles/variables';

.intermediaryName {
  font-weight: $boldFontWeight;
  color: $headerTextColor;
}

.avatar {
  margin-right: 8px;
}

.tableRowWrapper:hover {
  .tableRow {
    cursor: pointer;
    background-color: $secondary6;
  }
}
