@import 'styles/variables';

.headerContainer {
  padding: 24px 30px;
  border-radius: 6px;
  background-color: $surfacesLight;
  overflow: hidden;
  transition: 0.25s max-height ease-in-out;
}

.headerContainer:not(.headerContainerExpanded) {
  max-height: 72px;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
}

.headerTitleDropdownIcon {
  cursor: pointer;
  transition: 0.5s;
}

.headerTitleDropdownIconActive {
  transform: rotate(180deg);
  transition: 0.5s;
}

.emailInfoItem {
  padding: 14px 0;
  border-top: 1px solid $borderAndSeparatorsColor;
  display: flex;
  align-items: flex-start;
}

.emailInfoItemCollapsed {
  border-top: 0;
}

.emailInfoItemTitle {
  flex-shrink: 0;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $pLineHeight;
  color: $subtextColor;
  width: 28px;
}

.emailInfoItemValue {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin-left: 16px;
}

.emailInfoLastItem {
  padding-bottom: 0;
}

.senderInfo {
  display: flex;
  align-items: center;
  max-width: 580px;
}

.senderName {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  color: $headerTextColor;
}

.senderEmail {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sentDate {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $subtextColor;
  text-align: end;
  margin-right: 12px;
  flex-grow: 1;
}
