@import 'styles/variables';

.snackContainer {
  background-color: $surfacesPrimary;
  box-shadow: 0px 8px 24px rgba(15, 35, 66, 0.2);
  border-radius: $borderRadiusDefault;
}

.snackHeader {
  display: flex;
  padding: 18px;
  justify-content: space-between;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.snackBody {
  padding: 18px;
}

.closeButton {
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  background-color: $surfacesPrimary;
  cursor: pointer;

  & > svg {
    stroke: $primary80;
    width: 24px;
    height: 24px;
  }
}
