@import 'styles/variables';

.container {
  width: 100%;
  padding: 0 55px 48px;
}

.containerHasTopMargin {
  margin-top: 20px;
}
