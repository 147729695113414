@import 'styles/variables';

.line {
  .checkBoxRoot {
    margin-top: -50%;
    margin-bottom: -50%;
    padding-left: 0;
  }
}

.checkBox {
  padding: 0 16px 0 0;
}

.checkboxInLoading {
  align-items: flex-start;
  padding: 11px 0;
}

.doneTask {
  text-decoration-line: line-through;
  color: $primary40;
}

.teamMembersCell {
  text-decoration-line: none;
}

.checkBoxCell,
.checkBoxCell:hover {
  overflow: hidden;
}

.actionsButton {
  padding-right: 10px;
}
