@import 'styles/variables';
@import '../DndList/DndList.module';

.withBordersRow {
  @extend .draggableItem;
  border: 1px solid transparent;
  margin-top: -1px;
  border-radius: 4px;
  position: relative;

  &:before {
    border-top: 1px solid $borderAndSeparatorsColor;
    content: '';
    position: absolute;
  }

  &:hover {
    margin-top: -1px;
    border-color: $borderAndSeparatorsColor;

    &:before {
      border-top: none;
    }
  }

  &__dragging > div {
    background-color: white;
  }
}
