@import 'styles/variables';

$helpContainerPadding: 46px 55px;
$helpContainerLinkWidth: 48px;
$helpContainerLinkHeight: 48px;
$helpContainerLinkBorderRadius: 50%;
$helpContainerLinkMargin: 0 0 0 12px;

.helpContainer {
  background-color: $secondary100Base;
  display: flex;
  align-items: center;
  padding: $helpContainerPadding;
  width: 100%;

  & p {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h3FontSize;
    line-height: $h3LineHeight;
    color: $white100Base;
  }

  & a {
    color: $secondary100Base;
    width: $helpContainerLinkWidth;
    height: $helpContainerLinkHeight;
    background-color: $white100Base;
    border-radius: $helpContainerLinkBorderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $helpContainerLinkMargin;
  }
}
