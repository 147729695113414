.container {
  position: relative;
}

.borrowerTypeButtonToggleGroup {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: calc(100% + 16px);
}
