@import 'styles/variables';

.sendESignButton {
  margin: 36px 0 48px 0;
}

.bottomError {
  padding: 24px 55px;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  width: 100%;
  background-color: $red10;
  color: $defaultErrorColor;
}

.innerContainer {
  padding: 0 55px;
}

.popupContent {
  margin-top: 36px;
  padding: 0;
}
