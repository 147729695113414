@import 'styles/variables';

.cardTableView {
  display: grid;
  gap: 30px;
}

.field {
  display: flex;
  border-top: 1px solid $borderAndSeparatorsColor;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  min-height: 44px;
  max-height: 45px;
  transition: max-height 0.2s ease-in-out;

  &:last-child {
    border-bottom: 1px solid $borderAndSeparatorsColor;
  }

  &:hover {
    max-height: unset;
  }
}

.hiddenField {
  min-height: 0;
  max-height: 0;
  border: 0;
}

.fieldColumn {
  width: 50%;
  padding: 14px 10px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
}

.fieldTitle {
  @extend .fieldColumn;

  color: $primary40;
}

.fieldValue {
  @extend .fieldColumn;

  color: $primary80;
}
