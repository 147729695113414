@import 'styles/variables';
@import 'styles/mixins';

.input {
  width: 100%;
}

.inputContainer {
  width: 100%;
}

.buttonContainer {
  margin-top: 36px;
}

.inputsTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $headerTextColor;
  margin-top: 36px;
}

.inputsContainer {
  padding-top: 36px;
  width: 100%;
}
