@import 'styles/variables';

.infoPanelTitleInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.infoPanelTitle {
  position: relative;
  display: flex;
  align-items: center;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.infoPanelTitleName {
  color: $primary100Base;
  height: 24px;
}

.infoPanelTitleNumber {
  color: $orange100Base;
  padding-left: 8px;
}

.infoPanelTitleDropdownIcon {
  cursor: pointer;
  transition: 0.5s;
}

.infoPanelTitleDropdownIconActive {
  transform: rotate(180deg);
  transition: 0.5s;
}

.editLinkContainer {
  position: absolute;
  display: flex;
  align-items: center;
  width: max-content;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 16px);
  height: 36px;
}
