@import 'styles/variables';

.container {
  padding-top: 0;
}

.selectContainer {
  & > label {
    padding-left: 20px;

    & > input {
      min-height: 24px;
      margin: 0 0 4px 0;
      padding-left: 0;
    }
  }
}

.option {
  height: 24px;
  padding: 7px 6px 7px 10px;
  margin-left: 0;
  margin-right: 4px;

  & > p {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $tagFontSize;
    line-height: $tagLineHeight;
    letter-spacing: $defaultLetterSpacing;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    margin-right: 0;
  }

  & > svg {
    width: 19px;
    height: 19px;
  }

  &:last-child {
    margin-right: 20px;
  }
}
