@import 'styles/variables';

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px 0;
  &:before {
    content: '';
    border-top: 1px dashed $primary10;
    width: 100%;
  }
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $tableHeadFontSize;
  line-height: $tableHeadLineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
  position: absolute;
  z-index: 4;
  background-color: $white100Base;
  padding: 0 4px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
