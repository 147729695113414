@import 'styles/variables';

.container {
  position: relative;
  width: 980px;
  height: 100%;
  padding: 36px 55px 60px 55px;
}

.closeImage {
  width: 36px;
  height: 36px;
  cursor: pointer;
  position: absolute;
  top: 36px;
  right: 36px;
  stroke: $primary80;
}
