@import 'styles/variables';

.addModuleButtonContainer {
  margin-top: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__bloked {
    opacity: 0.4;
  }
}

.addModuleButton {
  padding: 0;
  border: 0;
  background-color: $primary80;
  border-radius: 6px;
  stroke: $surfacesPrimary;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s ease-in-out;
  outline: 0;

  &__active {
    background-color: $secondary100Base;
  }

  &:hover {
    background-color: $secondary100Base;
  }

  &:disabled {
    cursor: default;
  }
}

.inputLoader {
  width: 16px;
  height: 16px;
}

.addModuleLoading {
  background-color: $surfacesPrimary;
}

.addModuleIsBlocking {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
