@import 'styles/variables';

.icon {
  cursor: pointer;

  &::after {
    width: 100%;
    height: 100%;
    content: '?';
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 12px;
    line-height: 16px;
    color: $primary100Base;
    border-radius: 50%;
    border: 1px solid $primary20;
    font-weight: 500;
  }

  &:hover {
    &::after {
      color: $secondary100Base;
      border: 1px solid $secondary40;
    }
  }
}
