.intermediariesTableInner {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.intermediariesTable {
  width: 100%;
  padding: 0 55px 50px 55px;
}
