.buttonWrapper {
  display: flex;
  width: fit-content;
}

.button {
  &:disabled {
    pointer-events: none;
  }
}
