@import 'styles/variables';

.popupCore {
  section {
    width: 100%;
  }
}

.saveButton {
  margin-top: 36px;
}

.declineReasonInput {
  padding-top: 0;
}
