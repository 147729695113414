$variablesPaneZIndex: 100;
$createVariablePopupZIndex: $variablesPaneZIndex + 1;

.rightSidePopup {
  z-index: $variablesPaneZIndex;
  overflow-y: visible;
  height: 100vh;
}

.hiddenRightSidePopup {
  right: -334px;
}

.createVariablePopup {
  z-index: $createVariablePopupZIndex;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
