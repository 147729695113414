@import 'styles/variables';

.container {
  border: 1px solid $primary10;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px 0 14px;
  width: 100%;
  height: 328px;
  display: flex;
  flex-direction: column;

  .divider {
    display: inline-block;
    width: 100%;
    border-top: 1px solid $primary6;
    margin: 14px 0 12px;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
}

.body {
  height: 231px;
  padding: 0 24px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  .line {
    display: flex;
    align-items: center;
    margin-top: 8px;
    width: 100%;
  }

  .divider {
    margin: 12px 0 7px;
  }
}

.footer {
  display: flex;
  padding: 0 24px;
  justify-content: flex-start;
}
