@import 'styles/colorPalette';

.subTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.subTitlePhone {
  color: $primary100Base;
  font-weight: bold;
}
