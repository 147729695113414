@import 'styles/variables';

.comparisonOperandContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.typeHandlerContainer {
  width: 170px;
  min-width: 170px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.subtitleContainer {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin-top: 4px;
}

.linkContainer {
  position: absolute;
  bottom: 60px;
}

.descriptionContainer {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin-top: 4px;
}
