@import 'styles/mixins';
@import 'styles/variables';

$titleZIndex: 3;

.title {
  display: flex;
  justify-content: space-between;
  padding: 14px 30px 8px 30px;
}

.stickyTitle {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: $titleZIndex;

  &.stuck {
    box-shadow: 0 8px 24px rgba(15, 35, 66, 0.2);
    margin: 0 -25px;
    padding: 16px 55px;
  }
}

h2 {
  margin-right: 12px;
  @include headerTitle;
}

.titleName {
  margin-right: 12px;
}

.blankTitleName {
  color: $primary40;
}

.titleContent {
  display: flex;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: $orange100Base;
}

.statusDropdown {
  margin-left: auto;
  margin-right: 0;
}

.moreButton {
  border-radius: 4px;
  border: 1px solid $primary10;
  margin-left: 12px;

  > button {
    width: 42px;
    height: 42px;

    & circle {
      fill: $primary100Base;
    }
  }
}

.approvalAnimation {
  z-index: $titleZIndex + 1;
}
