.table {
  width: 100%;

  .totalColumnHeadCell {
    justify-content: flex-end;
  }
}

.tableHeadCell {
  padding: 12px 0;
  height: 36px;
}
