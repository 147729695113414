@import 'styles/variables';
@import 'styles/colorPalette';

.nameCell {
  display: flex;
  align-items: center;
  font-weight: $boldFontWeight;
  color: $primary100Base;

  & > svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}

.updatedCell {
  display: flex;

  & > * {
    margin-right: 12px;
  }
}
