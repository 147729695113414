.comparisonContainer {
  padding-top: 24px;
}

.comparisons {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  flex-wrap: wrap;
  padding-right: 72px;
  max-height: 120px;
}

.comparisonContainerTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0f2342;
}

.tooltip {
  text-align: center;
}
