@import 'styles/variables';

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  color: $headerTextColor;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  &__search {
    width: 100%;
  }

  &__memberFilter {
    display: flex;
    margin-right: auto;
    margin-left: 30px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
}

.connectEmailsButton {
  width: 160px;
}

.sendEmailButton {
  margin-left: 12px;
  width: 140px;
}
