@import 'styles/variables';

.statusRule {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0 20px;
  cursor: pointer;

  &:not(:last-child) {
    .andSeparator {
      display: block;
    }
  }

  &:first-child {
    padding-top: 0;

    .statusRuleContent {
      padding-top: 16px;
      border-top: 1px solid $borderAndSeparatorsColor;
    }
  }

  &:hover {
    background-color: $secondary6;
  }
}

.statusRuleContent {
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.statusRuleLine {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .lineTitle {
    min-width: 123px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }

  .lineValue {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }
}

.andSeparator {
  position: absolute;
  left: 6px;
  top: calc(100% - 6px);
  display: none;
  z-index: 1;
}

.rowActions {
  position: absolute;
  right: 0;
  top: 13px;
}
