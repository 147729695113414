@import 'styles/variables';
@import 'styles/colorPalette';

.editLink {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.editImage {
  margin-right: 6px;
}

.editLinkContainer {
  a {
    color: $secondary100Base;

    &:hover {
      color: $secondary100Base;
    }
  }

  border-radius: 6px;
  font-weight: $normalFontWeight;
}

.editLinkContainer:hover {
  background: $white100Base;
}
