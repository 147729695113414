@import 'styles/variables';

.tag {
  min-height: 16px;
  width: auto;
  border-radius: 2px;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: 0.06em;
  font-weight: $boldFontWeight;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
}

.green {
  background-color: $green10;
  color: $green100Base;
}

.gray {
  background-color: $surfacesLight;
  color: $primary80;
}

.red {
  background-color: $red10;
  color: $red100Base;
}

.blue {
  background-color: $secondary10;
  color: $secondary100Base;
}

.yellow {
  background-color: $lightOrange10;
  color: $lightOrange100Base;
}

.white {
  background-color: $white100Base;
  color: $primary40;
}

.mediumSize {
  padding: 0 8px;
  line-height: 24px;
}

.tagWithCloseButton {
  padding-right: 0;
}
