@import 'styles/variables';

.container {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
}

.statusCard {
  margin-right: 30px;
}

.applicationStatusesList {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
}

.addStatusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 330px;
  padding: 16px 80px;
  background-color: $white100Base;
}

.statusesListContent {
  display: flex;
  align-items: flex-start;
  padding: 0 55px 24px;
}

.defaultDisabledStatus {
  margin-right: 30px;
}
