@import 'styles/variables';
@import 'styles/mixins';

.linkButton {
  @include buttonReset;
  color: $defaultLinkColor;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-family: $fontFamilyMonsterrat;
  cursor: pointer;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}
