@import 'styles/variables';

.bodyContainer {
  position: relative;
  height: 100%;
}

.subheader {
  padding: 0 55px;
  min-height: 44px;
}

.tabsSwitch {
  margin-top: 24px;
  padding: 0 55px;
}

.tab {
  & label {
    margin-right: 36px;
  }
}
