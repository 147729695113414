@import 'styles/variables';

$navLinkContactMarginLeft: 3px;

.linkContainer {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  color: $defaultLinkColor;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  margin-left: $navLinkContactMarginLeft;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: $defaultLinkHoverColor;
  }
}

.text {
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  color: $primary80;
}
