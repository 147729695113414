@import 'styles/variables';

.tooltip {
  position: fixed;
  background-color: $primary100Base;
  border-radius: 4px;
  padding: 12px 16px;
  box-shadow: 0px 8px 24px rgba(15, 35, 66, 0.2);
  z-index: 999;

  .xData {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: $white100Base;
    white-space: nowrap;
  }

  .yData {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primary40;
    white-space: nowrap;
  }
}
