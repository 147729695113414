@import 'styles/variables';

.header {
  margin-bottom: 12px;
}

.actions {
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  .userFilter {
    margin-left: 24px;
  }

  .sortBySelect {
    display: flex;
    align-items: center;
  }

  .typeFilterButton {
    display: flex;
    margin-top: 0;
    margin-right: 12px;

    & > div {
      margin-top: 0;
    }

    & label {
      margin-top: 0;
      height: 44px;
    }
  }
}

.leftBlock {
  display: flex;
}

.rightBlock {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}
