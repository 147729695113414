@import 'styles/variables';

.editLabelsContainer {
  display: flex;
  flex-direction: row;
}

.labels {
  display: flex;
  flex-direction: row;
}

.labelTag {
  position: relative;
  margin-right: 8px;
  cursor: pointer;
  padding-left: 10px;
}

.productLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 10px 16px 10px 20px;

  &:disabled {
    cursor: default;
    background-color: $white100Base;
  }
}

.actionsContainer {
  background-color: $surfacesPrimary;
  box-shadow: 0 8px 24px rgba(26, 35, 56, 0.2);
  border-radius: $borderRadiusDefault;
  width: 240px;
  outline: 0;
  height: auto;
  cursor: default;
}

.addLabelsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: $surfacesLight;
  border-radius: 2px;
  cursor: pointer;

  & > svg {
    stroke: #3f4f68;
  }

  &:not(.disabledAddLabelsButton):hover {
    background-color: #e8f4fe;

    & > svg {
      stroke: #1e95f8;
    }
  }
}

.activeAddLabelsButton {
  background-color: #e8f4fe;

  & > svg {
    stroke: #1e95f8;
  }
}

.tag {
  padding: 7px 10px;
}
