@import 'styles/variables';

.card {
  display: flex;
  flex-direction: column;
  min-width: 330px;
  padding: 10px 20px;
  background-color: $white100Base;

  .statusIndex {
    margin-bottom: 8px;
  }

  .statusOptions {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  .statusOption {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 24px;
    }
  }

  .applicationCards {
    margin: 0 -10px;

    & > div {
      background-color: $surfacesLight;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .skeletonAvatarList {
    & > div {
      border: 2px solid $surfacesLight;
    }
  }
}
