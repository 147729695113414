@import 'styles/mixins';
@import 'styles/variables';

.container {
  margin: 0 0 24px;
}

.labelText {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;

  &--inactive {
    color: $subtextColor;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .subTitle__search {
    border-bottom: 1px solid $primary10;
  }
}

.memberFilterContainer {
  display: flex;
  margin-right: auto;
  margin-left: 24px;
}
