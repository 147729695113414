@import 'styles/variables';

.pageContainer {
  min-height: 100%;
  width: 100%;
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageHeaderTitleContainer {
  display: flex;
}

.pageTitle {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.tabSwitch {
  margin: 24px 0 36px 0;
}

.formButton {
  margin-top: 36px;
}

.importButton {
  min-width: 172px;
}

.noCredentialsButton {
  margin-top: 36px;
}

.title {
  position: relative;
  min-height: 36px;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  color: $headerTextColor;
  display: flex;
  align-items: center;
}

.inputKey {
  width: 510px;
  padding-top: 16px;
}

.closeEditorButton {
  position: absolute;
  min-width: 140px;
  right: 0;
  padding: 12px 25.5px;
}

.editCredentialsButton {
  margin-left: 12px;
  border-radius: 6px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: $defaultLinkColor;
    background-color: $secondary10;
  }
}

.templatesHeader {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  padding-top: 8px;
  color: #000000;
}

.noItemsContainer {
  margin-top: 60px;
}

.noItemsTitle {
  margin-top: 36px;
}

.noItemsButton {
  min-width: 180px;
  height: 52px;
  margin-top: 24px;
  padding: 16px 24px;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $secondary100Base;
  border: 1px solid $secondary100Base;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    background-color: $secondary10;
  }
}
