@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.results {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 21px;
}
