@import 'styles/variables';

.container {
  padding-top: 12px;
}

.subTitleRow {
  margin-top: 24px;
}

.resendCodeTimeout {
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.resendCodeTimeoutSeconds {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: inline;
  color: $primary100Base;
}
