@import 'styles/variables';

.displayName {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}

.strategyName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__inactive {
    color: $subtextColor;
  }
}

.versionsCount {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
}

.versionsContainer {
  display: inline-flex;
  position: relative;
  padding: 4px 20px 4px 0;
  justify-content: flex-start;
  width: 100%;

  .versionActions {
    top: 8px;
    right: 0;
  }

  &:hover {
    .strategyActionsBtn {
      opacity: 1;
      background-color: transparent;
    }
  }
}

.versionCell {
  padding: 0 $tableBodyCellHorizontalPadding;
  width: 33%;
  align-items: flex-start;

  &:hover {
    white-space: unset;
    overflow: hidden;
  }
}

.row {
  height: 77px;
}

.infoCell {
  padding: 15px $tableBodyCellHorizontalPadding;
  width: 33%;
}

.rowActionsContainer {
  position: absolute;
  right: 0;
  top: 12px;
}

.actionsContainer {
  position: absolute;
  top: 10px;
  right: 0;
  justify-content: unset;
  align-content: flex-end;
}
