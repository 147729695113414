@import 'styles/variables';

.fields {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}

.fieldContainer{
  padding-top: 0;
  margin-top: 0;
}

.buttonsContainer {
  display: flex;
  margin-top: 36px;
  width: 100%;
}

.closeEditorButton {
  margin-left: 12px;
}