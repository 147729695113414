@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.questionTooltip {
  margin-left: 8px;
}

.displayHeaderPreviewButton {
  min-width: 200px;
  margin-left: 12px;
  padding: 6px 8px;
  border-radius: 6px;

  &:not(:disabled):hover {
    background-color: $secondary10;
    color: $secondary100Base;
  }

  & > svg {
    margin-right: 4px;
    transition: transform 0.4s ease-in-out;
    transform: rotate(180deg);
  }
}

.activatedHeaderPreviewButton {
  & > svg {
    transform: rotate(0);
  }
}

.subheader {
  padding: 0 55px;
}
