@import 'styles/variables';

.editUser {
  width: 710px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;

  &__header {
    padding: 36px 36px 12px 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > h2 {
      color: $headerTextColor;
      font-size: $h2FontSize;
      line-height: $h2LineHeight;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-family: $fontFamilyMonsterrat;
    }

    & > button {
      border: none;
      background-color: transparent;
      height: 36px;
      width: 36px;
      padding: 0;
      cursor: pointer;
      outline: 0;

      & svg {
        width: 100%;
        height: auto;

        & path {
          stroke: $primary80;
        }
      }
    }
  }

  &__content {
    padding: 0 55px;
  }

  &__inputs {
    border-bottom: 1px solid rgba(15, 35, 66, 0.1);
    padding-bottom: 36px;
  }

  &__section {
    padding: 24px 0 36px 0;
    border-bottom: 1px solid rgba(15, 35, 66, 0.1);

    &Content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 12px;

      & > h5 {
        font-family: $fontFamilyMonsterrat;
        font-style: normal;
        font-weight: 400;
        font-size: $h5FontSize;
        line-height: $h5LineHeight;
        color: $headerTextColor;
      }
    }
  }

  &__sectionList {
    margin-bottom: 36px;
  }

  &__activeButton {
    width: 187px;
  }
}

.editPermissionGroupsButton {
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.notFoundUserHeader {
  @extend .editUser__header;
  display: flex;
  justify-content: flex-end;
}

.resendInvite {
  margin-right: 12px;
  margin-left: auto;
}
