@import 'styles/variables';

.profileDescriptionTooltip {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
}

.goToBorrowerProfileButton {
  display: flex;
  flex-direction: row-reverse;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  padding: 0;

  & > svg {
    margin-left: 4px;
  }
}
