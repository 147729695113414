@import 'styles/variables';

.lockBorrowersSwitchContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > label {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;

    & > p {
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $normalFontWeight;
      font-size: $pFontSize;
      line-height: $pLineHeight;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: $headerTextColor;
    }
  }
}

.lockBorrowersSwitch {
  margin: 0 12px 0 0 !important;
}

.roundRobinRolesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > label {
    margin-bottom: 9px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }

  .checkboxItem {
    padding-left: 10px;
    padding-right: 20px;
  }

  .checkboxLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary80;
  }

  .roundRobinMultiSelectList {
    & > label {
      margin: 0;
      padding: 14px 0;
      border-top: 1px solid $borderAndSeparatorsColor;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid $borderAndSeparatorsColor;
      }

      &:hover {
        &:hover {
          background-color: $secondary3;
        }
      }
    }
  }

  .roundRobinAvatar {
    margin-right: 8px;
  }
}

.saveChangesButton {
  min-width: 180px;
  margin-top: 36px;
}
