@import 'styles/variables';

.container {
  padding: 36px 25px 48px 25px;
  width: 980px;
  height: 100%;
}

.header,
.description {
  margin: 0 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 11px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  align-self: center;
  margin-bottom: 18px;
  background-color: none;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.closeImage {
  cursor: pointer;
  stroke: $primary80;
}

.focusTextArea {
  width: 733px;
  min-height: $pLineHeight;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  border: 0;
  outline: none;
  resize: none;
  padding: 0;
  opacity: 1;
  transition: 0.4s height ease;
  overflow-y: hidden;
  &::placeholder {
    color: $primary40;
  }
}

.content {
  position: relative;
  padding-bottom: 48px;
}

.arrowImageClosed {
  position: absolute;
  top: 15px;
  left: -20px;
  stroke: $secondary100Base;
  cursor: pointer;
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

.arrowImageOpened {
  @extend .arrowImageClosed;
  transform: rotate(0);
}

.branchInputContainer {
  > input {
    font-weight: $boldFontWeight;
    align-self: center;
    margin-bottom: 36px;
  }

  > p,
  > input {
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    max-width: 650px;
  }
}

.skeletonName {
  margin-bottom: 36px;
}
