@import 'styles/variables';

.subheader {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > h2 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }
}

.questionTooltip {
  margin-left: 8px;
}
