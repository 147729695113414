@import 'styles/variables';

.container {
  width: 100%;
  padding: 0 55px 48px;
}

.description {
  margin-top: 24px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  margin-bottom: 4px;
}

.checkboxes {
  margin-top: 4px;
}

.checkboxLine {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;

  & svg {
    min-width: 20px;
    cursor: pointer;
  }
}

.text {
  margin-left: 12px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.buttons {
  margin-top: 36px;
  display: flex;
  & button {
    margin-right: 12px;
  }
}
