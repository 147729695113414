@import 'styles/variables';

$leftSidePaneWidth: 280px;

.pageContent {
  display: flex;
  flex-direction: column;
}

.leftSidePane {
  width: $leftSidePaneWidth;
}

.contentContainer {
  left: $leftSidePaneWidth;
}
