div.noStrategyItems {
  margin-top: 80px;

  .noResultsMessage {
    margin-top: 0;
  }

  .clearFiltersButton {
    margin-top: 24px;
  }

  .noFilterResultsImg {
    margin-left: -16px;
  }
}
