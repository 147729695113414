@import 'styles/variables';
@import 'styles/colorPalette';

.nameCell {
  display: flex;
  align-items: center;
  gap: 4px;
}

.productCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  padding: 8px 10px;
}

.nameCell,
.productCell {
  &:first-child {
    font-weight: $boldFontWeight;
    color: $primary100Base;
  }
}

.productLine {
  width: 100%;

  div + & {
    flex-grow: 1;
  }
}

.loanAmountCell {
  justify-content: flex-end;
  font-weight: $boldFontWeight;
  color: $primary100Base;
}

.duplicateIcon {
  width: 24px;
  height: 24px;
  padding: 2px;
  flex-shrink: 0;
  stroke: $primary80;
}
