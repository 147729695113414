@import 'styles/colorPalette';
@import 'styles/variables';
@import '../common.module.scss';

.header {
  width: $columnWidth;
  background-color: $surfacesLight;
  padding: $paddingSize $paddingSize 16px $paddingSize;
  border-top-left-radius: $borderRadiusDefault;
  border-top-right-radius: $borderRadiusDefault;
}
