@import 'styles/variables';

.permissionGroupsPage {
  width: 100%;
  height: 100%;
}

.permissionGroupsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;

  &__title {
    display: flex;
    align-items: center;

    & h2 {
      color: $headerTextColor;
      font-size: $h2FontSize;
      line-height: $h2LineHeight;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-family: $fontFamilyMonsterrat;
    }
  }
}

.tooltip {
  margin-left: 12px;
}
