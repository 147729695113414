@import 'styles/mixins';

.headerTitle {
  @include headerTitle;

  margin-bottom: 24px;
}

.filter {
  margin-top: 24px;
}
