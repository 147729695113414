@import 'styles/variables';
@import 'components/LeftNav/LeftNavigation.module.scss';

.pageContent {
  min-width: 1160px;
  max-width: 2200px - $leftNavigationWidthCollapsed;
  flex-grow: 1;
  font-family: $fontFamilyMonsterrat;
}

.pagePaddings {
  padding: 36px 55px 60px 55px;
}
