@import 'styles/variables';

$iconWrapperTransition: 0.8s;
$iconTransition: 0.4s;

@mixin enter($borderColor) {
  opacity: 0;
  border: 0 solid $borderColor;

  & > svg {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}

@mixin enterActive($borderColor, $borderWidth) {
  opacity: 1;
  border: $borderWidth solid $borderColor;
  transition: all $iconWrapperTransition ease-in-out;

  & > svg {
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: all $iconTransition ease-in-out;
    transition-delay: $iconWrapperTransition;
  }
}

@mixin exit($borderColor, $borderWidth) {
  opacity: 1;
  border: $borderWidth solid $borderColor;

  & > svg {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@mixin exitActive($borderColor) {
  opacity: 0;
  border: 0 solid $borderColor;
  transition: all $iconWrapperTransition ease-in-out;
}

.loader {
  position: relative;
  width: 24px;
  height: 24px;
}

.spinner {
  position: absolute;
}

.spinnerEnter {
  opacity: 0;
}

.spinnerEnterActive {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.spinnerAppear {
  @extend .spinnerEnter;
}

.spinnerAppearActive {
  @extend .spinnerEnterActive;
}

.spinnerExit {
  opacity: 1;
}

.spinnerExitActive {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.iconWrapper {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;

    & path {
      stroke: white;
    }
  }
}

.successEnter {
  @include enter(#00c4ac);
}

.successEnterActive {
  @include enterActive(#00c4ac, 12px);
}

.successEnterDone {
  @include enterActive(#00c4ac, 12px);
}

.successAppear {
  @extend .successEnter;
}

.successAppearActive {
  @extend .successEnterActive;
}

.successExit {
  @include exit(#00c4ac, 12px);
}

.successExitActive {
  @include exitActive(#00c4ac);
}

.failureEnter {
  @include enter(#f76565);
}

.failureEnterActive {
  @include enterActive(#f76565, 12px);
}

.failureEnterDone {
  @include enterActive(#f76565, 12px);
}

.failureAppear {
  @extend .failureEnter;
}

.failureAppearActive {
  @extend .failureEnterActive;
}

.failureExit {
  @include exit(#f76565, 12px);
}

.failureExitActive {
  @include exitActive(#f76565);
}
