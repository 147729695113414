@import 'styles/variables';

.tableBodyCell {
  align-items: flex-start;
}

.code {
  width: 100%;
}

.tableRow {
  overflow: visible;

  &:not(:last-child) {
    .andSeparator {
      display: block;
    }
  }
}

.andSeparator {
  position: absolute;
  left: 6px;
  top: calc(100% - 6px);
  display: none;
  z-index: 1;
}
