@import 'styles/variables';

.tabMenuContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.tabMenuButton {
  display: flex;
  align-items: center;
  color: $primary80;
  padding: 10px 24px;
  border: 1px solid $primary10;
  box-sizing: border-box;
  font-style: normal;
  font-weight: $semiboldFontWeight;
  font-size: $pFontSize;
  line-height: $h5LineHeight;
  cursor: pointer;
  transition: 0.25s color ease-in-out;

  &:not(:last-child) {
    border-right: 0;
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  &.selected {
    color: $secondary100Base;
    background-color: $secondary10;
    border: 1px solid $secondary100Base;
  }

  &:hover {
    color: $secondary100Base;
    transition: 0.25s color ease-in-out;
  }
}
