@import 'styles/variables';

.paginationContainer {
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-weight: $normalFontWeight;
  color: $subtextColor;
  font-style: normal;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &__pages {
    display: flex;
    align-items: center;
  }

  &__buttons {
    display: flex;
    align-items: center;

    & button {
      border: 0;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      outline: 0;
      cursor: pointer;

      &:first-child {
        margin-left: 24px;
      }

      &:nth-child(2) {
        margin-left: 12px;
      }

      & svg {
        & > path {
          stroke: $secondary100Base;
        }
      }

      &.disabledButton {
        cursor: default;
        & > svg {
          & > path {
            stroke: $primary40;
          }
        }
      }
    }
  }
}

.pageValue {
  height: 36px;
  width: 64px;
  margin: 0 12px 0 12px;
  padding: 0;
}

.inputContainer {
  input {
    margin: 0;
    padding: 8px 12px;
    height: auto;
  }
}

.pagesDescriptionContainer {
  display: flex;
  align-items: center;
}
