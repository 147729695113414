.input {
  max-height: 124px;
  overflow: hidden;
  width: 100%;
  transition: max-height 0.3s ease-in-out;
}

.hiddenInput {
  max-height: 0;
}
