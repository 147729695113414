@import 'styles/variables';
@import 'components/Button/ButtonVariables';

.filterButton {
  justify-content: center;
  padding: 10px 0;
  min-width: 44px;
  width: 44px;
}

.active {
  background-color: $secondary100Base;
  border: 1px solid $secondary100Base;

  & svg {
    stroke: $contrastSurfacesTextColor;
  }

  &:hover {
    background-color: $secondary120;
    border: 1px solid $secondary120;

    & svg {
      stroke: $contrastSurfacesTextColor;
    }
  }
}

.disabled {
  color: $primaryBtnTextColorDisabled;
  background-color: $primaryBtnBackgroundColorDisabled;
  border: $primaryBtnBorderDisabled;
}
