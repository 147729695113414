@import 'styles/variables';

.changeImageLabel {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultLinkColor;
  cursor: pointer;
  &:hover {
    color: $defaultLinkHoverColor;
  }
}

.changeImageInput {
  display: none;
}
