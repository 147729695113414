@import 'styles/variables';

.container {
  padding: 36px 36px $bottomPaddingDefault 55px;
  width: 890px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
  margin-top: 24px;
  padding-right: 19px;
}

.closeImage {
  cursor: pointer;
  stroke: $primary80;
}

.focusTextArea {
  width: 733px;
  min-height: $pLineHeight;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  border: 0;
  outline: none;
  resize: none;
  padding: 0;
  opacity: 1;
  transition: 0.4s height ease;
  overflow-y: hidden;
  &::placeholder {
    color: $primary40;
  }
}

.content {
  margin-top: 8px;
  padding-right: 19px;
  width: 100%;
  &:first-child {
    border: 1px solid $borderAndSeparatorsColor;
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }

  &:last-child {
    border: 1px solid $borderAndSeparatorsColor;
    border-radius: 0 0 4px 4px;
    border-top: none;
  }
}

.buttonContainer {
  margin-top: 36px;
}

.inputContainer {
  padding-right: 19px;
}
