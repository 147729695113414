@import 'styles/variables';

.breadcrumbsContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.breadcrumb {
  color: $subtextColor;

  &:hover {
    color: $defaultLinkColor;
    cursor: pointer;
  }

  &:first-child {
    white-space: nowrap;
  }

  &:last-child {
    color: $defaultTextColor;
  }

  &Short {
    flex-shrink: 0;
  }

  &Long {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.arrow {
  margin-left: 2px;
  transform: rotate(90deg);
  flex-shrink: 0;

  > path {
    stroke: $subtextColor;
  }
}

.actionsContainer {
  min-width: unset;
}

.actionsButton {
  opacity: 1;
  transform: rotate(90deg);

  &:hover {
    background-color: transparent;

    > svg > circle {
      fill: $defaultLinkColor;
    }
  }

  > svg > circle {
    fill: $subtextColor;
  }
}
