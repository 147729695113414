@import 'styles/variables';
@import './PercentageInput.module';

.percentageImageContainer {
  @extend .dataTypeContainer;

  position: static;
  margin: 0 20px 0 -20px;
  background-color: $surfacesLight;
}
