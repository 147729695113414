@import 'styles/variables';
@import 'styles/mixins';

$userCardContainerHeight: 60px;
$imgHeight: 32px;
$imgWidth: 32px;
$userInfoContainerWidth: 200px;
$credentialsFontColor: #0f2342;
$imgContainerMarginRight: 12px;
$userInitilsColor: #ffffff;
$userImgBackground: linear-gradient(135deg, #00c4ac 0%, #1e95f8 100%);

.userCardContainer {
  &:not(.noMargin) {
    margin-top: 20px;
  }

  display: flex;
  height: $userCardContainerHeight;
}

.imgContainer {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: $imgContainerMarginRight;
}

.userImg {
  height: $imgHeight;
  width: $imgWidth;
  border-radius: 100%;
  background: $userImgBackground;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userInitials {
  color: $userInitilsColor;
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: $userInfoContainerWidth;
  overflow: ellipsis;
  font-family: $fontFamilyMonsterrat;
  color: $credentialsFontColor;
  font-weight: $boldFontWeight;
}

.userName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.link {
  @include buttonReset;
}

.linkText {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $defaultLinkColor;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}
