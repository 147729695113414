@import 'styles/variables';

.caseStatus {
  padding: 3px 6px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $surfacesLight;
  border-radius: 2px;
  color: $defaultTextColor;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
}

.statusPassed {
  background-color: $green10;
  color: $green100Base;
}

.statusFailed {
  background-color: $red10;
  color: $red100Base;
}

.statusError {
  background-color: $lightOrange10;
  color: $orange100Base;
}
