@import 'styles/variables';

.title {
  color: $primary100Base;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  font-family: $fontFamilyMonsterrat;
}

.link {
  font-family: $fontFamilyMonsterrat;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $secondary100Base;
  cursor: pointer;
  align-items: center;

  .shareIcon {
    margin-left: 4px;
    stroke: $secondary100Base;
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: $secondary120;

    .shareIcon {
      stroke: $secondary120;
    }
  }
}
