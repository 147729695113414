@import 'styles/variables';

.container {
  padding: 0 55px;
  margin: 24px 0 48px;
  width: 100%;
  height: auto;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    border-top: 1px dashed #2b3045;
    width: 100%;
    opacity: 0.15;
  }
}

.branchName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
  position: absolute;
  z-index: 4;
  background-color: $white100Base;
  padding: 0 4px;
}

.dividerWithButtonContainer {
  padding-top: 36px;
  padding-bottom: 24px;
}

.addButtonContainer {
  display: inline-block;
  width: 150px;
  position: absolute;
  background-color: $surfacesPrimary;
}

.buttonContainer {
  margin-top: 36px;
}

.outputTitle {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
  padding-bottom: 24px;
}

.simpleOutputContainer {
  padding-top: 30px;
}
