@import 'styles/variables';

.snackbarsContainer {
  position: absolute;
  right: 55px;
  bottom: 105px;
  width: 340px;
  font-family: $fontFamilyMonsterrat;
  z-index: 3;

  & > * {
    margin-top: 12px;
  }
}
