@import 'styles/variables';

.description {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
  margin-bottom: 36px;
}
