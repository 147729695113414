@import 'styles/variables';

.container {
  display: block;
  cursor: pointer;
}

.tooltip {
  transform: translate(-50%, calc(-100% - 10px));
}

:export {
  chartLineColor: $secondary100Base;
}
