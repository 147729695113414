@import 'styles/variables';

.addButtonContainer {
  padding: 0 15px;
  position: absolute;
  height: 52px;
  width: 100%;
  background: $white100Base;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  max-height: 300px;
  overflow-y: auto;
  z-index: 999;
}
