@import 'styles/variables';

.container {
  padding: 36px 55px 0 55px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &__newApplication {
    width: 165px;
    height: 44px;
    margin-left: auto;
  }
}

.subTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__search {
    margin-right: 24px;
  }

  &__memberFilter {
    display: flex;
    margin-right: auto;
  }
}

.sortBySelect {
  margin-left: 12px;
}

.disabledOption {
  color: $primary40;

  &:not(:disabled):hover {
    color: $primary40;
    background-color: transparent;
    cursor: default;
  }
}
