@import 'styles/variables';

.table {
  width: 100%;
  margin: 27px 0 12px;
}

.tableBodyDragging {
  // Have to compensate height of DnD placeholder element
  // in order not to introduce offset for content below the table
  margin-bottom: -44px;
}

.tableRow {
  &Dragging {
    background-color: $surfacesPrimary;
    border: none;
    box-shadow: 0px 8px 24px rgba(26, 35, 56, 0.2);
  }

  &CombineTarget {
    border: 1px solid $defaultLinkColor;
  }

  &Grayed {
    opacity: 0.5;
  }
}
