.fileUploadLabel {
  margin-bottom: 4px;
}

.fileUploadContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 108px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.hiddenFileUploadContainer {
  max-height: 0;
}

.deleteFileIcon {
  stroke: black;
  width: 17px;
  height: 17px;
}
