@import 'styles/variables';
@import 'components/Tooltip/Common.module';

.avatarContainer {
  position: relative;
  display: flex;
  justify-content: center;

  &:hover .tooltip {
    display: flex;
  }
}

.avatar {
  width: 2.8em;
  height: 2.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(135deg, $green100Base 0%, $secondary100Base 100%);
  line-height: 2.8em;
}

.tooltip {
  $offset: 2px;

  > div {
    &:global(.MuiTooltip-tooltipPlacementTop) {
      margin-top: $defaultTopMargin + $offset;
    }

    &:global(.MuiTooltip-tooltipPlacementBottom) {
      margin-top: $defaultBottomMargin - $offset;
    }
  }
}
