@import 'styles/variables';

.actionsPopUp {
  background-color: $surfacesPrimary;
  box-shadow: 0px 8px 24px rgba(26, 35, 56, 0.2);
  border-radius: $borderRadiusDefault;
  width: 240px;
  height: auto;
  cursor: default;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(15, 35, 66, 0.1);
    padding: 0 12px 0 20px;
    height: 43px;

    & > p {
      font-weight: $boldFontWeight;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: $defaultLetterSpacing;
      text-transform: uppercase;
      color: $subtextColor;
      display: table-cell;
      vertical-align: middle;
    }

    & > button {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: 0;
      background-color: transparent;
      outline: 0;
      cursor: pointer;

      & > svg {
        width: 100%;
        height: auto;
        stroke: $primary80;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
