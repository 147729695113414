@import 'styles/variables';

.container {
  padding: 36px 25px;
  width: 980px;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 11px 0 30px;
}

.title {
  & input,
  p {
    font-family: $fontFamilyMonsterrat;
    font-weight: $boldFontWeight;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    align-self: center;
    background-color: transparent;
    padding-left: 0;
  }
}

.description {
  margin: 0 30px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.closeImage {
  cursor: pointer;
  stroke: $primary80;
}

.focusTextArea {
  width: 733px;
  min-height: $pLineHeight;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  border: 0;
  outline: none;
  resize: none;
  padding: 0;
  opacity: 1;
  transition: 0.4s height ease;
  overflow-y: hidden;

  &::placeholder {
    color: $primary40;
  }
}

.content {
  position: relative;
  padding-bottom: 12px;
}

.arrowImageClosed {
  position: absolute;
  top: 15px;
  left: -20px;
  stroke: $secondary100Base;
  cursor: pointer;
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

.arrowImageOpened {
  @extend .arrowImageClosed;
  transform: rotate(0);
}

.editButton {
  margin: 24px 0 0 30px;
}

.branchCondition {
  margin: 48px 0;
}

.skeletonRow {
  margin: 0 30px;
  justify-content: flex-start;
}

.dataItemSkeleton {
  width: 356px;
}

.variableSkeleton {
  width: 356px;
}
