@import 'styles/variables';

.andSeparator {
  padding: 0 4px;
  background-color: $white100Base;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $tagFontSize;
  line-height: $menuHeaderLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
  color: $orange100Base;
  border-radius: 6px;
}
