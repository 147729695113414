@import 'styles/variables';

$backgroundOpacity: 0.9;

.titleButton {
  display: flex;
  position: relative;
  align-items: center;
  padding: 12px 12px 12px 20px;
}
.statusButton {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  min-width: 130px;

  svg {
    margin-right: 4px;
  }
}
.approvedButton {
  color: $green100Base;
  background: transparentize($green100Base, $backgroundOpacity);
  padding: 10px 20px 10px 10px;

  svg {
    path {
      stroke: $green100Base;
    }
  }
}
.rejectedButton {
  color: $red100Base;
  background: transparentize($red100Base, $backgroundOpacity);
  padding: 10px 20px 10px 16px;

  svg {
    path {
      stroke: $red100Base;
    }
  }
}
