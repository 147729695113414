.pageContent {
  display: flex;
  flex-direction: column;
}

.emailViewContainer {
  width: 980px;
}

:export {
  contextualViewWizard: 980px
}
