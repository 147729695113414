@import 'styles/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}
