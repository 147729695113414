@import 'styles/variables';

.productTemplatesLabel {
  margin-bottom: 8px;
}

.productIconsContainer {
  margin-top: 24px;
}

.productIconsInnerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.productIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 80px;
  width: 120px;
  box-sizing: border-box;
  border-left: 1px solid $borderAndSeparatorsColor;
  border-bottom: 1px solid $borderAndSeparatorsColor;

  &:hover {
    background-color: $secondary6;

    & > svg {
      fill: $secondary100Base;
    }
  }

  & > svg {
    fill: $primary80;
    transition: fill 0.25s ease-in-out;
  }

  &.selectedProductIcon {
    background-color: $secondary100Base;

    & > svg {
      fill: $white100Base;
    }
  }

  &:last-child, &:nth-child(5n) {
    border-right: 1px solid $borderAndSeparatorsColor;
  }

  &:nth-child(-n + 5) {
    border-top: 1px solid $borderAndSeparatorsColor;
  }
}

.checkboxMultiSelectListWrapper {
  margin-top: 24px;
}
