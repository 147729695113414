@import 'styles/variables';

.userCardContainer {
  margin-top: auto;
  display: flex;
  background-color: inherit;
  height: 60px;
  cursor: pointer;
  align-items: center;
  overflow: hidden;
  background-color: $darkBlue120;
}

.activeUserCardContainer {
  @extend .userCardContainer;
  background-color: inherit;
}

.imgContainer {
  min-width: 72px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.activeCircle {
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  border-radius: 100%;
  border: 2px solid $orange100Base;
}

.userImg {
  height: 32px;
  width: 32px;
  border-radius: 100%;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 208px;
  overflow: ellipsis;

  font-family: $fontFamilyMonsterrat;
  color: $contrastSurfacesTextColor;
  font-weight: $boldFontWeight;
}

.userName {
  font-size: $pFontSize;
  line-height: $pLineHeight;
  white-space: nowrap;
  font-family: $fontFamilyMonsterrat;
}

.activeUserName {
  @extend .userName;
  color: $orange100Base;
}

.companyName {
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  white-space: nowrap;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  color: $subtextColor;
}
