@import 'styles/variables';

.usersPage {
  width: 100%;
  height: 100%;
}

.usersContent {
  padding: 24px 0 0 0;
}

.noResultsFoundContainer {
  margin-top: 56px;
}
