@import '../../node_modules/reset-css/sass/reset';
@import 'typography.module';
@import 'variables';

.fixedSidebar {
  position: fixed;
  z-index: 1000;
}

.navContainer {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 10000;
}

.footerContainer {
  border-top: 1px solid black;
  z-index: 10000;
}

.sidebarContainer {
  z-index: 1000;
  background-color: #fff;
}

.floatingSidebarContainer {
  z-index: 1000;
  margin-top: 78px;
}

iframe {
  display: none;
}

.__re-bulma_column {
  padding: 0;
}
.__re-bulma_columns,
.__re-bulma_columns:not(:last-child) {
  margin: 0;
}
