@import 'styles/variables';
@import 'styles/mixins';

.productList {
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    & > path {
      stroke: $secondary100Base;
    }
  }

  h2 {
    @include headerTitle;
  }
}

.actionItem {
  display: flex;
  align-items: center;

  padding-right: 4px;

  &.selected {
    color: $secondary100Base;
  }
}

.checkIcon {
  margin: 0 8px 0 auto;
}

.contextualPopUp {
  margin-left: 12px;
  margin-top: -8px;
}

.actionPopUp {
  box-shadow: none;
}

:export {
  contextualPopUpMaxHeight: 576px;
  contextualPopUpMaxWidth: 272px;
}
