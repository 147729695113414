@import 'styles/variables';
@import 'styles/colorPalette';

.tableRowWrapper:hover {
  .tableRow {
    cursor: pointer;
    background-color: $secondary6;
  }
}

.nameCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 9px;
  padding-bottom: 9px;
  gap: 2px;
  font-weight: $boldFontWeight;
  color: $primary100Base;
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.loanAmountCell {
  justify-content: flex-end;
  font-weight: $boldFontWeight;
  color: $primary100Base;
}

.duplicateIcon {
  width: 24px;
  height: 24px;
  padding: 2px;
  flex-shrink: 0;
  stroke: $primary80;
}

.displayId {
  padding: 10px;
}

.label {
  margin-top: 2px;
}

.blankNameLine {
  color: $primary40;
}
