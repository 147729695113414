@import 'styles/variables';
@import '../skeletonAnimationMixin';

.skeletonRectangle {
  border-radius: 2px;
}

.skeletonPrimary6 {
  background-color: $primary6;
}

.skeletonPrimary10 {
  background-color: $primary10;
}

.skeletonPrimary20 {
  background: $primary20;
}

.skeletonAnimationPrimary6 {
  @include skeletonAnimationPrimary6;
}

.skeletonAnimationPrimary10 {
  @include skeletonAnimationPrimary10;
}

.skeletonAnimationPrimary20 {
  @include skeletonAnimationPrimary20;
}
