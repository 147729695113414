@import 'styles/variables';

.buttonToggleGroup {
  & > span:first-child > button {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & > span:last-child > button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.buttonFromToggleGroup {
  text-transform: capitalize;
  background-color: $white100Base;
  border: 1px solid $borderAndSeparatorsColor;
  color: $primary80;

  &:disabled {
    border: 1px solid $borderAndSeparatorsColor;
  }
}
