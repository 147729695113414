@import 'styles/variables';

$showIcon: url('images/invisible.svg') no-repeat center;
$hideIcon: url('images/visible.svg') no-repeat center;

.inputContainer {
  width: 100%;
  margin-top: 24px;
  &__input {
    border: 1px solid rgba(15, 35, 66, 0.1);
    border-radius: $borderRadiusDefault;
    padding: 16px 20px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    cursor: text;
    height: 52px;

    &:hover {
      border: 1px solid rgba(15, 35, 66, 0.2);
    }

    &.inputFocus {
      border: 1px solid $secondary40;
    }

    &.inputError {
      border: 1px solid $defaultErrorColor;
    }

    & input {
      width: 100%;
      border: 0;
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $normalFontWeight;
      font-size: $pFontSize;
      line-height: $pLineHeight;
      color: $headerTextColor;
      outline: none;

      &::placeholder {
        color: $placeholderTextColor;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    & button {
      padding: 0;
      border: 0;
      background-color: transparent;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px;
      height: 24px;

      & svg {
        stroke: $primary80;
        width: 100%;
        height: auto;
      }

      &.showButton {
        background-color: $primary80;
        mask: $showIcon;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
        margin-top: 0;
      }

      &.hideButton {
        @extend .showButton;
        mask: $hideIcon;
        margin-top: -2px;
      }
    }
  }

  &__label {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: bold;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    color: $headerTextColor;
  }

  &__error {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $defaultErrorColor;
    margin-top: 4px;
  }
}

.labelWithLink {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.navLink {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $defaultLinkColor;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}

.input {
  margin-right: 12px;
}
