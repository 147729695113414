@import 'styles/variables';

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.titleWithActionsContainer {
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.actionsContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.titleActionsContainer {
  @extend .actionsContainer;

  width: fit-content;
  left: calc(100% + 12px);
}

.headerActionsContainer {
  @extend  .actionsContainer;

  right: 0;
}
