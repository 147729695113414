@import 'styles/colorPalette';
@import 'components/BillingDashboard/Overview/OverviewVariables';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: $primary100Base;
  margin-bottom: 16px;
}

.value {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  color: $primary100Base;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.hint {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $primary80;
  display: flex;
}

.questionIcon {
  margin-left: 4px;
}
