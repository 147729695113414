@import 'styles/variables';

.wrapper {
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 6px;
  
  > img {
    max-height: 100%;
    max-width: 100%;
  }
}

.tools {
  display: flex;
  position: absolute;
  bottom: 16px;
  height: 44px;
  background: rgba(6, 13, 25, 0.8);
  border-radius: 4px;
}

.zoomButton {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  stroke: white;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none;

  &.disabled {
    stroke: $primary40;
  }
}

.zoomIcon {
  @extend .zoomButton;
  width: 40px;
}

.resetButton {
  @extend .zoomButton;
  font-size: 14px;
  font-weight: $normalFontWeight;
  width: 42px;
}
