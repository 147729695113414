@import 'styles/variables';
@import 'styles/colorPalette';

.automatedProcessCardInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 8px;
}

.automatedProcessCardContent {
  padding: 24px;
}

.automatedProcessCardTitle {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $primary100Base;
}

.automatedProcessActivated {
  background: $secondary6;
  border: 1px solid $secondary40;
}

.automatedProcessIcon {
  display: flex;
  width: 60px;
  margin-bottom: 12px;

  svg {
    width: 100%;
    height: auto;
  }
}

.activationInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $primary20;
  padding: 8px 24px;
}

.activationInnerActive {
  border-top: 1px solid $secondary40;
}

.activationInnerClickable {
  cursor: pointer;
}

.activationTitle {
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $primary100Base;
}

.activationTitleDisabled {
  color: $primary40;
}

.activationTitleActive {
  color: $primary80;
}

.autoCompletion {
  margin: 0;
}

.disableSwitcher {
  color: $primary40;
}

.numberOfDays {
  margin-top: 8px;
}

.declineReason {
  margin-top: 8px;
}

.notificationIcon {
  width: 20px;
}

.cardTitleWithNotification:after {
  display: inline;
  width: 20px;
  height: 20px;
  content: url('images/warning-icon.svg');
  position: absolute;
  margin-left: 5px;
}

.companySettingsLink {
  display: flex;
  align-items: center;
}

.companySettingsLinkTitle {
  margin-right: 4px;
}

.sendGridNotification {
  margin-top: 16px;
}

.sendGridNotificationText {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $primary80;
}
