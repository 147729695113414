@import 'styles/variables';

.saveButton {
  margin-top: 36px;
}

.additionalInputsSection {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
