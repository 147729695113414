@import 'styles/variables';

.popUpBody {
  &__description {
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $defaultTextColor;
    padding: 24px 0 36px 0;
  }
  &__button {
    display: flex;
    & > button {
      min-width: 180px;
      margin-right: 12px;
    }
  }
}

