.skeletonHeader {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.skeletonTitle {
  display: flex;
  flex-direction: column;
  padding: 0 25px;

  & > div {
    &:first-child {
      display: flex;
      margin-bottom: 8px;
    }
  }
}
