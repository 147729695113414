@import 'styles/variables';
@import 'styles/mixins';

.content {
  max-height: calc(100% - 61px);
}

.stepperWrapper {
  border-top: 1px solid #f1f2f4;
  position: absolute;
  bottom: 0;
  background-color: $white100Base;
}

.stepper {
  padding: 12px 0 12px 55px;
}

.continueButton {
  display: block;
  margin-top: 36px;
  margin-bottom: 35px;
}

.createApplicationButton {
  margin-top: 36px;
  margin-bottom: 35px;
  width: 190px;
}

.formContainer {
  margin: 0 30px;
}

.productName {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
  padding-left: 30px;
  margin-bottom: 36px;
}

.teamMembersSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 13px;
  margin-left: 24px;
}

.editTeamMembersButton {
  margin-left: 2px;
  padding: 6px 8px 6px 6px;
  border-radius: 6px;

  &:not(:disabled):hover {
    background-color: $secondary10;
    color: $secondary100Base;
  }
}
