@import 'styles/variables';

.confirmButton {
  margin-right: 12px;
}

.declineButton {
  min-width: 180px;
}

.contentMessage {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #3f4f68;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  margin-top: 36px;
}

.subMessage {
  margin-top: 20px;
}
