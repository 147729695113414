@import 'styles/variables';

.continue {
  margin-top: 36px;
  width: 100%;
}

.firstInput {
  padding-top: 12px;
}

.subHeader {
  color: $headerTextColor;
  font-size: $h5FontSize;
  font-weight: $boldFontWeight;
  line-height: $h5LineHeight;
  margin: 36px 0 12px;
}
