@import 'styles/variables';

.header {
  display: flex;
  flex-direction: column;
  margin: 0 30px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-right: 12px;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  margin-top: 8px;
}

.tip {
  white-space: pre-line;
}

.downloadButton {
  margin-left: auto;
}
