@import 'styles/variables';

.content {
  padding: 0 55px;
  margin: 0 0 48px;
  width: 100%;
  height: auto;
}

.continueButton {
  margin-top: 36px;
}

.outputVariablesInput {
  width: 100%;
}

.auto {
  padding-top: 24px;
}

.equalsSeparator {
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.separatorText {
  color: $primary40;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $tableHeadFontSize;
  line-height: $tableHeadLineHeight;
  background-color: $white100Base;
  z-index: 1;
  padding: 0 2px;
  text-transform: uppercase;
}

.separatorLine {
  border-top: 1px dashed $primary10;
  width: 100%;
  position: absolute;
}

.codeEditor {
  margin-top: 24px;
}

.calculatedVariableOptionsList {
  & > div {
    height: 44px;
  }
}

