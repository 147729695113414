@import 'styles/variables';

.variableHighlight {
  pointer-events: auto;
  background: $orange10;
  position: absolute;
}

.aceEditorContainer {
  border: 1px solid $primary10;
  border-radius: 4px;
}

.aceEditorContainerPreviewMode {
  border: 0;
  border-radius: 0;
}

.aceEditor {
  width: 100% !important;
  border-radius: 4px;
}

.header {
  display: flex;
  align-items: center;
  min-height: 16px;
  margin-bottom: 8px;
}

.testCodeButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  margin-left: auto;
  color: $secondary100Base;

  & path {
    fill: $secondary100Base;
  }
}

.disabledTestCodeButton {
  color: $primary40;
  cursor: default;

  & path {
    fill: $primary40;
  }
}

.disabledAceEditor {
  pointer-events: none;
  background: $surfacesLight !important;

  :global(.ace_gutter) {
    background: $primary10 !important;
    color: $primary40 !important;
  }
}

.aceEditorPreviewMode {
  background-color: transparent;

  :global(.ace_cursor-layer) {
    opacity: 0;
  }
}

.withErrorCodeEditorContainer {
  border: 1px solid $red100Base;
}

.error {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $red100Base;
}

.labelWithTooltipContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questionIcon {
  margin-left: 8px;
}
