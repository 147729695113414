@import 'styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include headerTitle;
}
