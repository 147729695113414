@import 'styles/variables';

$leftNavigationHeaderHeight: 72px;

.headerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  position: relative;
}

.mainLogoContainerExpanded {
  display: flex;
  align-items: center;
  width: 100%;
  height: $leftNavigationHeaderHeight;
  padding: 0 18px;
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}

.mainLogoContainerCollapsed {
  @extend .mainLogoContainerExpanded;
  width: 65px;
}

.mainLogo {
  min-height: 37px;
  min-width: 110px;
  cursor: pointer;
}
.contactUsIconContainer {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  margin-left: 90px;

  border: 1.5px solid $darkBlue90;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background-color: $secondary100Base;
  }
}
.contactUsIcon {
  min-height: 36px;
  min-width: 36px;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $secondary100Base;
  white-space: nowrap;
  cursor: pointer;
}

.titleIcon {
  margin: 12px 24px;
  cursor: pointer;
  min-height: 24px;
  min-width: 24px;
}

.titleText {
  width: 208px;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-weight: $boldFontWeight;
  color: $contrastSurfacesTextColor;
  overflow: hidden;
  font-family: $fontFamilyMonsterrat;
}
