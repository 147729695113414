@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.field {
  display: flex;
  flex-direction: row;
  height: fit-content;
  border-top: 1px solid $borderAndSeparatorsColor;

  & > div {
    padding: 0 10px;
  }

  .fieldValue {
    padding-right: 20px;
  }
}

.lastFieldInColumn {
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  & > div {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 36px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 30px;
  height: 100%;
}