@import 'styles/variables';
@import 'styles/colorPalette';

.root {
  display: flex;
  justify-content: space-between;
  height: 44px;
  box-sizing: border-box;
  margin-left: 12px;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    stroke: $primary100Base;
    width: 44px;
    height: 44px;
    border: 1px solid $primary10;
    transition: all $transitionDuration ease-in-out;

    &:hover {
      border: 1px solid $secondary40;
      stroke: $secondary100Base;
    }

    &:first-child {
      border-top-left-radius: $borderRadiusDefault;
      border-bottom-left-radius: $borderRadiusDefault;
    }

    &:last-child {
      border-top-right-radius: $borderRadiusDefault;
      border-bottom-right-radius: $borderRadiusDefault;
    }

    &__selected {
      background-color: $secondary100Base;
      stroke: $contrastSurfacesTextColor;
      border: 1px solid $secondary100Base;

      &:hover {
        background-color: $secondary100Base;
        stroke: $contrastSurfacesTextColor;
        cursor: default;
        border: 1px solid $secondary100Base;
      }
    }
  }
}
