@import 'styles/variables';

.skeletonDraggableUploadFileStub {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashedContainer {
  width: 100%;
  height: 80px;
  border: 1px dashed $borderAndSeparatorsColor;
}
