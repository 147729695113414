@import 'styles/variables';
@import 'styles/mixins';

$dropdownIcon: url('images/dropdown.svg') no-repeat center;

.moduleContainer {
  display: flex;
}

.module {
  display: flex;
  flex-direction: column;
  background-color: $surfacesPrimary;
  border-radius: 6px;
  border: 1px solid $surfacesPrimary;
  box-shadow: 0 1px 3px rgba(15, 35, 66, 0.07);
  transition: border 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  position: relative;
  min-width: 690px;

  &:hover {
    border: 1px solid $secondary40;
  }

  &:hover .duplicateButton {
    opacity: 1;
  }

  &:hover .disabledModuleName {
    max-width: 430px;
  }
}

.moduleMainInfo {
  width: 100%;
  padding: 11px 0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover .addBranchButton {
    opacity: 1;
  }

  &:hover .dragImage {
    opacity: 1;
  }

  &:hover .moduleName {
    max-width: 358px;
  }

  &:hover .disabledModuleName {
    max-width: 311px;
  }
}

.moduleInfo {
  margin-left: 12px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.moduleContent {
  display: flex;
}

.moduleType {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $menuHeaderFontSize;
  line-height: $menuHeaderLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
  color: $orange100Base;
}

.moduleName {
  margin: 4px 0 4px -6px;
  display: flex;
  align-items: center;
  height: 24px;
  max-width: 508px;

  & > input {
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    color: $headerTextColor;
    font-family: $fontFamilyMonsterrat;
    background-color: transparent;
    border: 0;
    outline: 0;
  }

  & > p {
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    font-family: $fontFamilyMonsterrat;
    border: 0;
    outline: 0;
    position: absolute;
    visibility: hidden;
  }
}

.disabledModuleName {
  max-width: 430px;
}

.moduleNameWithDisabledTag {
  display: flex;
  align-items: center;
}

.moduleContent {
  display: flex;
  align-items: center;
}

.moduleDescription {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
}

.hideButton {
  display: flex;
  align-items: center;
  width: 24px;
  margin-right: 12px;

  & > button {
    padding: 0;
    width: 24px;
    height: 24px;
    border: 0;
    cursor: pointer;
    outline: 0;
    background-color: transparent;
    border-radius: 6px;
    transition: background-color 0.25s ease-in-out;

    & > svg {
      transition: all 0.15s ease-in-out;

      & > path {
        transition: stroke 0.15s ease-in-out;
        stroke: $primary100Base;
      }
    }

    &:hover {
      background-color: $secondary10;

      & > svg {
        & > path {
          stroke: $secondary100Base;
        }
      }
    }

    &.isOpen {
      & > svg {
        transform: rotate(-0.5turn);
      }
    }
  }
}

.requireRules {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 12px 0 4px;

  & > svg {
    width: 24px;
    height: 24px;
  }

  & > p {
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $subtextColor;
  }

  &:hover .tooltip {
    display: block;
  }
}

.branchesContainer {
  height: auto;
  border-radius: 6px;
  padding: 0 3px;
}

.branchListItem:before {
  left: 57px;
  top: -1px;
  right: 57px;
}

.addBranchButton {
  padding-left: 4px;
  opacity: 0;

  & > button {
    @include buttonReset;
    display: flex;
    align-items: center;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $secondary100Base;
    padding: 2px 12px 2px 4px;
    border-radius: 6px;
    transition: all 0.25s ease-in-out;
    height: 28px;

    &:hover {
      background-color: $secondary10;
    }

    & > svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;

      & > path {
        stroke: $secondary100Base;
      }
    }
  }
}

.moduleLeft {
  display: flex;
  height: 48px;
}

.dragImage {
  opacity: 0;
  transition: 0.25s all ease-in-out;
  display: flex;
  align-items: center;

  & > svg {
    width: 24px;
    height: 24px;
  }
}

.moduleImage > svg {
  fill: $primary80;
  height: 48px;
  width: 48px;
}

.moduleRight {
  @extend .moduleLeft;
  align-items: center;
}

.moreImage {
  height: 24px;
  margin-top: 7px;
  margin-right: 12px;
}

.disabledModuleTag {
  color: $primary80;
  letter-spacing: $defaultLetterSpacing;
  height: 24px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  background-color: $surfacesPrimary;
  border-radius: 2px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
  margin-left: 6px;
}

.moduleDisabled {
  background-color: $surfacesLight;
  border: 1px solid $borderAndSeparatorsColor;
}

.tooltip {
  position: absolute;
  background: $surfacesPrimary;
  border: 1px solid $primary120;
  box-sizing: border-box;
  border-radius: $borderRadiusDefault;
  transform: translate(0, -100%);
  padding: 8px 12px;
  z-index: 10;
  display: none;
  bottom: 0;
}

.tooltipText {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  white-space: nowrap;
}

.moduleDraggable {
  box-shadow: 0 8px 24px rgba(15, 35, 66, 0.2);
}

.addModule {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -50px;
  left: 72px;
}

.requirementsRulesContainer {
  display: flex;
  align-items: center;
  height: 44px;
  margin: 14px 0 0 -4px;
  z-index: 1;
}

.requirementsRules {
  width: 180px;
  height: 44px;
  background-color: $red100Base;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $surfacesPrimary;
  padding-right: 12px;

  & > svg {
    width: 36px;
    height: 36px;

    & path,
    circle {
      stroke: $surfacesPrimary;
    }
  }

  & > p {
    margin-left: 2px;
  }
}

.externalIntegrationContainer {
  @extend .requirementsRulesContainer;
  margin-left: -4px;
}

.externalIntegration {
  height: 72px;
  width: 180px;
  background-color: $primary60;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $surfacesPrimary;

  & > p {
    margin-left: 10px;
  }
}

.integrationArrowLeft {
  transform: rotate(180deg);
  margin-right: -4px;
}

.moreButton {
  &:hover {
    & > svg {
      & > circle {
        fill: $secondary100Base;
      }
    }
  }
}
