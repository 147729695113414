@import 'styles/variables';

.applyFilter {
  width: 100%;
  margin-top: 36px;
}

.filterLayoutTitle {
  margin-bottom: 16px;
}

.statusDropdownContainer {
  margin: 0;
}

.typeTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  padding-bottom: 12px;
  color: $primary100Base;
}

.borrowerTypesContainer {
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: space-between;
}
