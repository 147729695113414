.saveButton {
  margin-top: 36px;
}

.selectInput {
  & > svg {
    stroke: none;
  }

  & > input {
    padding-left: 8px;
  }
}

.editFolderButton {
  &:disabled {
    pointer-events: none;
  }
}
