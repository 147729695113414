@import 'styles/variables';

.uploadFileStubContainer {
  width: 100%;
}

.stubInputWrapper {
  padding: 30px 24px;
  margin: 0;
  text-align: center;
  color: $primary40;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.label {
  margin-bottom: 12px;
}
