@import 'styles/variables';
@import 'styles/colorPalette';

.inner {
  font-family: $fontFamilyMonsterrat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white100Base;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;

  input {
    margin: 0;
  }
}

.inputContainer {
  padding: 0;
}

.description {
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.number {
  color: $primary100Base;
}

.daysAmount {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: $primary40;
}

.loader {
  right: 63px;
  top: 50%;
  transform: translateY(-50%);
}
