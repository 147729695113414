@import 'styles/variables';
@import 'styles/mixins';

$menuHeaderContentColor: #2b3045;

.burgerMenuContainer {
  background-color: $surfacesPrimary;
  width: 260px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.menuHeader {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  border-bottom: 1px solid $primary6;
}

.closeButton {
  cursor: pointer;
  stroke: $primary80;
}

.menuTitle {
  margin-left: 20px;
  color: $subtextColor;
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $menuHeaderFontSize;
  line-height: $menuHeaderLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
}

.linksContainer {
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 20px 14px 10px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

  & p {
    width: 182px;
  }
}

.linkEnabled {
  color: $primary80;
  background-color: $white100Base;

  &:hover {
    color: $secondary100Base;
    background-color: $secondary10;
  }
}

.linkActive {
  color: $secondary100Base;
}

.linkDisabled {
  &,
  &:hover {
    color: $subtextColor;
    background-color: $primary6;
  }
}

.checkedIcon {
  margin-right: auto;
}

.switchOrganizationLink {
  @extend .link;
  border-top: 1px solid $borderAndSeparatorsColor;
}

.logOutLink {
  @extend .link;
  border: 0;
  border-top: 1px solid $borderAndSeparatorsColor;
  text-align: start;
  cursor: pointer;
  color: $red100Base;
  background-color: $surfacesPrimary;

  &:hover {
    background-color: $red10;
    color: $red100Base;
  }
}
