@import 'styles/colorPalette';

@mixin message {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $white100Base;
}

.icon {
  fill: $white100Base;
  margin-right: 4px;
}

.container {
  background-color: $red100Base;
  height: 32px;
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  padding-left: 55px;
  z-index: 999;
}

.message {
  @include message;
}

.paymentPortalLink {
  margin-left: 8px;
  display: flex;
  align-items: center;

  .paymentPortalText {
    @include message;

    border-bottom: 1px solid $white100Base;
  }

  .paymentPortalIcon {
    stroke: $white100Base;
    margin-right: 2px;
  }
}
