@import 'styles/variables';
@import 'components/Button/ButtonVariables';

.button {
  min-width: auto;
  padding: 12px 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary100Base;
  border-color: $primary6;

  &.disabled {
    color: $primaryBtnTextColorDisabled;
    background-color: $primaryBtnBackgroundColorDisabled;
    border: $primaryBtnBorderDisabled;
    pointer-events: none;
  }

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.selected,
  &.selected:focus {
    background-color: $secondary100Base;
    color: $white100Base;
    border-color: $secondary100Base;
  }
}

.buttonToggleGroup {
  display: flex;
}
