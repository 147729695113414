@import 'styles/variables';

.ruleRow {
  display: flex;
  justify-content: space-between;
  width: 870px;
  position: relative;
  border-bottom: 1px solid $borderAndSeparatorsColor;
  margin: 0 30px;
  cursor: pointer;
  transition: 0.25s background-color ease-in-out;

  &:hover {
    background-color: $secondary6;
  }
}

.tableCell {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
  text-justify: center;
}

.variable {
  @extend .tableCell;
  color: $primary100Base;
  min-width: 356px;
  padding-left: 10px;
}

.value {
  @extend .tableCell;
  font-weight: $normalFontWeight;
  color: $defaultTextColor;
  min-width: 356px;
  padding-left: 10px;
}

.conditionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ruleContainer {
  display: flex;
  padding: 9px 0;
  position: relative;
  align-items: center;
}

.actionsButton {
  margin-left: auto;
}
