@import 'styles/variables';

.listItem {
  padding-bottom: 16px;

  & > p {
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    color: $defaultTextColor;
    display: flex;
    align-items: center;

    &::before {
      content: '•';
      color: $secondary100Base;
      font-weight: $boldFontWeight;
      line-height: $smallLineHeight;
      margin: 0 8px 4px 0;
    }
  }
}
