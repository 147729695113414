@import 'styles/colorPalette';
@import 'styles/variables';

$confettiSize: 200px;

.card {
  position: relative;
  background-color: $white100Base;
  border-radius: $borderRadiusDefault;
  font-family: $fontFamilyMonsterrat;
  transition: background-color 0.25s ease-in-out;
  width: 100%;
  padding: 12px 16px;
  box-shadow: 0px 1px 3px rgba(15, 35, 66, 0.07);
  cursor: pointer;
  min-height: 108px;

  &.disabled {
    cursor: default;
  }

  &:not(.disabled):hover {
    background-color: $secondary6;
    transition: background-color 0.25s ease-in-out;
  }

  .body {
    display: flex;
    position: relative;

    .moreButton {
      position: absolute;
      top: 6px;
      right: 0;
      width: 24px;
      height: 24px;
    }
  }

  &__wrappingLink {
    display: block;
    width: 100%;
  }

  &__labels {
    display: flex;
    height: 4px;
    margin-bottom: 4px;

    > div:not(:first-child) {
      margin-left: 4px;
    }
  }

  &__title {
    overflow-wrap: break-word;
    font-family: $fontFamilyMonsterrat;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-weight: $boldFontWeight;
    color: $primary100Base;
    display: flex;
    gap: 8px;

    & > div:first-child {
      max-width: 150px;
    }
  }

  &__subTitle {
    font-family: $fontFamilyMonsterrat;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-weight: $normalFontWeight;
    color: $subtextColor;
    display: flex;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    .members {
      margin: -50% 0;
    }
  }

  &__value {
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    font-weight: $boldFontWeight;
    color: $primary100Base;
  }
}

.loader {
  position: absolute;
  top: 6px;
  right: 0;
}

.dot {
  &:not(.hasIcon) {
    margin-left: 4px;
  }

  margin-right: 4px;
}

.duplicateIcon {
  stroke: $primary40;
}

.avatarIcon {
  margin: -50% 0;
  height: 28px;
}

.blankBorrowerFullName {
  color: $primary40;
}

:export {
  confettiSize: $confettiSize;
}
