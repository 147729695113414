@import 'styles/variables';

.container {
  position: relative;
  width: 710px;
  height: 100%;
  padding: 36px 55px 60px 55px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.createProductButton {
  margin: 36px 0 60px 0;
}

.closeImage {
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}

.productStatusSection {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > p {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    margin-bottom: 12px;
  }

  & > div {
    display: flex;
    flex-direction: row;
  }
}

.productStatusCheckboxContainer {
  margin-right: 0 !important;
}

.productStatusCheckbox {
  padding-right: 12px !important;
}

.questionIcon {
  margin-left: 8px;
}

