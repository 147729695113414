@import 'styles/variables';
@import 'styles/colorPalette';

.container {
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 8px;
}

.content {
  padding: 24px;
}

.activationContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  border-top: 1px solid $primary20;
}

.icon {
  margin-bottom: 14px;
}

.autoCompletion {
  margin-bottom: 6px;
}
