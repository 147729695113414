@import 'styles/variables';

.popupContentSlideContainer {
  transition: 0.5s;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.popupSlide {
  min-width: 100%;

  &__withPadding {
    padding: 0 55px 48px;
  }
}

.warningText {
  padding: 24px 55px;
  background-color: $red10;
  color: $red100Base;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}
