@import 'styles/variables';

.updateLine {
  &DateText {
    width: 100%;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    color: $defaultTextColor;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  &EditorText {
    margin-left: 12px;
  }
}
