@import 'styles/variables';

.addCardButton {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: $secondary10;
  }

  & path {
    stroke: $secondary100Base;
  }
}
