@import 'styles/variables';
@import 'styles/mixins';

.cardsForm {
  margin-top: 36px;
}

.container {
  width: 980px;
  height: 100%;
}

.content {
  padding: 36px 55px 0 55px;
  position: relative;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    @include headerTitle;
  }
}

.closeButton {
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}

.createButton {
  margin: 36px 0 60px 0;
}
