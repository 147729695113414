@import 'styles/variables';

.container {
  padding-top: 12px;
}

.userCard {
  margin-top: 36px;
}

.label {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $primary100Base;
}

.resendEmailButton {
  margin-top: 24px;
}

.subTitleRow {
  &:not(:first-child) {
    margin-top: 24px;
  }
}

.timer {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;

  .seconds {
    font-weight: bold;
    color: $primary100Base;
    display: inline;
  }
}
