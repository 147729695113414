@import 'styles/variables';

$lgBtnPadding: 15px 24px;
$mdBtnPadding: 10px 20px;
$smBtnPadding: 9px 16px;

$mdBtnWithIconPadding: 10px 20px 10px 16px;

$lgBtnWidthDefault: 180px;
$mdBtnWidthDefault: 140px;
$smBtnWidthDefault: 100px;

$lgBtnHeight: 52px;
$mdBtnHeight: 44px;
$smBtnHeight: 36px;

$primaryBtnBackgroundColor: $orange100Base;
$primaryBtnBackgroundColorHover: $orange120;
$primaryBtnBackgroundColorDisabled: $surfacesLight;
$primaryBtnBackgroundColorLoading: $orange120;

$primaryBtnTextColor: $white100Base;
$primaryBtnTextColorHover: $white100Base;
$primaryBtnTextColorDisabled: $subtextColor;
$primaryBtnTextColorLoading: $white100Base;

$secondaryBtnBackgroundColor: transparent;
$secondaryBtnBackgroundColorHover: $secondary10;
$secondaryBtnBackgroundColorDisabled: transparent;

$secondaryBtnTextColor: $secondary100Base;
$secondaryBtnTextColorHover: $secondary100Base;
$secondaryBtnTextColorDisabled: $subtextColor;

$warningBtnBackgroundColor: transparent;
$warningBtnBackgroundColorHover: $red10;
$warningBtnBackgroundColorDisabled: transparent;

$warningBtnTextColor: $defaultErrorColor;
$warningBtnTextColorHover: $defaultErrorColor;
$warningBtnTextColorDisabled: $subtextColor;

$primaryBtnBorder: 1px solid $orange100Base;
$secondaryBtnBorder: 1px solid $secondary100Base;
$warningBtnBorder: 1px solid $defaultErrorColor;

$primaryBtnBorderHover: 1px solid $orange120;
$secondaryBtnBorderHover: 1px solid $secondary100Base;
$warningBtnBorderHover: 1px solid $defaultErrorColor;

$primaryBtnBorderLoading: 1px solid $orange120;

$primaryBtnBorderDisabled: 1px solid $surfacesLight;
$secondaryBtnBorderDisabled: 1px solid $borderAndSeparatorsColor;
$warningBtnBorderDisabled: 1px solid $borderAndSeparatorsColor;

$secondaryBtnBoxSizing: border-box;
$warningBtnBoxSizing: border-box;

$transitionDuration: 0.25s;
