@import 'styles/mixins';

.droppableList {
  padding: 0 36px;
}

.draggableInputStub {
  margin-top: 16px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  padding: 18px 0 14px 0;
}

.infiniteScrollContainer {
  @include overflowOverlayY;
}

.searchNotFound {
  padding: 0 36px;

  & li:last-child {
    padding-bottom: 0;
  }
}
