@import 'styles/variables';
@import 'styles/mixins';

.container {
  position: relative;
  width: 980px;
  height: 100%;
}

.content {
  padding: 35px 25px 0 25px;
  height: 100%;
  overflow-y: auto;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  padding-left: 30px;

  h2 {
    @include headerTitle;
  }

  & > div {
    display: flex;
    flex-direction: row;
  }
}

.closeButton {
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}

.teamMembersSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
}

.userAvatarsList {
  & > div {
    padding-left: 0;
  }
}

.editTeamMembersButton {
  margin-left: 8px;
  padding: 6px 8px;
  border-radius: 6px;

  &:not(:disabled):hover {
    background-color: $secondary10;
    color: $secondary100Base;
  }
}
