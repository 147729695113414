@import 'styles/variables';
@import 'styles/colorPalette';

$justifyItemsForTotal: flex-end;

.table {
  width: 100%;
  padding: 24px 55px 50px 55px;

  .totalColumnHeadCell {
    justify-content: $justifyItemsForTotal;
  }
}
