.title {
  margin-bottom: 24px;
}

.tabSwitch {
  margin-bottom: 36px;
}

.tab {
  & label {
    margin-right: 36px;
  }
}

.trialInfo {
  margin: 0 -30px 24px -30px;
  transition: all 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
  box-sizing: border-box;

  > div {
    transition: all 300ms ease-in;
    transition-delay: 200ms;
  }

  &.trialInfoEnter {
    max-height: 0;

    .trialInfoInnerContainer {
      opacity: 0;
    }
  }

  &.trialInfoEnterActive {
    max-height: 150px;

    .trialInfoInnerContainer {
      opacity: 1;
    }
  }
}
