@import 'styles/variables';
@import 'styles/colorPalette';

.addNewUser {
  width: 710px;
  height: 100%;

  &__header {
    padding: 36px 36px 12px 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > h2 {
      color: $headerTextColor;
      font-size: $h2FontSize;
      line-height: $h2LineHeight;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-family: $fontFamilyMonsterrat;
    }

    & > button {
      border: none;
      background-color: transparent;
      height: 36px;
      width: 36px;
      padding: 0;
      cursor: pointer;
      outline: 0;

      & svg {
        width: 100%;
        height: auto;

        & path {
          stroke: $primary80;
        }
      }
    }
  }

  &__content {
    padding: 0 55px;

    & > button {
      margin-top: 36px;
    }
  }
}

.editPermissionGroupsButton {
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}

.seatsCount {
  font-weight: $boldFontWeight;
}

.restrictionMessage {
  transition: all 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 12px;

  > div {
    transition: all 300ms ease-in;
    transition-delay: 200ms;
  }

  &.restrictionMessageEnter {
    max-height: 0;

    .restrictionMessageInnerContainer {
      opacity: 0;
    }
  }

  &.restrictionMessageEnterActive {
    max-height: 120px;

    .restrictionMessageInnerContainer {
      opacity: 1;
    }
  }
}
