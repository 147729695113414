@import 'styles/variables';

.footer {
  border-top: 1px solid $primary10;
  border-bottom: 1px solid $primary10;
  height: 50px;
  display: flex;
  align-items: center;
  margin: -1px 30px 0;
  margin-bottom: 48px;
}

.addButton {
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
}
