@import '../SelectInput/SelectInput.module.scss';

.container {
  display: flex;
  flex-direction: column;
}

.fieldContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.typeContainer {
  height: 52px;
  width: 170px;
  min-width: 170px;
  background: $surfacesLight;
  border: 1px solid $primary10;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  padding: 16px 20px;
  border-left: none;
  cursor: default;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
  margin-top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.typeContainerPlaceholder {
  color: $defaultTextColor;
}

.dataTypeContainer {
  position: relative;
}

.link {
  position: absolute;
  right: 0;
  top: -17px;
}

.description {
  margin-top: 4px;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
}
