@import 'styles/variables';
@import 'styles/mixins';

.popUpItem {
  @include buttonReset;
  padding: 12px 20px;
  transition: 0.25s all ease-in-out;
  width: 100%;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  outline: 0;
  color: $defaultTextColor;

  &:not(.disabled):hover {
    background-color: $secondary6;
    color: $secondary100Base;
  }

  &.dangerButton {
    color: $red100Base;

    &:not(.disabled):hover {
      background-color: $red10;
      color: $red100Base;
    }
  }

  &.disabled {
    background-color: $surfacesLight;
    color: $placeholderTextColor;
  }
}
