@import 'styles/variables';

.overlayContainer {
  overflow: auto;
  max-height: 264px;
}

.name {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
}

.phone,
.email {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
}
