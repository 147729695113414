@import 'styles/variables';

.popper {
  width: 100%;
  font-family: $fontFamilyMonsterrat;
  background: $white100Base;
  box-shadow: 0px 8px 24px rgba(15, 35, 66, 0.2);
  border-radius: 4px;
  z-index: 10;
}
