@import 'styles/variables';

.requirements {
  margin: 26px 0 36px 0;
}

.requirement {
  display: flex;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .checkedIcon {
    flex: 0 0 auto;
    margin-right: 8px;

    & path {
      stroke: $green110;
    }
  }
}

.buttonFullWidth {
  width: 100%;
}
