.configurableFormContainer {
  display: grid;
  height: 100%;
  gap: 30px;
}

.inputContainer {
  width: 100%;
}

.invisibleContainer {
  display: none;
}
