@import 'styles/variables';

.profilePage {
  width: 100%;
  height: 100%;
}

.profileHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: $fontFamilyMonsterrat;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;

  &__title {
    display: flex;
    align-items: flex-end;

    & h2 {
      color: $headerTextColor;
      font-size: $h2FontSize;
      line-height: $h2LineHeight;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-family: $fontFamilyMonsterrat;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    margin-top: 48px;
  }

  &__photo {
    margin-right: 20px;
  }

  &__name {
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    color: $headerTextColor;
  }
}

.profileBody {
  &__accountInformation {
    padding: 36px 0;
    border-bottom: 1px solid rgba(15, 35, 66, 0.1);
  }

  &__title {
    & h4 {
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-size: $h4FontSize;
      line-height: $h4LineHeight;
      color: $headerTextColor;
    }
  }

  &__information {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__infoItem {
    width: 48%;
    box-sizing: border-box;
  }

  &__save {
    padding-top: 36px;
  }
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.section {
  padding: 24px 0 46px 0;
  border-bottom: 1px solid rgba(15, 35, 66, 0.1);

  &Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    & h5 {
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $normalFontWeight;
      font-size: $h5FontSize;
      line-height: $h5LineHeight;
      color: $headerTextColor;
    }
  }
}
