@import 'styles/variables';

.pageContainer {
  display: flex;
  height: 100vh;
}

.pageInfo {
  width: 480px;
}

.pageBackground {
  height: 100%;
  background-color: $surfacesSidebar;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    left: -10%;
    width: 110%;
    height: auto;
  }
}

.notifications {
  left: 33.3%;
}

.infoContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.organizationLogo {
  & > img {
    height: 28px;
  }
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
}

.description {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin: 12px 0 36px 0;
}

.highlighted {
  font-weight: bold;
  color: $headerTextColor;
}

.limitationsBlock {
  margin-top: 24px;
}

.limitationItem {
  display: flex;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin: 0 8px 16px 0;

  & > svg {
    min-width: 24px;
    margin: -2px 8px 0 0;

    & > path {
      stroke: $green100Base;
    }
  }
}
