@import 'styles/variables';

.container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.foldersContainer {
  flex-basis: 60%;
}

.foldersBreadcrumb {
  font-family: $fontFamilyMonsterrat;
  font-size: $pFontSize;
  font-weight: $normalFontWeight;
  line-height: 24px;
  color: $subtextColor;
}

.configurationContainer {
  padding: 24px 29px;
  max-width: 385px;
  flex-basis: 40%;
  border-left: 1px solid $borderAndSeparatorsColor;

  .configurationTitle {
    color: $primary100Base;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  .switchButton {
    margin: 0 12px 0 0;
  }

  .docuSignSwitchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
  }
}

.informationSection {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > p {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    margin-bottom: 24px;
  }
}

.shareImage {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: $secondary100Base;
}

.openDocuSignSettingsButton {
  display: flex;
  align-items: center;
  width: 273px;
  padding: 14px 24px;
}

.createFolderLink {
  margin-top: 24px;
}

.breadcrumbsContainer {
  margin-top: 8px;
}
