@import 'styles/variables';

.errorMainInfo {
  margin-left: 55px;
  margin-right: 55px;
}

.contactLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $defaultLinkColor;

  &:hover {
    color: $defaultLinkHoverColor;

    & > svg {
      & > path {
        stroke: $defaultLinkHoverColor;
      }
    }
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
}

.errorTitle {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
  margin: 26px 0 16px 0;
}

.contactText {
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  flex-wrap: wrap;

  & > p {
    margin-right: 5px;
  }
}

.dashboardButton {
  width: 180px;
  margin-top: 36px;
}

.errorSystemTitle {
  margin-bottom: 24px;
}
