@import 'styles/variables';

.productTitle {
  align-items: normal;
  font-weight: $normalFontWeight;
  color: $primary80;
}

.checkbox {
  padding: 0 !important;
  width: 24px;
}
