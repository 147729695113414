@import 'styles/variables';

.container {
  position: relative;
  width: 100%;
}

.dataTypeContainer {
  position: absolute;
  top: 48px;
  height: 52px;
  width: 52px;
  border: 1px solid $borderAndSeparatorsColor;
  border-radius: 4px 0 0 4px;
  background-color: #f8f8f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
