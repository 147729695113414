@import 'styles/colorPalette';

.block {
  padding: 36px 0 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.infoLine {
  display: flex;
  padding: 14px 10px;
  align-items: center;

  > div {
    display: flex;
    width: 50%;
  }
}

.subTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $primary40;
}

.subTitleText {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $primary80;
}

.blockTitle {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: $primary100Base;
  margin-bottom: 16px;
}

.hintIcon {
  display: inline-block;
  margin: 8px;
}

.hintText {
  text-align: left;
}

.selectContainer {
  margin-top: 24px;
}

.formFooter {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
  margin-top: 24px;
}

.bold {
  display: inline;
  color: $primary100Base;
  font-weight: bold;
}

.createAccount {
  width: 100%;
  margin-top: 36px;
}

.line {
  border-top: 1px solid rgba($primary20, 0.5);
  width: 100%;
}
