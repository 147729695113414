@import 'styles/variables';

$border: 1px solid rgba($primary20, 0.5);

.line {
  display: flex;
  padding: 12px 10px;
  border-top: $border;

  &:last-child {
    border-bottom: $border;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $secondary6;
      outline: none;
    }
  }

  &.disabled {
    cursor: default; // prevents caret cursor when hovering over text
  }
}

.name {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $primary100Base;
}

.lastActiveDate {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $primary80;
}

.logo {
  width: 36px;
  height: 36px;
  margin-right: 12px;

  &.hidden {
    visibility: hidden;
  }
}

.organizationDefaultIcon {
  background-color: $surfacesLight;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  > svg {
    fill: $surfacesOverlaySolid;
    fill-opacity: 0.4;
  }
}

.centred {
  display: flex;
  align-items: center;
}

.isInactive {
  .name,
  .lastActiveDate {
    color: $primary40;
  }
}

.status {
  margin-left: 8px;
  display: flex;
}

.statusTooltip {
  margin-left: 8px;
  align-self: center;
}

.title {
  display: flex;
}
