@import 'styles/variables';

.layoutHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  & > h4 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }
}

.actionsContainer {
  position: absolute;
  right: 0;
}