@import 'styles/mixins';

.title {
  @include headerTitle;
  margin-bottom: 24px;
}

.tabSwitch {
  margin-bottom: 30px;
}
