@import "styles/variables";
@import 'components/Auth/CreateAccountForm/Step.module.scss';

.signInLink {
  a:hover {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      top: -$pLineHeight;
    }
  }
}
