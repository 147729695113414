@import 'styles/variables';

.permissionsProduct {
  margin-top: 48px;

  &:first-child {
    margin-top: 36px;
  }

  & h4 {
    margin-bottom: 24px;
    color: $headerTextColor;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-family: $fontFamilyMonsterrat;
  }
}
