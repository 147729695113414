@import 'styles/variables';

.editTemplate {
  width: 980px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30px;

  &Header {
    padding: 36px 36px 8px 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > button {
      border: none;
      background-color: transparent;
      height: 36px;
      width: 36px;
      padding: 0;
      cursor: pointer;
      outline: 0;
      flex-shrink: 0;

      & svg {
        width: 100%;
        height: auto;

        & path {
          stroke: $primary80;
        }
      }
    }
  }

  &Content {
    padding: 0 55px;
  }
}

.editTemplateDescription {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin-top: 12px;
}

.fieldsSectionActions {
  margin-top: 36px;
  display: flex;
  align-items: center;
}

.switchContainer {
  display: flex;
  align-items: center;
  min-height: 44px;
  min-width: 172px;
  margin: 0 12px;
  padding-left: 4px;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $subtextColor;

  & > p {
    margin-left: 4px;
  }

  .activeSwitchText {
    color: $headerTextColor;
  }
}

.helpMessage {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $red100Base;
  margin-top: 4px;
}

.editRecipientsMappingButton {
  margin-top: 24px;
  min-width: 213px;
}

.tableTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.section {
  &:first-child {
    margin-top: 36px;
  }

  &:not(:last-child) {
    margin-bottom: 48px;
  }
}

.fieldsSectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questionIcon {
  margin-left: 12px;

  &::after {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $headerTextColor;
    border: 1px solid $borderAndSeparatorsColor;
  }
}

.questionTooltip {
  text-align: center;
}

.editVariablesMappingButton {
  margin-top: 24px;
  min-width: 203px;
}

.headerInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > h2 {
    color: $headerTextColor;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-family: $fontFamilyMonsterrat;
  }
}
