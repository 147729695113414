@import 'styles/variables';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 330px;
  padding: 10px 20px;
  background-color: $white100Base;

  .cardHeader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .dragImage {
    opacity: 0;
    position: absolute;
    left: -20px;
  }

  &:hover,
  &.dragging {
    .dragImage {
      opacity: 1;
    }
  }

  .statusIndex {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $tableHeadFontSize;
    line-height: $menuHeaderLineHeight;
    letter-spacing: $defaultLetterSpacing;
    text-transform: uppercase;
    color: $captionColor;
    margin-bottom: 8px;
  }

  .statusName {
    p,
    input {
      max-width: 290px;
      padding: 0;
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-size: 18px;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: $headerTextColor;
    }
  }

  .statusOptions {
    display: flex;
    flex-direction: column;

    & > div {
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &:last-child {
        margin-bottom: 14px;
      }
    }
  }

  .applicationCards {
    display: flex;
    flex-direction: column;
    margin: 0 -10px;

    & > div {
      background-color: $surfacesLight;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .skeletonAvatarsList {
      & > div {
        border: 2px solid $surfacesLight;
      }
    }
  }

  .checkboxItem {
    padding: 0 12px 0 12px !important;
  }

  .checkboxMultiSelectList {
    margin-top: 12px;

    & > label:not(:last-child) {
      padding-bottom: 12px;
    }
  }

  .rowActions {
    position: absolute;
    left: calc(100% - 4px);
    min-width: auto;
    margin: 0;
  }
}

.permissionsSection {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.permissionSectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > h6 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }
}

.permissionsSectionTooltip {
  margin-left: 8px;
}

.statusRuleTransitionContainer {
  margin: 0 -20px;
}
